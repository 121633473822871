import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import "../styles/DashboardHeader.css";
import { LogoutButton } from "./LogoutButton";
import ChatIcon from "../icons/ChatIcon";
import GuideyLogo from "../icons/GuideyLogo";

export default function DashboardHeader(props) {
  // Data
  const { systemContent } = props;

  const userData = useSelector(
    (state) => state.userReducer.user.userData,
    shallowEqual
  );

  const messagingActiveStatus = userData
    ? userData.MessagingActiveStatus
    : false;

  const messageData = useSelector(
    (state) => state.userReducer.user.messageData,
    shallowEqual
  );

  const unreadMessageCount = messageData
    ? messageData.filter(function (item) {
        if (item.InboundMessage && !item.ReadByRecipient) {
          return true;
        } else {
          return false;
        }
      }).length
    : userData
    ? userData.unreadMessageCount
    : 0;

  //console.log("unreadMessageCount", unreadMessageCount)

  {
    // const propertyData = useSelector(
    //  (state) => state.userReducer.user.linkedProperties,
    //   shallowEqual
    //  );
  }

  {
    //
    //const vanityPropertyId = window.location.pathname.split("/")[2];
  }

  {
    // const propertyDetails = propertyData.filter((property) => {
    //  return property.PropertyID === vanityPropertyId;
    // });
  }

  // Functions
  const logoClick = () => {
    props.history.push("/");
  };

  const profileClick = () => {
    props.history.push("/personal-information");
  };

  const messagingClick = () => {
    props.history.push("/messaging");
  };

  {
    // const onClickInternal = () => {
    //  props.history.push({
    //    pathname: "/referrals",
    //  });
    //};
  }

  // Extract placeholder property details from SystemContent
  var propertyManagerPlaceholderFiltered = null;
  if (systemContent) {
    propertyManagerPlaceholderFiltered = systemContent.filter(
      (DisplayContent) => {
        return DisplayContent.DetailLabel === "property-manager-placeholder";
      }
    );
  }

  const staticHeader = window.location.pathname === "/" ? true : false;

  const headerBarOuterClass = staticHeader
    ? "headerbar-outer-fixed"
    : "headerbar-outer";

  const [opacity] = useState("100%");

  {
    //const handleResize = () => {
    //if (!staticHeader) {
    //if (window.pageYOffset < 20) {
    //setOpacity("100%");
    //}
    //if (window.pageYOffset > 19 && window.pageYOffset < 25) {
    //setOpacity("75%");
    //}
    //if (window.pageYOffset > 24 && window.pageYOffset < 31) {
    //setOpacity("40%");
    //}
    //if (window.pageYOffset > 30) {
    //setOpacity("20%");
    //}
    //}
    //if (staticHeader) {
    //setOpacity("100%");
    //}
    //};
    //
    //useEffect(() => {
    //if (typeof window !== "undefined" && !staticHeader) {
    //window.addEventListener("scroll", handleResize);
    //}
    //return () => {
    //window.removeEventListener("scroll", handleResize);
    //};
    //}, []);
  }

  return (
    <>
      <div className={headerBarOuterClass} style={{ opacity: `${opacity}` }}>
        <div className="headerbar">
          <div className="header-logo-container" onClick={logoClick}>
            <GuideyLogo width={"120"} height={"auto"} />
          </div>

          {userData && (
            <div className="header-button-and-avatar-container">
              <LogoutButton
                StaticText={"Logout"}
                pusher={props.pusher}
                {...props}
              />
              {messagingActiveStatus && (
                <div
                  className="header-chat-icon-container"
                  onClick={messagingClick}
                >
                  <span>
                    <ChatIcon className={"header-chat-icon"} />
                  </span>
                  {unreadMessageCount !== 0 && (
                    <span className="header-chat-icon-badge">
                      {unreadMessageCount}
                    </span>
                  )}
                </div>
              )}
              <img
                onClick={profileClick}
                className="header-profile-image"
                src={
                  userData && userData.UserCoverImage
                    ? `${userData.UserCoverImage}?width=35}`
                    : propertyManagerPlaceholderFiltered
                    ? `${propertyManagerPlaceholderFiltered[0].DetailImage}?width=35}`
                    : null
                }
                alt="Profile"
              ></img>
            </div>
          )}
        </div>
      </div>
      {
        // <div className="nav-headerbar-banner" onClick={onClickInternal}>
        //   💰 Refer friends and claim a FREE Amazon voucher for each friend! 💰
        // </div>
      }
      {
        //<NavigationHeader
        //{...props}
        //propertyDetails={propertyDetails}
        //currentPathRoot={currentPathRoot}
        //currentPageTitle={currentPageTitle}
        //previousPageTitle={previousPageTitle}
        //previousPagePath={previousPagePath}
        ///>
      }
      {
        // <div className="inline-static-link-header-container">
        //  <div className="inline-static-link-header-grey">
        //    airguide is now Guidey™{" "}
        //     <a
        //      href="https://guideyapp.com/from-airguide-to-guidey"
        //      target="_blank"
        //      rel="noopener noreferrer"
        //   >
        //      find out more
        //   </a>
        //    .
        //  </div>
        //  </div>
      }
    </>
  );
}
