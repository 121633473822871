import React from "react";
import ChevronRightIcon from "../icons/ChevronRightIcon";
import {
  workflowApiRoot,
  googlePlacePhotoApiRootUrl,
  googlePlacesApiKey,
  apiSecretToken,
} from "../constants.js";
import "../styles/InfoItem.css";

export default class LocalProfileImageBanner extends React.Component {
  state = {
    contentCount: 0,
    imageLoaded: false,
    imageError: false,
    contentCountLoaded: false,
    googlePhotoRef: this.props.ItemData.GooglePhotoReference,
  };

  componentDidMount = () => {
    const { SharedContentProfileId } = this.props;
    fetch(`${workflowApiRoot}get-profile-content-count`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({ profileId: SharedContentProfileId }),
    })
      .then((response) => response.json())
      .then((results) => {
        if (results.status !== "success") {
          this.setState({ contentCount: 0, contentCountLoaded: true });
        }

        if (results.status === "success") {
          this.setState({
            contentCount: results.response.contentCount,
            contentCountLoaded: true,
          });
        }
      });
  };

  onImageLoad = () => {
    this.setState({ imageLoaded: true, imageError: false });
  };

  onImageError = () => {
    const { ItemData } = this.props;
    this.setState({
      imageError: true,
    });
    this.refreshGooglePlaceDetails(ItemData.GooglePlaceId, ItemData._id);
  };

  refreshGooglePlaceDetails = (googlePlaceId, contentId) => {
    fetch(`${workflowApiRoot}refresh-local-profile-image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${apiSecretToken}`,
      },
      body: JSON.stringify({
        place_id: googlePlaceId,
        contentId: contentId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("refreshGooglePlaceDetails response", data.response);
        this.setState({
          googlePhotoRef: data.response.photoReference,
        });
      });
  };

  handleClick = () => {
    const { Target, SharedContentProfileId } = this.props;
    this.props.history.push({
      pathname: "/" + Target,
      state: { propertyId: SharedContentProfileId },
    });
  };

  render() {
    const { Heading, Image } = this.props;
    const ContentCount = this.state.contentCount;

    const DetailImage = this.state.googlePhotoRef
      ? `${googlePlacePhotoApiRootUrl}maxwidth=300&photoreference=${this.state.googlePhotoRef}&key=${googlePlacesApiKey}`
      : Image;

    return (
      <>
        <div className="image-banner" onClick={this.handleClick}>
          <div
            className={
              this.state.imageLoaded ? "image-banner-main-image-loading" : ""
            }
          >
            <img
              className={"image-banner-main-image"}
              src={DetailImage}
              alt={Heading}
              onLoad={this.onImageLoad}
              onError={this.onImageError}
            />
          </div>

          <div className="button-banner-heading-and-chevron-wrapper">
            <div className="button-banner-heading">{Heading}</div>
            <div className="button-banner-chevron-icon-wrapper">
              <ChevronRightIcon className="button-banner-chevron-icon" />
            </div>
          </div>

          <div className="image-banner-subheading">
            {!this.state.contentCountLoaded && "...loading"}
            {this.state.contentCountLoaded &&
              (ContentCount === 0 || ContentCount > 1) &&
              `${ContentCount} places`}
            {this.state.contentCountLoaded &&
              ContentCount === 1 &&
              `${ContentCount} place`}
          </div>
        </div>
      </>
    );
  }
}
