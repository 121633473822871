import React from "react";
import { connect } from "react-redux";
import { cdnRootUrl } from "../constants.js";
import { updateUser } from "../redux/actions/userActions";
import { updateCompany } from "../redux/actions/userActions";
import { updateManager } from "../redux/actions/userActions";
import { updateProperty } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import { uploadFile } from "../redux/actions/userActions";
import { deleteUploadedFile } from "../redux/actions/userActions";
import InfoItemCheckMark from "./InfoItemCheckMark";
import ReactLoading from "react-loading";
import "../styles/InfoItem.css";
import CirclePlusIcon from "../icons/CirclePlusIcon";

class InfoItemImage extends React.Component {
  state = {
    selectedFile: null,
    payload: {},
    inputKey: null,
    fileName: "",
  };

  onChange = (e) => {
    e.persist();
    const { ItemLabel, ItemId, FileFolder } = this.props;
    var payloadA = { ...this.state.payload };
    payloadA[e.target.name] = e.target.files[0];
    let file = e.target.files[0];

    this.setState({
      fileName: `${FileFolder}/${ItemId}/${ItemLabel}_${ItemId}_${file.name}`,
    });
    const reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    //  reader.readAsBinaryString(file); // Bubble API
    reader.readAsArrayBuffer(file);
    // console.log("file", file);
  };

  handleReaderLoaded = (readerEvt) => {
    const { PayloadA, userId } = this.props;
    let file = readerEvt.target.result;
    let fileName = this.state.fileName;
    //  console.log("binaryString", binaryString);

    var blob = new Blob([file], { type: "image/jpeg" });
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(blob);

    this.setState({
      selectedFile: file,
      filePreview: imageUrl,
      // selectedFile: btoa(binaryString), // Bubble API
      payload: {
        [PayloadA]: `${cdnRootUrl}/user/${userId}/${fileName}`,
        //{  // Bubble API
        //   filename: fileName, // Bubble API
        //   contents: btoa(binaryString), // Bubble API
        //   private: false // Bubble API
        // }
      },
    });
    //   console.log(this.state);
  };

  onEdit = (e) => {
    const { ItemLabel } = this.props;
    document
      .getElementById(`${ItemLabel}-container`)
      .scrollIntoView({ behavior: "smooth" });
    let randomString = Math.random().toString(36);
    e.preventDefault();
    this.props.setSaveStatus(`${ItemLabel}-editing`);
    this.setState({
      selectedFile: null,
      fileName: "",
      inputKey: randomString,
    });
  };

  onCancel = (e) => {
    e.preventDefault();
    const { ItemLabel } = this.props;
    let randomString = Math.random().toString(36);
    this.props.setSaveStatus(`${ItemLabel}-idle`);
    this.setState({
      selectedFile: null,
      fileName: "",
      inputKey: randomString,
    });
  };

  onSubmit = (e) => {
    const {
      ItemLabel,
      ItemType,
      userId,
      companyId,
      managerId,
      propertyId,
      ValueA,
    } = this.props;

    e.preventDefault();

    this.props.uploadFile(userId, this.state.fileName, this.state.selectedFile);

    if (ItemType === "company") {
      this.props.updateCompany(
        this.state.payload,
        companyId,
        ItemLabel,
        userId
      );
    }
    if (ItemType === "manager") {
      this.props.updateManager(
        this.state.payload,
        managerId,
        ItemLabel,
        userId
      );
    }
    if (ItemType === "property") {
      this.props.updateProperty(this.state.payload, propertyId, ItemLabel);
    }
    if (ItemType === "user") {
      this.props.updateUser(this.state.payload, userId, ItemLabel);
    }
    if (ItemType === "default-manager") {
      this.props.updateManager(
        this.state.payload,
        managerId,
        ItemLabel,
        userId
      );
      this.props.updateUser(
        { UserCoverImage: this.state.payload.PropertyMgrCoverImage },
        userId,
        ItemLabel
      );
    }

    if (ValueA) {
      const cdnRootString = `${cdnRootUrl}/`;
      const cdnStorageString = `${ValueA.slice(
        ValueA.indexOf(cdnRootString) + cdnRootString.length
      )}`;
      const deleteFileString =
        ValueA.split("/")[0] === "https:"
          ? cdnStorageString
          : `https:${ValueA}`;
      this.props.deleteUploadedFile(deleteFileString);
    }
  };

  render() {
    const {
      SaveStatus,
      ItemLabel,
      ItemHeading,
      ItemDescription,
      InputLabelA,
      ValueA,
      IsChecked,
      IsRequired,
      FalseText,
      PayloadA,
      IsNewCustomItem,
      FileLoadStatus,
    } = this.props;

    const Loading =
      FileLoadStatus === `${this.state.fileName}-loading` ? true : false;
    return (
      <>
        <div
          className="infoitem-container"
          id={`${ItemLabel}-container`}
          style={{ scrollMarginTop: "var(--headerNavHeight)" }}
        >
          <div className="infoitem-heading-and-button-container">
            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={IsChecked}
                IsRequired={IsRequired}
                IsCustomContent={ValueA ? true : false}
                ItemHeading={ItemHeading}
                IsNewCustomItem={IsNewCustomItem}
              />
            </div>

            <button
              className="infoitem-edit-button"
              onClick={
                SaveStatus === `${ItemLabel}-editing`
                  ? this.onCancel
                  : this.onEdit
              }
            >
              {SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "Cancel"
                : "Edit"}
            </button>
          </div>

          <div
            className={
              SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching` ||
              Loading
                ? "hide"
                : "show"
            }
          >
            {ValueA && (
              <div className="infoitem-image-container">
                <img
                  className="infoitem-image"
                  src={`${ValueA}?width=300`}
                  alt={ItemLabel}
                />
              </div>
            )}
            {!ValueA && FalseText}
          </div>

          {(SaveStatus === `${ItemLabel}-editing` ||
            SaveStatus === `${ItemLabel}-fetching` ||
            Loading) && <div className="infoitem-value">{ItemDescription}</div>}

          <div
            className={
              SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching` ||
              Loading
                ? "show"
                : "hide"
            }
          >
            <div className="infoitem-form-container">
              <form className="infoitem-form" onSubmit={this.onSubmit}>
                <div className="infoitem-form-inner-container-left">
                  <label className="infoitem-form-label"> {InputLabelA} </label>
                  <input
                    type="file"
                    key={this.state.inputKey || ""} // Required to clear file input value
                    id={`file-upload-image-${ItemLabel}`}
                    name={PayloadA}
                    className="infoitem-image-input"
                    placeholder={`Enter ${InputLabelA}`}
                    //    defaultValue={""}
                    value={this.state.payload.name}
                    onChange={this.onChange}
                    required
                  />

                  <label
                    htmlFor={`file-upload-image-${ItemLabel}`}
                    className="custom-file-upload"
                  >
                    {this.state.selectedFile && (
                      <img
                        className="infoitem-image"
                        // src={`data:image/jpeg;base64,${this.state.selectedFile}`}
                        src={`${this.state.filePreview}?width=300`}
                        alt={ItemLabel}
                      />
                    )}
                    {!this.state.selectedFile && (
                      <div className="custom-file-upload-inner-container">
                        <CirclePlusIcon className="custom-file-upload-icon" />

                        <div className="custom-file-upload-text">
                          Upload image
                        </div>
                      </div>
                    )}
                  </label>
                </div>

                {this.state.selectedFile && (
                  <div className="infoitem-form-inner-container-left">
                    <button
                      className="button-with-loading"
                      onClick={this.onSubmit}
                    >
                      <div
                        className={
                          Loading
                            ? "button-with-loading-text-loading"
                            : "button-with-loading-text"
                        }
                      >
                        {Loading ? "Saving" : "Save"}
                      </div>
                      {Loading && (
                        <ReactLoading
                          type={"spin"}
                          color={"white"}
                          height={"20%"}
                          width={"20%"}
                        />
                      )}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>

          <div className="infoitem-section-break-wrapper">
            <div className="infoitem-section-break"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userInfo, userId, ItemLabel) =>
    dispatch(updateUser(userInfo, userId, ItemLabel)),
  updateManager: (managerInfo, managerId, ItemLabel, userId) =>
    dispatch(updateManager(managerInfo, managerId, ItemLabel, userId)),
  updateCompany: (companyInfo, companyId, ItemLabel, userId) =>
    dispatch(updateCompany(companyInfo, companyId, ItemLabel, userId)),
  updateProperty: (propertyInfo, propertyId, ItemLabel) =>
    dispatch(updateProperty(propertyInfo, propertyId, ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
  uploadFile: (userId, fileName, file) =>
    dispatch(uploadFile(userId, fileName, file)),
  deleteUploadedFile: (fileUrl) => dispatch(deleteUploadedFile(fileUrl)),
});

export default connect(null, mapDispatchToProps)(InfoItemImage);
