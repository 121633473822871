import React from "react";
import NavigationHeader from "../components/NavigationHeader";
import InfoItemTwoBox from "../components/InfoItemTwoBox";
import InfoItemImage from "../components/InfoItemImage";
import InfoItemOneBox from "../components/InfoItemOneBox";
import InfoItemStatic from "../components/InfoItemStatic";
import InfoItemTel from "../components/InfoItemTel";
import DeleteManagerButton from "../components/DeleteManagerButton";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class ManagerProfilePage extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname + window.location.search);
  };

  render() {
    const {
      SaveStatus,
      companyData,
      propertyData,
      managerData,
      userData,
      managerVanityId,
    } = this.props;

    const managerDataFiltered = managerData.filter((Item) => {
      return Item.ManagerVanityID === managerVanityId;
    });

    const propertyManager = managerDataFiltered[0];

    const propertyDataFiltered = propertyData.filter((Item) => {
      return Item.LinkedPropertyMgr === propertyManager._id;
    });

    return (
      <>
        <NavigationHeader
          {...this.props}
          previousPageTitle={"Property Managers"}
          previousPagePath={"/property-managers"}
          currentPageTitle={"Property Manager Profile"}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="infopage-subheading">
              The details of the property manager / host, which will be shared
              with your guests.
            </div>

            <InfoItemTwoBox
              SaveStatus={SaveStatus}
              managerId={propertyManager._id}
              ItemType={"manager"} // Defines which dispatch to invoke
              ItemLabel={"Name"}
              ItemHeading={"Name"}
              ItemDescription={
                "This is the name of the property manager / host"
              }
              InputLabelA={"First name"}
              ValueA={propertyManager.PropertyMgrFirstname}
              PayloadA={"PropertyMgrFirstname"}
              InputLabelB={"Last name"}
              ValueB={propertyManager.PropertyMgrLastname}
              PayloadB={"PropertyMgrLastname"}
              IsChecked={
                propertyManager.PropertyMgrFirstname ||
                propertyManager.PropertyMgrLastname
                  ? true
                  : false
              }
              IsRequired={false}
              FalseText={"Not entered"}
              userId={userData._id}
            />

            <InfoItemImage
              SaveStatus={SaveStatus}
              managerId={propertyManager._id}
              userId={userData._id}
              companyId={companyData._id}
              ItemType={"manager"} // Defines which dispatch to invoke
              ItemHeading={"Photo"}
              ItemLabel={"manager-profile-image"}
              FileFolder={"manager-content"}
              ItemId={propertyManager._id} // Defines which id to insert into filename
              ItemDescription={"This is the photo displayed to your guests"}
              InputLabelA={""}
              ValueA={propertyManager.PropertyMgrCoverImage}
              PayloadA={"PropertyMgrCoverImage"} // Defines which database label to update
              IsChecked={propertyManager.PropertyMgrCoverImage ? true : false}
              IsRequired={false}
              FalseText={"Not uploaded"}
            />

            <InfoItemOneBox
              SaveStatus={SaveStatus}
              managerId={propertyManager._id}
              userId={userData._id}
              ItemType={"manager"} // Defines which dispatch to invoke
              ContainerClassName={"infoitem-form-inner-container-full"}
              ItemLabel={"Email address"}
              ItemHeading={"Email Address"}
              ItemDescription={
                "This is the email address that guests will use to contact you (leave blank if you don't want to show an email address)"
              }
              InputLabelA={"Email address"}
              ValueA={propertyManager.PropertyMgrEmail}
              PayloadA={"PropertyMgrEmail"}
              IsChecked={propertyManager.PropertyMgrEmail ? true : false}
              IsRequired={false}
              FalseText={"Not entered"}
            />

            <InfoItemTel
              SaveStatus={SaveStatus}
              managerId={propertyManager._id}
              userId={userData._id}
              itemType={"manager"}
              managerEmail={propertyManager.PropertyMgrEmail}
              ContainerClassName={"infoitem-form-inner-container-left"}
              itemLabel={"manager-phone-number"}
              ItemHeading={"Phone Number"}
              ItemDescription={
                "This is the telephone number that guests will use to contact you (leave blank if you don't want to show a phone number)"
              }
              InputLabelA={"Phone number"}
              ValueA={propertyManager.PropertyMgrTel}
              // PayloadA={"PropertyMgrTel"}
              IsChecked={propertyManager.PropertyMgrTel ? true : false}
              IsRequired={false}
              FalseText={"Not entered"}
            />

            <InfoItemTwoBox
              SaveStatus={SaveStatus}
              managerId={propertyManager._id}
              ItemType={"manager"} // Defines which dispatch to invoke
              ItemLabel={"message-link"}
              ItemHeading={"Message Link"}
              ItemDescription={"This is a link to where guests can contact you"}
              InputLabelA={'Message link name (e.g. "WhatsApp")'}
              ValueA={propertyManager.MessageAppName}
              PayloadA={"MessageAppName"}
              InputLabelB={"Message link Url (e.g. https://wa.me/+12345678910)"}
              ValueB={propertyManager.MessageAppLink}
              PayloadB={"MessageAppLink"}
              IsChecked={propertyManager.MessageAppName ? true : false}
              IsRequired={false}
              FalseText={"Not entered"}
              userId={userData._id}
            />

            <InfoItemStatic
              ItemData={propertyDataFiltered}
              DataType={"property"}
              ItemHeading={"Places Managing"}
              ItemDescription={
                propertyDataFiltered.length < 1
                  ? "Not currently managing any places"
                  : ""
              }
              IsChecked={propertyDataFiltered.length > 0 ? true : false}
              IsRequired={false}
              ValueA={true}
            />

            <DeleteManagerButton
              {...this.props}
              managerId={propertyManager._id}
              userId={userData._id}
              ItemLabel={"delete-manager"}
            />
          </div>
        </div>
      </>
    );
  }
}
