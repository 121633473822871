import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { workflowApiRoot } from "../constants.js";
import {
  subscriptionOptions,
  subscriptionOffers,
  subscriptionUnitPricing,
  subscriptionCustomPricing,
} from "../data/subscriptionOptions.js";
// import { createPaymentAndSubscription } from "../redux/actions/userActions";
import {
  setSaveStatus,
  autoLogin,
  getSubscriptionData,
  setSubscriptionDataLoaded,
} from "../redux/actions/userActions";
import CardSection from "./CardSection";
import FormattedDaT from "./FormattedDaT";
// import Countdown from "react-countdown";
import creditCardBadge from "../images/credit-card-badge.png";
import stripeBadge from "../images/stripe-secure-badge.png";
import LabelIcon from "../icons/LabelIcon";
import WalletIcon from "../icons/WalletIcon";
import DocumentIcon from "../icons/DocumentIcon";
import CircularArrowsIcon from "../icons/CircularArrowsIcon";
import HomeIcon from "../icons/HomeIcon";
import "../styles/CreateSubscription.css";

function CreateSubscription(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const stripe = useStripe();
  const elements = useElements();
  const {
    userData,
    // stripeData,
    geoLocationData,
    propertyData,
    ItemLabel,
    SaveStatus,
    InputLabelA,
    currentPageTitle,
  } = props;

  // const priceData = stripeData.priceData;
  //console.log("priceData", priceData);
  // console.log("userData", userData);
  //  console.log(geoLocationData)

  // Geo Location test
  // const geoLocationData = {
  // currency: "AUD"
  // }

  const propertyDataFiltered = propertyData
    ? propertyData.filter((item) => {
        return !item.SharedProperty;
      })
    : null;

  var currentDate = new Date(); // Now

  const userIsInTrialPeriod =
    userData.SubscriptionTrialEnd > currentDate ? true : false;

  const userId = userData ? userData._id : "";

  const displayCustomPricing =
    userId === "1730986263965x492917907799731900" ? true : false;

  //console.log("userId", userId)
  const displaySpecialOffer = userData.SpecialOffersEligible ? true : false;
  //(userData.LifeCycleStatus === "Lapsed Trialist" || userData.LifeCycleStatus === "Trialist") &&
  //(userData.SpecialOffersEligible !== false ) ? true : false;

  const displayUnitPricing = userData.UnitPricing ? true : false;

  const subscriptionOptionsDisplay = displaySpecialOffer
    ? subscriptionOffers
    : displayCustomPricing
    ? subscriptionCustomPricing
    : displayUnitPricing
    ? subscriptionUnitPricing
    : subscriptionOptions;

  const propertyCount =
    propertyDataFiltered && propertyDataFiltered.length
      ? propertyDataFiltered.length
      : 1;

  const currencyOptions = [
    { key: 1, label: "USD $", value: "usd" },
    { key: 2, label: "GBP £", value: "gbp" },
    { key: 3, label: "EUR €", value: "eur" },
    { key: 4, label: "AUD $", value: "aud" },
  ];

  const [currency, setCurrency] = useState(
    userData.DefaultCurrency
      ? userData.DefaultCurrency
      : geoLocationData &&
        geoLocationData.currency &&
        geoLocationData.currency.toLowerCase() === "gbp"
      ? "gbp"
      : geoLocationData &&
        geoLocationData.currency &&
        geoLocationData.currency.toLowerCase() === "eur"
      ? "eur"
      : geoLocationData &&
        geoLocationData.currency &&
        geoLocationData.currency.toLowerCase() === "aud"
      ? "aud"
      : "usd"
  );
  // console.log("useState Currency", currency);
  //  console.log("currencyOptions", currencyOptions);

  const subscriptionOptionsFiltered = subscriptionOptionsDisplay.filter(
    (item) => {
      return item.currency === currency;
    }
  );

  // console.log("subscriptionOptionsFiltered", subscriptionOptionsFiltered);

  var defaultSubscriptionCycle = "annual";

  var defaultPlanFilter = null;
  if (subscriptionOptionsFiltered) {
    defaultPlanFilter = subscriptionOptionsFiltered.filter((item) => {
      return item.cycle === defaultSubscriptionCycle;
    });
  }

  var defaultPlan = subscriptionOptionsFiltered[0];
  if (defaultPlanFilter) {
    defaultPlan = defaultPlanFilter[0];
  }

  const [subscriptionPlan, setSubscriptionPlan] = useState(defaultPlan);

  //  console.log(planId);

  const onPlanChange = (e) => {
    e.persist();
    const selectedSubscription = subscriptionOptionsFiltered.filter(
      (item) => item.value === e.target.value
    );
    setSubscriptionPlan(selectedSubscription[0]);
  };

  const onCurrencyChange = (currency, cycle) => {
    const selectedSubscription = subscriptionOptionsDisplay.filter(
      (item) => item.cycle === cycle && item.currency === currency
    );
    setSubscriptionPlan(selectedSubscription[0]);
    setCurrency(currency);
  };

  // console.log("subscriptionPlan", subscriptionPlan);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    props.setSaveStatus(`${ItemLabel}-fetching`);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      //    console.log("[error]", error);
      props.setSaveStatus(`${ItemLabel}-error`);
    } else {
      //   console.log("[PaymentMethod]", paymentMethod);

      const userInfo = {
        customerId: userData.StripeCustomerID,
        currency: currency,
        planId: subscriptionPlan.value,
        planCycle: subscriptionPlan.cycle,
        propertyCount: propertyCount,
        paymentMethodId: paymentMethod.id,
        paymentMethodBrand: paymentMethod.card.brand,
        paymentMethodlastFour: paymentMethod.card.last4,
        paymentMethodExpMonth: paymentMethod.card.exp_month,
        paymentMethodExpYear: paymentMethod.card.exp_year,
      };
      // console.log("userInfo", userInfo);

      props.setSaveStatus(`${ItemLabel}-fetching`);
      fetch(`${workflowApiRoot}create-payment-and-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(userInfo),
      })
        .then((response) => response.json())
        .then((results) => {
          //  console.log("CreateSubscription results", results);
          if (
            !results.response.stripePaymentSuccess ||
            !results.response.stripeSubscriptionSuccess
          ) {
            props.setSaveStatus(`${ItemLabel}-error`);
            cardElement.clear();
          }
          if (
            results.response.stripePaymentSuccess &&
            results.response.stripeSubscriptionSuccess
          ) {
            //props.setSaveStatus(`${ItemLabel}-success`);
            // props.getSubscriptionData();
            props.history.push(`/subscription-success?s=1`);
            props.setSubscriptionDataLoaded(false);
            props.getSubscriptionData();
            props.autoLogin(ItemLabel);
          }
        });
    }
  };

  // Filter priceData by currency
  //var priceDataFiltered = null;
  //if (priceData) {
  //  priceDataFiltered = priceData["_api_c2_body.data"].filter((item) => {
  //    return item._api_c2_currency === currency;
  //  });
  //}

  var currencySymbol = subscriptionPlan.currencySymbol;

  var totalAmount = subscriptionPlan.pricing[propertyCount].toFixed(2);

  var unitAmount = (totalAmount / propertyCount).toFixed(2);

  var renewalTotalAmount =
    subscriptionPlan.renewalPricing[propertyCount].toFixed(2);

  const billingCycleTerm =
    subscriptionPlan.cycle === "annual" ? "year" : "month";

  const unitLabel =
    subscriptionPlan.unitLabel === "guide" ? "guide" : "property";

  const unitLabelPlural =
    subscriptionPlan.unitLabel === "guide" ? "guides" : "properties";

  const monthlyEquivalent =
    subscriptionPlan.cycle === "annual" ? (unitAmount / 12).toFixed(2) : null;

  var monthlyRenewalDate = new Date(); // Now
  monthlyRenewalDate.setDate(monthlyRenewalDate.getDate() + 30); // Set now + 30 days as the new date

  var annualRenewalDate = new Date(); // Now
  annualRenewalDate.setDate(annualRenewalDate.getDate() + 366); // Set now + 366 days as the new date

  var date =
    subscriptionPlan.cycle === "annual"
      ? annualRenewalDate
      : monthlyRenewalDate;

  return (
    <>
      <div className="h1-header-large"> {currentPageTitle}</div>
      <>
        {userIsInTrialPeriod && (
          <>
            <div className="infoitem-value">
              Select your plan and enter your credit/debit card details securely
              below. You will only be billed after your free trial ends.
            </div>
            <div style={{ height: "15px" }}> </div>
          </>
        )}
      </>
      <>
        {!userIsInTrialPeriod && (
          <>
            <div className="countdown-text">
              <span>Your free trial has ended</span>
            </div>
            <div className="infoitem-value">
              Your guide links and QR Codes are currently inactive. Don't worry,
              nothing has been lost and you can reactivate them instantly by
              completing your payment details securely below.
            </div>
            <div style={{ height: "15px" }}> </div>
          </>
        )}
      </>

      <div className="subscription-product-container">
        <div className="subscription-product-text-and-currency-selector-container">
          <div className="subscription-product-text">
            <strong>Choose your plan</strong>
          </div>

          {!userData.DefaultCurrency && (
            <select
              className="currency-selector"
              value={currency}
              onChange={(e) =>
                onCurrencyChange(e.currentTarget.value, subscriptionPlan.cycle)
              }
            >
              {
                //console.log("currencyOptions", currencyOptions)}
              }
              {currencyOptions.map(({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
            </select>
          )}
        </div>

        <>
          <div
            className="subscription-radio-buttons-container"
            onChange={onPlanChange}
          >
            {subscriptionOptionsFiltered.map((item) => (
              <div key={`${item.id}`}>
                <label
                  htmlFor={`${item.id}`}
                  className={"subscription-radio-button-container"}
                >
                  <div style={{ cursor: "pointer" }}>
                    <input
                      type="radio"
                      defaultChecked={item.cycle === subscriptionPlan.cycle}
                      name={"subscription-options"}
                      id={`${item.id}`}
                      value={item.value}
                    />
                  </div>

                  <div className="subscription-radio-button-text">
                    {item.name}
                    {item.saveMessage && (
                      <span className="subscription-radio-button-text-saving">{`${item.saveMessage}`}</span>
                    )}
                  </div>
                </label>
              </div>
            ))}
          </div>
        </>

        <div className="icon-and-text-inline-container">
          <HomeIcon className="icon-and-text-inline-icon" />
          <div className="icon-and-text-inline-text">
            <>
              {propertyCount} {propertyCount === 1 && `${unitLabel}`}
              {propertyCount > 1 && `${unitLabelPlural}`} at {currencySymbol}
              {totalAmount} total per {billingCycleTerm} (~{currencySymbol}
              {unitAmount} per property per {billingCycleTerm}
              {billingCycleTerm === "year" && (
                <>{` / ${currencySymbol}${monthlyEquivalent} per property per month equivalent`}</>
              )}
              )
            </>
          </div>
        </div>
        {userIsInTrialPeriod && (
          <>
            <div className="icon-and-text-inline-container">
              <LabelIcon className="icon-and-text-inline-icon" />
              <div className="icon-and-text-inline-text">
                Recurring billing starts <strong>only</strong> after your free
                trial ends
              </div>
            </div>
          </>
        )}

        <div className="icon-and-text-inline-container">
          <WalletIcon className="icon-and-text-inline-icon" />
          <div className="icon-and-text-inline-text">
            {userIsInTrialPeriod && (
              <>
                Due now: <strong>{currencySymbol}0.00</strong>
              </>
            )}
            {!userIsInTrialPeriod && (
              <>
                Your first {billingCycleTerm}, due now:{" "}
                <strong>
                  {currencySymbol}
                  {totalAmount}
                </strong>
              </>
            )}
          </div>
        </div>

        <div className="icon-and-text-inline-container">
          <CircularArrowsIcon className="icon-and-text-inline-icon" />
          {userIsInTrialPeriod && (
            <>
              <div className="icon-and-text-inline-text">
                Starting{" "}
                <FormattedDaT DateInput={userData.SubscriptionTrialEnd} />:{" "}
                <strong>
                  {currencySymbol}
                  {totalAmount} per {billingCycleTerm}
                </strong>
              </div>
            </>
          )}
          {!userIsInTrialPeriod && (
            <>
              <div className="icon-and-text-inline-text">
                Then recurring billing, starting on or around{" "}
                <FormattedDaT DateInput={date} />:{" "}
                <strong>
                  {currencySymbol}
                  {renewalTotalAmount} per {billingCycleTerm}
                </strong>
              </div>
            </>
          )}
        </div>

        <div className="icon-and-text-inline-container">
          <DocumentIcon className="icon-and-text-inline-icon" />
          <div className="icon-and-text-inline-text">
            {billingCycleTerm === "month" && (
              <>
                No contract. Cancel any time. Add or remove {unitLabelPlural} at
                any time.
              </>
            )}
            {billingCycleTerm === "year" && (
              <>Billed upfront. Cancel at end of yearly billing cycle.</>
            )}
          </div>
        </div>
      </div>

      <div className="infoitem-container">
        <div className="infoitem-form-container">
          <form className="infoitem-form" onSubmit={handleSubmit}>
            <div className="infoitem-card-section-container">
              <label className="infoitem-form-label"> {InputLabelA} </label>
              <div className="infoitem-card-section-container-inner">
                <CardSection />
              </div>
            </div>
            <div className="infoitem-form-inner-container-left">
              <input
                type="submit"
                disabled={!stripe}
                className="create-new-button"
                value={
                  SaveStatus === `${ItemLabel}-fetching`
                    ? "Processing..."
                    : "Complete setup"
                }
              />
            </div>

            {SaveStatus === `${ItemLabel}-error` && (
              <div className="infoitem-form-error-message">
                Card details were not saved. Please try again.
              </div>
            )}
          </form>

          <div className="payment-badge-container">
            <a href="https://stripe.com/">
              <img
                className="stripe-badge"
                src={stripeBadge}
                alt="Secure Payments Powered by Stripe"
              ></img>
            </a>

            <img
              className="card-badge-container"
              src={creditCardBadge}
              alt="We accept Visa, Mastercard and American Express"
            ></img>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
  autoLogin: (ItemLabel) => dispatch(autoLogin(ItemLabel)),
  getSubscriptionData: () => dispatch(getSubscriptionData()),
  setSubscriptionDataLoaded: (payload) =>
    dispatch(setSubscriptionDataLoaded(payload)),
});

export default connect(null, mapDispatchToProps)(CreateSubscription);
