import React from "react";
import Popup from "reactjs-popup";
import PDFPreview from "./PDFPreview";
import CrossIcon from "../icons/CrossIcon";
import "../styles/ContentPreviewPopup.css";

export default class ContentPreviewPopup extends React.Component {
  render() {
    const { ItemData, ButtonText } = this.props;

    var detailTextPreviewString = "";
    const { DetailText } = ItemData;
    if (ItemData && DetailText) {
      const brsReplaced = DetailText ? DetailText.replace(/<br>/g, " ") : "";
      let tempDiv = document.createElement("div");
      tempDiv.innerHTML = brsReplaced;
      detailTextPreviewString = tempDiv.textContent || tempDiv.innerText || "";
    }

    var DetailTextPreview = "";
    if (detailTextPreviewString) {
      DetailTextPreview = detailTextPreviewString;
    }
    if (!detailTextPreviewString) {
      const { DetailTextDescribe, DetailTextLocate, DetailTextOperate } =
        ItemData;
      const detailTextDescribeString = DetailTextDescribe
        ? `${DetailTextDescribe} `
        : "";
      const detailTextLocateString = DetailTextLocate
        ? `${DetailTextLocate} `
        : "";
      const detailTextOperateString = DetailTextOperate
        ? `${DetailTextOperate} `
        : "";
      DetailTextPreview = `${detailTextDescribeString}${detailTextLocateString}${detailTextOperateString}`;
    }

    return (
      <div>
        <Popup
          position="right center"
          contentStyle={{
            border: "none",
            padding: "5px",
            margin: "auto",
            width: "auto",
            height: "auto",
          }}
          trigger={
            <button className="popup-modal-open-button-round">
              <div className="popup-modal-open-button-round-text">
                {ButtonText}
              </div>
            </button>
          }
        >
          {(close) => (
            <>
              <div className="popup-modal-box">
                <div className="popup-modal-close-button-wrapper">
                  <button className="popup-modal-close-button" onClick={close}>
                    <div className="popup-modal-close-button-icon-wrapper">
                      <div className="popup-modal-close-button-icon">
                        <CrossIcon />
                      </div>
                    </div>
                  </button>
                </div>
                <div className="popup-modal-content-wrapper">
                  <div className="infoitem-container">
                    <div className="infoitem-heading">
                      {ItemData.DetailTitle}
                    </div>

                    <div className="infoitem-preview-container">
                      <div className="infoitem-text-preview">
                        {DetailTextPreview}
                      </div>
                    </div>

                    <div className="infoitem-attachments-container">
                      {ItemData.DetailImage && (
                        <div className="infoitem-attachments-banner-container">
                          <div className="infoitem-image-preview-container">
                            {/*     <label className="infoitem-form-label"> Image </label> */}

                            <img
                              className="infoitem-image-preview"
                              src={`${ItemData.DetailImage}?width=150`}
                              alt={ItemData.DetailLabel}
                            />
                          </div>
                        </div>
                      )}

                      {ItemData.DetailVideo && (
                        <div className="infoitem-attachments-banner-container">
                          <div className="infoitem-preview-container">
                            {/*   <label className="infoitem-form-label"> Video </label> */}
                            <div className="infoitem-preview-container-inner">
                              <video
                                className="infoitem-preview-video"
                                src={ItemData.DetailVideo}
                                type="video/mp4"
                              >
                                This browser does not support video. Try Chrome
                                or Safari instead.
                              </video>
                            </div>
                          </div>
                        </div>
                      )}

                      {ItemData.DetailDocument && (
                        <div className="infoitem-attachments-banner-container">
                          <div className="infoitem-preview-container">
                            <div className="infoitem-preview-container-inner">
                              <PDFPreview
                                DetailDocument={ItemData.DetailDocument}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Popup>
      </div>
    );
  }
}
