import React, { useState, useEffect } from "react";
import { previewRootUrl } from "../constants.js";
import "../styles/PreviewButton.css";
import UpperRightArrowSquare from "../icons/UpperRightArrowSquare";
import CirclePlusIcon from "../icons/CirclePlusIcon";

export default function PreviewButton(props) {
  const { onClick, PropertyVanityId, Heading, Style, Type, history } = props;

  const [buttonLabel, setButtonLabel] = useState(Heading);

  const onCreatePlaceClick = () => {
    history.push("/create-property");
  };

  useEffect(() => {
    const updateButtonLabel = () => {
      if (window.innerWidth <= 800) {
        if (Type === "preview-guide") {
          setButtonLabel("Preview");
        }
        if (Type === "create-property") {
          setButtonLabel("Create");
        }
      }
      //   } else if (window.innerWidth <= 768) {
      //   setButtonLabel("This is text for medium screens.");
      else {
        setButtonLabel(Heading);
      }
    };

    updateButtonLabel(); // Set initial text
    window.addEventListener("resize", updateButtonLabel);

    return () => window.removeEventListener("resize", updateButtonLabel);
  }, []);

  return (
    <>
      {Type === "preview-guide" && PropertyVanityId && (
        <a
          href={`${previewRootUrl}${PropertyVanityId}`}
          className={"preview-button-icon-left-container"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className={`preview-button-icon-left-${Style}`}
            onClick={onClick}
          >
            <div className="preview-button-icon-left-icon-and-label-wrapper">
              <div
                className={`preview-button-icon-left-wrapper-inner-${Style}`}
              >
                <UpperRightArrowSquare
                  className={`preview-button-icon-left-icon-${Style}`}
                />
              </div>

              <div>{buttonLabel}</div>
            </div>
          </div>
        </a>
      )}

      {Type === "create-property" && (
        <div
          onClick={onCreatePlaceClick}
          className={"preview-button-icon-left-container"}
        >
          <div
            className={`preview-button-icon-left-${Style}`}
            onClick={onClick}
          >
            <div className="preview-button-icon-left-icon-and-label-wrapper">
              <div
                className={`preview-button-icon-left-wrapper-inner-${Style}`}
              >
                <CirclePlusIcon
                  className={`preview-button-icon-left-icon-${Style}`}
                />
              </div>

              <div>{buttonLabel}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
