import React from "react";
//import ReactGA from "react-ga";
//import { googleAnalyticsId } from "../constants.js";
import InfoItemSubscription from "./InfoItemSubscription";
import DashboardCheckoutForm from "./DashboardCheckoutForm";
import DashboardSubscriptionInvoices from "./DashboardSubscriptionInvoices";
import "../styles/DisplayCanvas.css";
import "../styles/InfoItem.css";

//ReactGA.initialize(googleAnalyticsId);

export default class DashboardSubscriptionInfo extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
    //   ReactGA.pageview(window.location.pathname + window.location.search);
  };

  render() {
    const { SaveStatus, userData, stripeData, currentPageTitle } = this.props;

    return (
      <>
        <div className="h1-header-large"> {currentPageTitle}</div>
        <InfoItemSubscription
          {...this.props}
          SaveStatus={SaveStatus}
          userId={userData._id}
          userData={userData}
          stripeData={stripeData}
          ItemType={"user"}
          ContainerClassName={"infoitem-form-inner-container-left"}
          ItemLabel={"subscription-details"}
          ItemHeading={"Subscription"}
          ItemDescription={"Details of your current subscription plan"}
          //  InputLabelA={"Subscription"}
          ValueA={
            userData.SubscriptionActiveUser && userData.SubscriptionActiveSystem
              ? true
              : false
          }
          //   PayloadA={"SubscriptionActive"}
          IsChecked={
            userData.SubscriptionActiveUser && userData.SubscriptionActiveSystem
              ? true
              : false
          }
          IsRequired={true}
        />

        <DashboardCheckoutForm
          SaveStatus={SaveStatus}
          IsChecked={userData.StripeCardID ? true : false}
          userId={userData._id}
          userData={userData}
          stripeData={stripeData}
          ItemLabel={"payment-details"}
          ItemHeading={"Payment details"}
          ItemDescription={"Update your payment method"}
          InputLabelA={"New credit/debit card details"}
          ValueA={
            userData.StripeCardID
              ? `${userData.StripeCardBrand} **** **** **** ${userData.StripeCardLastFour}`
              : "Card details not entered - Payment required by monthly invoice."
          }
          ValueB={
            userData.StripeCardID
              ? `| Expiry: ${userData.StripeCardExpMonth}/${userData.StripeCardExpYear}`
              : ""
          }
          IsRequired={true}
        />

        <DashboardSubscriptionInvoices
          SaveStatus={SaveStatus}
          stripeData={stripeData}
          ItemLabel={"subscription-invoices"}
          ItemHeading={"Invoices"}
          ItemDescription={"Your Invoices / Receipts"}
        />
      </>
    );
  }
}
