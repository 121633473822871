import React, { useEffect, useState } from "react";
import "../styles/NavigationHeader.css";
import Breadcrumb from "../components/Breadcrumb";
import PreviewButton from "../components/PreviewButton";

export default function NavigationHeader(props) {
  const {
    propertyDetails,
    pathRoot,
    currentPageTitle,
    previousPageTitle,
    previousPagePath,
  } = props;

  const [small, setSmall] = useState(false);

  const handleResize = () => {
    setSmall(window.pageYOffset > 100);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleResize);
    }
    return () => {
      window.removeEventListener("scroll", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className={
          small ? "nav-headerbar-outer-small" : "nav-headerbar-outer-large"
        }
      >
        <div
          className={
            small ? "nav-headerbar-inner-small" : "nav-headerbar-inner-large"
          }
        >
          <div className={"nav-headerbar-inner-left"}>
            <Breadcrumb
              {...props}
              previousPageTitle={previousPageTitle}
              previousPagePath={previousPagePath}
              propertyDetails={propertyDetails}
            />
          </div>

          <div
            className={small ? "h1-header-small" : "h1-header-hidden"}
            id="h1-header"
          >
            {currentPageTitle}
          </div>

          <div className={"nav-headerbar-inner-right"}>
            {propertyDetails && pathRoot !== "shared-content" && (
              <PreviewButton
                {...props}
                Style={"solid"}
                Heading={"Preview guide"}
                PropertyVanityId={propertyDetails.PropertyID}
                Type={"preview-guide"}
              />
            )}
            {!propertyDetails && pathRoot === "all-properties" && (
              <PreviewButton
                {...props}
                Style={"solid"}
                Heading={"Create property"}
                Type={"create-property"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
