import React from "react";
import ReactLoading from "react-loading";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import "../styles/Loading.css";
import GuideyLogo from "../icons/GuideyLogo";

export default class Loading extends React.Component {
  render() {
    const { LoadingText, LoadingCanvas } = this.props;

    if (LoadingCanvas === "loading-page-header-canvas") {
      return (
        <div className="loading-page-header-canvas">
          <div>
            <GuideyLogo width={"180"} height={"auto"} />
          </div>
          <div className="loading-text">
            {LoadingText && LoadingText}
            {!LoadingText && "loading..."}
          </div>

          <div style={{ marginTop: "15px" }}>
            <ReactLoading
              type={"spin"}
              color={"var(--UIcolour1)"}
              height={1}
              width={40}
            />
          </div>
        </div>
      );
    }

    if (LoadingCanvas === "table-loading-canvas") {
      return (
        <div className="table-loading-canvas">
          <div className="loading-text">
            {LoadingText && LoadingText}
            {!LoadingText && "loading..."}
          </div>

          <div style={{ marginTop: "15px" }}>
            <ReactLoading
              type={"spin"}
              color={"var(--UIcolour1)"}
              height={1}
              width={40}
            />
          </div>
        </div>
      );
    }

    if (LoadingCanvas === "success-loading-page-canvas") {
      return (
        <>
          <div className="success-loading-page-canvas">
            <div className="loading-checkmark-icon-wrapper-checked">
              <CheckmarkIcon className="loading-checkmark-icon-checked" />
            </div>

            <div className="loading-text">
              {LoadingText && LoadingText}
              {!LoadingText && "loading..."}
            </div>

            <div style={{ marginTop: "15px" }}>
              <ReactLoading
                type={"spin"}
                color={"var(--UIcolour1)"}
                height={1}
                width={40}
              />
            </div>
          </div>
        </>
      );
    }

    return (
      <div id="loading-page-canvas">
        <div>
          <GuideyLogo width={"180"} height={"auto"} />
        </div>

        <div className="loading-text">
          {LoadingText && LoadingText}
          {!LoadingText && "loading..."}
        </div>

        <div style={{ marginTop: "15px" }}>
          <ReactLoading
            type={"spin"}
            color={"var(--UIcolour1)"}
            height={1}
            width={40}
          />
        </div>
      </div>
    );
  }
}
