import React from "react";
import SharedContentSelector from "./SharedContentSelector";
import NavigationHeader from "./NavigationHeader";
import InfoItemMulti from "./InfoItemMulti";
//import InfoItemMultiThree from "./InfoItemMultiThree";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class PropertyDetailsArrival extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname + window.location.search);
  };

  checkIfChecked = (propertyId, itemId, data) => {
    var filter = data.filter((item) => {
      return (
        item._id === itemId &&
        item.LinkedProperty &&
        item.LinkedProperty.includes(propertyId)
      );
    });
    if (filter[0]) return true;
    if (!filter[0]) return false;
  };

  render() {
    const {
      SaveStatus,
      userData,
      propertyData,
      PropertyId,
      customContent,
      systemContent,
      sharedContent,
      pathRoot,
      isComponentShared,
      SharedContentProfileId,
      AttachContentLoaded,
      FileLoadStatus,
    } = this.props;

    // Extract VanityPropertyId from current page's URL
    const vanityPropertyId = window.location.pathname.split("/")[2];

    const previousPagePathRoot = window.location.pathname.split("/")[1];

    const propertyDataFiltered = propertyData.filter((item) => {
      return item.PropertyID === vanityPropertyId;
    });

    const sharedContentFiltered =
      sharedContent && sharedContent[0]
        ? sharedContent.filter((item) => {
            return (
              item.DetailLabel === "welcome-message" ||
              item.DetailLabel === "directions-info" ||
              item.DetailLabel === "parking-info" ||
              item.DetailLabel === "checking-in-and-out" ||
              item.DetailLabel === "on-the-way-info" ||
              item.DetailLabel === "ahead-of-your-trip-info"
            );
          })
        : null;

    const sharedContentSubContent =
      sharedContent && sharedContent[0]
        ? sharedContent.filter((item) => {
            return item.ContentType === "sub-details";
          })
        : null;

    const propertyDetails = propertyDataFiltered[0];

    const previousPageTitle = isComponentShared
      ? "Shared Property Content"
      : propertyDetails && propertyDetails.PropertyTitle
      ? propertyDetails.PropertyTitle
      : "Dashboard";

    const previousPagePath = isComponentShared
      ? "/shared-content/property"
      : propertyDetails && propertyDetails.PropertyID
      ? `/property/${propertyDetails.PropertyID}`
      : "/";

    const currentPageTitle = "Welcome and Arrival Info";

    return (
      <>
        <NavigationHeader
          {...this.props}
          propertyDetails={propertyDetails}
          pathRoot={pathRoot}
          previousPageTitle={previousPageTitle}
          previousPagePath={previousPagePath}
          currentPageTitle={currentPageTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {currentPageTitle}</div>

            {pathRoot !== "shared-content" &&
              sharedContentFiltered &&
              sharedContentFiltered[0] && (
                <>
                  <div className="infoitem-container-box">
                    <div className="infoitem-container-box-heading">
                      Shared Content
                    </div>
                    {sharedContentFiltered.map((item) => (
                      <SharedContentSelector
                        key={item._id}
                        ItemUniqueId={`shared-content-${item._id}`}
                        ContentId={item._id}
                        PropertyId={propertyDetails._id}
                        SharedContentProfileId={SharedContentProfileId}
                        SaveStatus={SaveStatus}
                        IsChecked={this.checkIfChecked(
                          propertyDetails._id,
                          item._id,
                          sharedContentFiltered
                        )}
                        ItemHeading={item.DetailTitle}
                        AttachContentLoaded={AttachContentLoaded}
                        ItemData={item}
                        ContentType={"property-content"}
                        subContent={
                          sharedContentSubContent
                            ? sharedContentSubContent.filter((subItem) => {
                                return subItem.ParentContent === item._id;
                              })
                            : null
                        }
                      />
                    ))}
                  </div>
                </>
              )}

            <InfoItemMulti
              propertyData={propertyData}
              PropertyId={PropertyId}
              systemContent={systemContent}
              customContent={customContent}
              sharedContent={sharedContent}
              SaveStatus={SaveStatus}
              userId={userData._id}
              DetailLabel={"welcome-message"}
              IsRequired={false}
              ShowDescribeText={true}
              ShowLocateText={false}
              ShowOperateText={false}
              ShowWebLink={false}
              ShowMedia={true}
              InfoType={"system"}
              IsCustomLabel={false}
              ItemUniqueId={"arrival-welcome-message"}
              isComponentShared={isComponentShared}
              SharedContentProfileId={SharedContentProfileId}
              FileLoadStatus={FileLoadStatus}
            />

            <InfoItemMulti
              propertyData={propertyData}
              PropertyId={PropertyId}
              systemContent={systemContent}
              customContent={customContent}
              sharedContent={sharedContent}
              SaveStatus={SaveStatus}
              userId={userData._id}
              DetailLabel={"directions-info"}
              IsRequired={false}
              ShowDescribeText={false}
              ShowLocateText={true}
              ShowOperateText={false}
              ShowWebLink={false}
              ShowMedia={true}
              InfoType={"system"}
              IsCustomLabel={false}
              ItemUniqueId={"arrival-directions-info"}
              isComponentShared={isComponentShared}
              SharedContentProfileId={SharedContentProfileId}
              FileLoadStatus={FileLoadStatus}
            />

            <InfoItemMulti
              propertyData={propertyData}
              PropertyId={PropertyId}
              systemContent={systemContent}
              customContent={customContent}
              sharedContent={sharedContent}
              SaveStatus={SaveStatus}
              userId={userData._id}
              DetailLabel={"ahead-of-your-trip-info"}
              IsRequired={false}
              ShowDescribeText={false}
              ShowLocateText={true}
              ShowOperateText={false}
              ShowWebLink={false}
              ShowMedia={true}
              InfoType={"system"}
              IsCustomLabel={false}
              ItemUniqueId={"arrival-ahead-of-your-trip"}
              isComponentShared={isComponentShared}
              SharedContentProfileId={SharedContentProfileId}
              FileLoadStatus={FileLoadStatus}
            />

            <InfoItemMulti
              propertyData={propertyData}
              PropertyId={PropertyId}
              systemContent={systemContent}
              customContent={customContent}
              sharedContent={sharedContent}
              SaveStatus={SaveStatus}
              userId={userData._id}
              DetailLabel={"on-the-way-info"}
              IsRequired={false}
              ShowDescribeText={true}
              ShowLocateText={false}
              ShowOperateText={false}
              ShowWebLink={false}
              ShowMedia={true}
              InfoType={"system"}
              IsCustomLabel={false}
              ItemUniqueId={"arrival-one-the-way"}
              isComponentShared={isComponentShared}
              SharedContentProfileId={SharedContentProfileId}
              FileLoadStatus={FileLoadStatus}
            />

            <InfoItemMulti
              propertyData={propertyData}
              PropertyId={PropertyId}
              systemContent={systemContent}
              customContent={customContent}
              sharedContent={sharedContent}
              SaveStatus={SaveStatus}
              userId={userData._id}
              DetailLabel={"parking-info"}
              IsRequired={false}
              ShowDescribeText={false}
              ShowLocateText={true}
              ShowOperateText={false}
              ShowWebLink={false}
              ShowMedia={true}
              InfoType={"system"}
              IsCustomLabel={false}
              ItemUniqueId={"arrival-parking-info"}
              isComponentShared={isComponentShared}
              SharedContentProfileId={SharedContentProfileId}
            />

            <InfoItemMulti
              propertyData={propertyData}
              PropertyId={PropertyId}
              systemContent={systemContent}
              customContent={customContent}
              sharedContent={sharedContent}
              SaveStatus={SaveStatus}
              userId={userData._id}
              DetailLabel={"checking-in-and-out"}
              IsRequired={false}
              ShowDescribeText={true}
              ShowLocateText={false}
              ShowOperateText={false}
              ShowWebLink={false}
              ShowMedia={true}
              InfoType={"system"}
              IsCustomLabel={false}
              ItemUniqueId={"arrival-checking-in-and-out"}
              isComponentShared={isComponentShared}
              SharedContentProfileId={SharedContentProfileId}
              FileLoadStatus={FileLoadStatus}
            />
          </div>
        </div>
      </>
    );
  }
}
