import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { updateUserTel } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import InfoItemCheckMark from "./InfoItemCheckMark";
import "../styles/InfoItemTel.css";

export default function PhoneFormInput(props) {
  const {
    itemLabel,
    itemType,
    IsChecked,
    IsRequired,
    ValueA,
    ItemHeading,
    IsNewCustomItem,
    SaveStatus,
    ItemDescription,
    FalseText,
    ContainerClassName,
    InputLabelA,
    managerId,
    managerEmail,
  } = props;
  const dispatch = useDispatch();

  const geoLocationData = useSelector(
    (state) => state.userReducer.user.geoLocationData,
    shallowEqual
  );
  const ContactTel = ValueA ? ValueA : "";

  const defaultInputText = ContactTel;
  const defaultCountry = geoLocationData
    ? geoLocationData.country.toLowerCase()
    : "us";

  //const [isEditing, setIsEditing] = useState(props.IsEditing);
  const [inputText, setInputText] = useState(defaultInputText);

  const onEdit = (e) => {
    e.preventDefault();
    document
      .getElementById(`${itemLabel}-container`)
      .scrollIntoView({ behavior: "smooth" });
    dispatch(setSaveStatus(`${itemLabel}-editing`));
  };

  const onCancel = (e) => {
    e.preventDefault();
    dispatch(setSaveStatus(`${itemLabel}-idle`));
  };

  const onSubmit = () => {
    const type = itemType;
    var payload = {};
    if (type === "user") {
      payload = { userTel: inputText };
    }
    if (type === "manager") {
      payload = {
        managerTel: inputText,
        managerId: managerId,
        managerEmail: managerEmail,
      };
    }

    const label = itemLabel;
    dispatch(updateUserTel(payload, label, type));
  };

  return (
    <>
      <div
        className="infoitem-container"
        id={`${itemLabel}-container`}
        style={{ scrollMarginTop: "var(--headerNavHeight)" }}
      >
        <div className="infoitem-heading-and-button-container">
          <div className="infoitem-heading">
            <InfoItemCheckMark
              IsChecked={IsChecked}
              IsRequired={IsRequired}
              IsCustomContent={ValueA ? true : false}
              ItemHeading={ItemHeading}
              IsNewCustomItem={IsNewCustomItem}
            />
          </div>

          <button
            className="infoitem-edit-button"
            onClick={SaveStatus === `${itemLabel}-editing` ? onCancel : onEdit}
          >
            {SaveStatus === `${itemLabel}-editing` ||
            SaveStatus === `${itemLabel}-fetching`
              ? "Cancel"
              : "Edit"}
          </button>
        </div>

        <div className="infoitem-value">
          {SaveStatus === `${itemLabel}-editing` ||
          SaveStatus === `${itemLabel}-fetching`
            ? `${ItemDescription}`
            : ValueA
            ? ValueA
            : FalseText}
        </div>

        <div
          className={
            SaveStatus === `${itemLabel}-editing` ||
            SaveStatus === `${itemLabel}-fetching`
              ? "show"
              : "hide"
          }
        >
          <div className="infoitem-form-container">
            <div className="infoitem-form">
              <div className={`${ContainerClassName}`}>
                <label className="infoitem-form-label"> {InputLabelA} </label>

                <PhoneInput
                  country={defaultCountry}
                  placeholder={`Enter ${InputLabelA}`}
                  value={inputText}
                  onChange={(phone) => setInputText(phone)}
                  containerClass={"form-phone-input-container"}
                  inputClass={"form-phone-input"}
                  buttonClass={"form-phone-input-dropdown-button"}
                  dropdownClass={"form-phone-input-dropdown"}
                  required={IsRequired}
                />
              </div>
              <div className="infoitem-form-inner-container-left">
                <button onClick={onSubmit} className="infoitem-save-button">
                  {SaveStatus === `${itemLabel}-fetching`
                    ? "Saving..."
                    : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="infoitem-section-break-wrapper">
          <div className="infoitem-section-break"></div>
        </div>
      </div>
    </>
  );
}
