import React from "react";
import "../styles/DisplayCanvas.css";
import AvatarBanner from "../components/AvatarBanner";
import NavigationHeader from "../components/NavigationHeader";

export default class PropertyManagersPage extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  propertiesManaged = (managerId) => {
    const { propertyData } = this.props;
    const propertyDataFiltered = propertyData.filter((Item) => {
      return Item.LinkedPropertyMgr === managerId;
    });
    return propertyDataFiltered.length;
  };

  onClick = () => {
    this.props.history.push("/create-manager");
  };

  render() {
    const { managerData, systemContent } = this.props;

    // Extract placeholder property details from SystemContent
    const propertyManagerPlaceholderFiltered = systemContent.filter(
      (DisplayContent) => {
        return DisplayContent.DetailLabel === "property-manager-placeholder";
      }
    );
    const propertyManagerPlaceholder = propertyManagerPlaceholderFiltered[0];

    const currentPageTitle = "Property Managers / Hosts";

    return (
      <>
        <NavigationHeader
          {...this.props}
          previousPageTitle={"Dashboard"}
          previousPagePath={"/"}
          currentPageTitle={currentPageTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {currentPageTitle}</div>
            <div className="infopage-subheading">
              Find and create property managers
            </div>

            <div className="section-break-wrapper">
              <div className="section-break"></div>
            </div>

            <div className="button-banner-section-outer-container">
              <div className="button-banner-section-container">
                {managerData.length === 1 && (
                  <h3>{managerData.length} Property manager</h3>
                )}

                {(managerData.length > 1 || managerData.length < 1) && (
                  <h3>{managerData.length} Property managers</h3>
                )}

                <div className="button-banners-container">
                  {!managerData[0] && (
                    <div className="button-banner-section-description">
                      {" "}
                      No property managers setup yet{" "}
                    </div>
                  )}

                  {managerData.map((item) => (
                    <div
                      key={item._id}
                      className={
                        managerData.indexOf(item) === 0 ||
                        managerData.indexOf(item) === 6 ||
                        managerData.indexOf(item) === 12
                          ? "button-banner-container-1"
                          : managerData.indexOf(item) === 1 ||
                            managerData.indexOf(item) === 7 ||
                            managerData.indexOf(item) === 13
                          ? "button-banner-container-2"
                          : managerData.indexOf(item) === 2 ||
                            managerData.indexOf(item) === 8 ||
                            managerData.indexOf(item) === 14
                          ? "button-banner-container-3"
                          : managerData.indexOf(item) === 3 ||
                            managerData.indexOf(item) === 9 ||
                            managerData.indexOf(item) === 15
                          ? "button-banner-container-4"
                          : managerData.indexOf(item) === 4 ||
                            managerData.indexOf(item) === 10 ||
                            managerData.indexOf(item) === 16
                          ? "button-banner-container-5"
                          : managerData.indexOf(item) === 5 ||
                            managerData.indexOf(item) === 11 ||
                            managerData.indexOf(item) === 17
                          ? "button-banner-container-6"
                          : "button-banner-container-1"
                      }
                    >
                      <AvatarBanner
                        Image={
                          item.PropertyMgrCoverImage
                            ? item.PropertyMgrCoverImage
                            : propertyManagerPlaceholder.DetailImage
                        }
                        Heading={
                          item.PropertyMgrFirstname && item.PropertyMgrLastname
                            ? `${item.PropertyMgrFirstname} ${item.PropertyMgrLastname}`
                            : item.PropertyMgrFirstname &&
                              !item.PropertyMgrLastname
                            ? `${item.PropertyMgrFirstname}`
                            : propertyManagerPlaceholder.DetailTitle
                        }
                        Status={
                          item.LinkedProperty ? item.LinkedProperty.length : 0
                        }
                        DefaultManager={item.DefaultPropertyMgr}
                        Target={item.ManagerVanityID}
                        managerId={item._id}
                        PropertiesManaged={this.propertiesManaged(item._id)}
                        {...this.props}
                      />
                    </div>
                  ))}
                </div>

                <button className="create-new-button" onClick={this.onClick}>
                  Create new manager
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
