import React from "react";
import NavigationHeader from "../components/NavigationHeader";
import CreatePropertyModule from "../components/CreatePropertyModule";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class CreatePropertyPage extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { SaveStatus, userData, linkedPropertiesLoaded } = this.props;
    const currentPageTitle = "Create a New Property Guide";

    const fromPage = sessionStorage.getItem("routePath")
      ? sessionStorage.getItem("routePath")
      : "dashboard";
    const previousPageTitle =
      fromPage === "dashboard"
        ? "Dashboard"
        : fromPage === "all-properties"
        ? "All Properties"
        : "Dashboard";
    const previousPagePath =
      fromPage === "dashboard"
        ? "/"
        : fromPage === "all-properties"
        ? "/properties"
        : "/";

    return (
      <>
        <NavigationHeader
          {...this.props}
          previousPageTitle={previousPageTitle}
          previousPagePath={previousPagePath}
          currentPageTitle={currentPageTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {currentPageTitle}</div>

            <CreatePropertyModule
              {...this.props}
              SaveStatus={SaveStatus}
              SetupFlow={false}
              ItemType={"property"} // Defines which dispatch to invoke
              ContainerClassName={"infoitem-form-inner-container-full"}
              ItemLabel={"New Property"}
              ItemHeading={"Property Title"}
              InputLabelA={"Property guide name"}
              InputLabelB={"Unique weblink"}
              ValueA={""}
              FalseText={"Not entered"}
              PayloadA={"PropertyTitle"}
              PayloadB={"PropertyID"}
              userId={userData._id}
              linkedPropertiesLoaded={linkedPropertiesLoaded}
            />
          </div>
        </div>
      </>
    );
  }
}
