import React from "react";
import "../styles/Breadcrumb.css";
import ChevronLeftIcon from "../icons/ChevronLeftIcon";

const Breadcrumb = ({
  history,
  propertyDetails,
  previousPagePath,
  previousPageTitle,
  PreviousPageTitle,
  PreviousPagePath,
}) => {
  const previousPath = previousPagePath ? previousPagePath : PreviousPagePath;
  const previousTitle = previousPageTitle
    ? previousPageTitle
    : PreviousPageTitle;

  const handleBack = () => {
    history.push({
      pathname: previousPath,
      pageTitle: previousTitle,
      state: {
        propertyId: propertyDetails ? propertyDetails._id : "",
      },
    });
    //  }
  };

  return (
    <div className="breadcrumb-container">
      {previousPath && (
        <div className="breadcrumb-chevron-icon-wrapper-left">
          <ChevronLeftIcon className="breadcrumb-chevron-icon" />
        </div>
      )}
      <div className="breadcrumb-previous-section" onClick={handleBack}>
        {previousTitle && (
          <div className="breadcrumb-link">{previousTitle}</div>
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;
