import React from "react";
import NavigationHeader from "../components/NavigationHeader";
import PreviewButton from "../components/PreviewButton";
import InfoItemTickBox from "../components/InfoItemTickBox";
import InfoItemImageSelector from "../components/InfoItemImageSelector";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class PropertyMessagingSettingsPage extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { SaveStatus, managerData, propertyData } = this.props;

    // Extract VanityPropertyId from current page's URL
    const vanityPropertyId = window.location.pathname.split("/")[2];

    const previousPagePathRoot = window.location.pathname.split("/")[1];

    const propertyDataFiltered = propertyData.filter((item) => {
      return item.PropertyID === vanityPropertyId;
    });

    const propertyDetails = propertyDataFiltered[0];

    const propertyManagerEnquiriesId = propertyDetails.PropertyMgrEnquiries;

    const managerDataFilteredEnquiries = managerData.filter((item) => {
      return item._id === propertyManagerEnquiriesId;
    });

    const propertyManagerMaintenanceId = propertyDetails.PropertyMgrMaintenance;

    const managerDataFilteredMaintenance = managerData.filter((item) => {
      return item._id === propertyManagerMaintenanceId;
    });

    const propertyManagerDocumentsId = propertyDetails.PropertyMgrDocuments;

    const managerDataFilteredDocuments = managerData.filter((item) => {
      return item._id === propertyManagerDocumentsId;
    });

    //console.log("managerDataFiltered", managerDataFilteredEnquiries[0]);

    const PreviousPageTitle = propertyDetails.PropertyTitle
      ? propertyDetails.PropertyTitle
      : "Unamed Place";

    const currentPageTitle = "Messaging Settings";

    return (
      <>
        <NavigationHeader
          {...this.props}
          propertyDetails={propertyDetails}
          previousPageTitle={PreviousPageTitle}
          previousPagePath={`/${previousPagePathRoot}/${vanityPropertyId}`}
          currentPageTitle={currentPageTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {currentPageTitle}</div>
            <InfoItemTickBox
              propertyDetails={propertyDetails}
              SaveStatus={SaveStatus}
              ContainerClassName={"infoitem-form-inner-container-full"}
              propertyId={propertyDetails._id}
              ItemType={"property"} // Defines which dispatch to invoke
              ItemLabel={"messaging-activation"}
              ItemDescriptionOne={
                "You can choose to activate or deactivate messaging."
              }
              ItemDescriptionTwo={""}
              InputLabelA={""}
              ValueA={
                propertyDetails.CommsActiveStatus
                  ? "Messaging is currently activated"
                  : "Messaging is currently not activated"
              }
              IsChecked={propertyDetails.CommsActiveStatus}
              IsRequired={false}
              ItemHeading={"Activate Messaging"}
              TickBoxText={"Activate Messaging"}
              FalseText={`///${propertyDetails.WhatThreeWordsLocation} not currently being displayed to guests`}
              PayloadA={"CommsActiveStatus"}
            />

            <InfoItemImageSelector
              {...this.props}
              SaveStatus={SaveStatus}
              // systemCategories={systemCategories}
              //  systemContent={systemContent}
              ItemList={managerData}
              propertyDetails={propertyDetails}
              propertyId={propertyDetails._id}
              ItemType={"property"} // Defines which dispatch to invoke
              DataType={"managers"} // Defines the data source so correct db labels are used to find data
              ItemHeading={"General Enquiries"}
              ItemLabel={"property-manager-enquiries"}
              ItemDescription={"Select a team member for general enquiries"}
              InputLabelA={""}
              ValueA={
                managerDataFilteredEnquiries[0]
                  ? managerDataFilteredEnquiries[0].PropertyMgrFirstname
                  : ""
              }
              DefaultImage={
                managerDataFilteredEnquiries[0]
                  ? managerDataFilteredEnquiries[0].PropertyMgrCoverImage
                  : undefined
              }
              DefaultId={
                managerDataFilteredEnquiries[0]
                  ? managerDataFilteredEnquiries[0]._id
                  : ""
              }
              IsChecked={managerDataFilteredEnquiries[0] ? true : false}
              IsRequired={false}
              TrueText={
                managerDataFilteredEnquiries[0] &&
                managerDataFilteredEnquiries[0].PropertyMgrFirstname &&
                managerDataFilteredEnquiries[0].PropertyMgrLastname
                  ? managerDataFilteredEnquiries[0].PropertyMgrFirstname +
                    " " +
                    managerDataFilteredEnquiries[0].PropertyMgrLastname
                  : managerDataFilteredEnquiries[0] &&
                    managerDataFilteredEnquiries[0].PropertyMgrFirstname &&
                    !managerDataFilteredEnquiries[0].PropertyMgrLastname
                  ? managerDataFilteredEnquiries[0].PropertyMgrFirstname
                  : undefined
              }
              FalseText={"Not selected"}
              PayloadA={"PropertyMgrEnquiries"} // Defines which database label to update
            />

            <InfoItemImageSelector
              {...this.props}
              SaveStatus={SaveStatus}
              // systemCategories={systemCategories}
              //  systemContent={systemContent}
              ItemList={managerData}
              propertyDetails={propertyDetails}
              propertyId={propertyDetails._id}
              ItemType={"property"} // Defines which dispatch to invoke
              DataType={"managers"} // Defines the data source so correct db labels are used to find data
              ItemHeading={"Maintenance"}
              ItemLabel={"property-manager-maintenance"}
              ItemDescription={"Select a team member for maintenance issues"}
              InputLabelA={""}
              ValueA={
                managerDataFilteredMaintenance[0]
                  ? managerDataFilteredMaintenance[0].PropertyMgrFirstname
                  : ""
              }
              DefaultImage={
                managerDataFilteredMaintenance[0]
                  ? managerDataFilteredMaintenance[0].PropertyMgrCoverImage
                  : undefined
              }
              DefaultId={
                managerDataFilteredMaintenance[0]
                  ? managerDataFilteredMaintenance[0]._id
                  : ""
              }
              IsChecked={managerDataFilteredMaintenance[0] ? true : false}
              IsRequired={false}
              TrueText={
                managerDataFilteredMaintenance[0] &&
                managerDataFilteredMaintenance[0].PropertyMgrFirstname &&
                managerDataFilteredMaintenance[0].PropertyMgrLastname
                  ? managerDataFilteredMaintenance[0].PropertyMgrFirstname +
                    " " +
                    managerDataFilteredMaintenance[0].PropertyMgrLastname
                  : managerDataFilteredMaintenance[0] &&
                    managerDataFilteredMaintenance[0].PropertyMgrFirstname &&
                    !managerDataFilteredMaintenance[0].PropertyMgrLastname
                  ? managerDataFilteredMaintenance[0].PropertyMgrFirstname
                  : undefined
              }
              FalseText={"Not selected"}
              PayloadA={"PropertyMgrMaintenance"} // Defines which database label to update
            />

            <InfoItemImageSelector
              {...this.props}
              SaveStatus={SaveStatus}
              // systemCategories={systemCategories}
              //  systemContent={systemContent}
              ItemList={managerData}
              propertyDetails={propertyDetails}
              propertyId={propertyDetails._id}
              ItemType={"property"} // Defines which dispatch to invoke
              DataType={"managers"} // Defines the data source so correct db labels are used to find data
              ItemHeading={"Document Uploads"}
              ItemLabel={"property-manager-documents"}
              ItemDescription={"Select a team member for document uploads"}
              InputLabelA={""}
              ValueA={
                managerDataFilteredDocuments[0]
                  ? managerDataFilteredDocuments[0].PropertyMgrFirstname
                  : ""
              }
              DefaultImage={
                managerDataFilteredDocuments[0]
                  ? managerDataFilteredDocuments[0].PropertyMgrCoverImage
                  : undefined
              }
              DefaultId={
                managerDataFilteredDocuments[0]
                  ? managerDataFilteredDocuments[0]._id
                  : ""
              }
              IsChecked={managerDataFilteredDocuments[0] ? true : false}
              IsRequired={false}
              TrueText={
                managerDataFilteredDocuments[0] &&
                managerDataFilteredDocuments[0].PropertyMgrFirstname &&
                managerDataFilteredDocuments[0].PropertyMgrLastname
                  ? managerDataFilteredDocuments[0].PropertyMgrFirstname +
                    " " +
                    managerDataFilteredDocuments[0].PropertyMgrLastname
                  : managerDataFilteredDocuments[0] &&
                    managerDataFilteredDocuments[0].PropertyMgrFirstname &&
                    !managerDataFilteredDocuments[0].PropertyMgrLastname
                  ? managerDataFilteredDocuments[0].PropertyMgrFirstname
                  : undefined
              }
              FalseText={"Not selected"}
              PayloadA={"PropertyMgrDocuments"} // Defines which database label to update
            />
          </div>
        </div>
      </>
    );
  }
}
