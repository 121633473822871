import React, { useState } from "react";
import ChevronRightIcon from "../icons/ChevronRightIcon";
import "../styles/ImageBanners.css";
import "../styles/ButtonBanners.css";

export default function ImageBanner(props) {
  const { Image, Heading, Target, Status, PropertyId } = props;

  const [isLoading, setIsLoading] = useState(true);

  const onLoad = () => {
    setIsLoading(false);
  };

  const handleClick = () => {
    props.history.push({
      pathname: "/" + Target,
      state: { propertyId: PropertyId },
    });
  };

  const InnerWrapperStyle = isLoading
    ? "image-banner-main-image-wrapper-loading"
    : "";
  const ImageStyle = isLoading
    ? "image-banner-main-image-loading image-banner-main-image-loading-animate"
    : "image-banner-main-image";

  const imageSourceOptimized = `${Image}?width=300`;

  return (
    <>
      <div className="image-banner" onClick={handleClick}>
        <div className={InnerWrapperStyle}>
          <img
            className={ImageStyle}
            src={imageSourceOptimized}
            alt={Heading}
            onLoad={onLoad}
          />
        </div>

        <div className="button-banner-heading-and-chevron-wrapper">
          <div className="button-banner-heading">{Heading}</div>
          <div className="button-banner-chevron-icon-wrapper">
            <ChevronRightIcon className="button-banner-chevron-icon" />
          </div>
        </div>

        <div className="image-banner-subheading">
          {Status ? "Live" : "Inactive"}
        </div>
      </div>
    </>
  );
}
