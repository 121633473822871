import React, { useState } from "react";
import ChevronRightIcon from "../icons/ChevronRightIcon";
import "../styles/ButtonBanners.css";
import "../styles/AvatarBanners.css";

export default function AvatarBanner(props) {
  const { Image, Heading, Target, DefaultManager, PropertiesManaged } = props;
  const [loading, setLoading] = useState(false);

  const handleClick = (e) => {
    setLoading(true);
    props.history.push("/manager-profile/" + Target);
  };

  return (
    <>
      {loading && <div> Loading... </div>}

      <div className="avatar-banner" onClick={handleClick}>
        {DefaultManager && <div className="button-banner-label">DEFAULT</div>}
        <div>
          <img
            className="avatar-banner-main-image"
            src={`${Image}?width=100`}
            alt={Heading}
          />
        </div>

        <div className="button-banner-heading-and-chevron-wrapper">
          <div className="button-banner-heading">{Heading}</div>

          <div className="button-banner-chevron-icon-wrapper">
            <ChevronRightIcon className="button-banner-chevron-icon" />
          </div>
        </div>

        <div className="avatar-banner-subheading">
          {PropertiesManaged > 1
            ? `${PropertiesManaged} properties`
            : PropertiesManaged === 1
            ? `${PropertiesManaged} property`
            : "No properties"}
        </div>
      </div>
    </>
  );
}
