import React from "react";
import { connect } from "react-redux";
//import { workflowApiRoot } from "../constants.js";
import { autoLogin } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import InfoItemCheckMark from "./InfoItemCheckMark";
import "../styles/InfoItem.css";
import FormattedDaT from "./FormattedDaT";
import LabelIcon from "../icons/LabelIcon";
import WalletIcon from "../icons/WalletIcon";
import CircularArrowsIcon from "../icons/CircularArrowsIcon";
import HomeIcon from "../icons/HomeIcon";

class InfoItemSubscription extends React.Component {
  state = {
    isEditing: this.props.IsEditing,
    payload: {},
    cancelIsActive: false,
  };

  onChange = (e) => {
    e.persist();
    var payloadA = { ...this.state.payload };
    payloadA[e.target.name] = e.target.value;
    this.setState(() => ({
      payload: { ...payloadA },
    }));
  };

  onEdit = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    document
      .getElementById(`${ItemLabel}-container`)
      .scrollIntoView({ behavior: "smooth" });
    this.props.setSaveStatus(`${ItemLabel}-editing`);
  };

  onCancel = (e) => {
    const { ItemLabel } = this.props;
    e.preventDefault();
    this.props.setSaveStatus(`${ItemLabel}-idle`);
    this.setState(() => ({
      cancelIsActive: false,
    }));
  };

  onClickCancel = () => {
    this.props.history.push("/cancellation");
  };

  onCancelSubscription = () => {
    this.setState(() => ({
      cancelIsActive: true,
    }));
  };

  onClickNoCancel = () => {
    this.setState(() => ({
      cancelIsActive: false,
    }));
  };

  // onSubmit = (e) => {
  //   const { ItemLabel, stripeData } = this.props;

  //  const subscriptionData = stripeData.subscriptionData;

  //  const subscriptionId = { subscriptionId: subscriptionData._api_c2_body.id };
  //  e.preventDefault();

  //  this.props.setSaveStatus(`${ItemLabel}-fetching`);
  //  fetch(`${workflowApiRoot}cancel-subscription`, {
  //  method: "POST",
  //  headers: {
  //    "Content-Type": "application/json",
  //   Accept: "application/json",
  //  Authorization: `Bearer ${sessionStorage.getItem("token")}`
  //  },
  //  body: JSON.stringify(subscriptionId)
  // })
  //  .then((response) => response.json())
  //   .then((results) => {
  //   console.log("results", results);
  //    if (
  //      results.status !== "success" ||
  //    results.response.subscriptionStatus !== "canceled"
  //  ) {
  //    this.props.setSaveStatus(`${ItemLabel}-error`);
  //   }
  //  if (
  //    results.status === "success" &&
  //   results.response.subscriptionStatus === "canceled"
  //  ) {
  //      this.props.setSaveStatus(`${ItemLabel}-success`);
  //    this.props.autoLogin(ItemLabel);
  //  }
  //  });
  //};

  render() {
    const {
      SaveStatus,
      ItemLabel,
      ItemHeading,
      IsChecked,
      stripeData,
      //   userData
    } = this.props;

    const subscriptionData = stripeData.subscriptionData;
    const upcomingInvoiceData = stripeData.upcomingInvoiceData;
    // const cardOnFileFailed = userData.CardOnFileFailed
    //console.log("stripeData", stripeData);
    //  console.log("upcomingInvoiceData", upcomingInvoiceData);

    const multiTierData = upcomingInvoiceData["_api_c2_body.lines.data"];

    const subscriptionInterval = multiTierData[0]["_api_c2_plan.interval"];
    const subscriptionTitle =
      subscriptionInterval === "year"
        ? "airguide™ Annual Subscription"
        : subscriptionInterval === "month"
        ? "airguide™ Monthly Subscription"
        : subscriptionInterval === "day"
        ? "airguide™ Daily Subscription"
        : "airguide™ Monthly Subscription";
    const currency = upcomingInvoiceData["_api_c2_body.currency"];
    const amountDue = upcomingInvoiceData["_api_c2_body.amount_due"];
    const nextPaymentDate = upcomingInvoiceData[
      "_api_c2_body.next_payment_attempt"
    ]
      ? upcomingInvoiceData["_api_c2_body.next_payment_attempt"]
      : upcomingInvoiceData["_api_c2_body.due_date"];
    const currentPeriodStartDate =
      subscriptionData["_api_c2_body.current_period_start"];
    const paymentPastDue =
      subscriptionData["_api_c2_body.status"] === "past_due" ||
      subscriptionData["_api_c2_body.status"] === "incomplete"
        ? true
        : false;

    return (
      <>
        <div
          className="infoitem-container"
          id={`${ItemLabel}-container`}
          style={{ scrollMarginTop: "var(--headerNavHeight)" }}
        >
          <div className="infoitem-heading-and-button-container">
            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={paymentPastDue ? false : IsChecked}
                IsRequired={true}
                IsCustomContent={true}
                ItemHeading={ItemHeading}
              />
            </div>

            <button
              className="infoitem-edit-button"
              onClick={
                SaveStatus === `${ItemLabel}-editing`
                  ? this.onCancel
                  : this.onEdit
              }
            >
              {SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "Cancel"
                : "Edit"}
            </button>
          </div>

          <div className="subscription-product-container">
            <div className="subscription-product-text">
              <strong>{subscriptionTitle} </strong>
            </div>

            {subscriptionData["_api_c2_body.status"] === "trialing" && (
              <>
                <div className="icon-and-text-inline-container">
                  <LabelIcon className="icon-and-text-inline-icon" />
                  <div className="icon-and-text-inline-text">
                    <>
                      Recurring billing starts <strong>only</strong> after your
                      free trial ends
                    </>
                  </div>
                </div>
              </>
            )}

            {multiTierData.map((item) => (
              <div
                className="icon-and-text-inline-container"
                key={item._api_c2_id}
              >
                <HomeIcon className="icon-and-text-inline-icon" />
                <div className="icon-and-text-inline-text">
                  <>{item._api_c2_description}</>
                </div>
              </div>
            ))}

            <div className="icon-and-text-inline-container">
              <WalletIcon className="icon-and-text-inline-icon" />
              <div className="icon-and-text-inline-text">
                <>
                  {subscriptionData["_api_c2_body.status"] === "trialing" && (
                    <>
                      Your{" "}
                      {subscriptionInterval === "year"
                        ? "annual"
                        : subscriptionInterval === "day"
                        ? "daily"
                        : "monthly"}{" "}
                      payment amount will be:{" "}
                    </>
                  )}
                  {subscriptionData["_api_c2_body.status"] !== "trialing" && (
                    <>
                      Total{" "}
                      {subscriptionInterval === "year"
                        ? "annual"
                        : subscriptionInterval === "day"
                        ? "daily"
                        : "monthly"}{" "}
                      payment amount:{" "}
                    </>
                  )}
                  <strong>
                    {currency === "gbp" && <>£</>}
                    {currency === "usd" && <>$</>}
                    {currency === "eur" && <>€</>}
                    {currency === "aud" && <>$</>}
                    {(amountDue / 100).toFixed(2)} per{" "}
                    {subscriptionInterval === "year"
                      ? "year"
                      : subscriptionInterval === "day"
                      ? "day"
                      : "month"}
                  </strong>
                </>
              </div>
            </div>

            <div className="icon-and-text-inline-container">
              <CircularArrowsIcon className="icon-and-text-inline-icon" />
              <div className="icon-and-text-inline-text">
                {subscriptionData["_api_c2_body.status"] === "trialing" && (
                  <>
                    Starting on:{" "}
                    <strong>
                      <FormattedDaT
                        DateInput={
                          new Date(
                            subscriptionData["_api_c2_body.trial_end"] * 1000
                          )
                        }
                      />
                    </strong>
                  </>
                )}

                {paymentPastDue && (
                  <>
                    Payment was due on{" "}
                    <FormattedDaT
                      DateInput={new Date(currentPeriodStartDate * 1000)}
                    />
                    .{" "}
                    <strong>
                      Please add a new card below to avoid your account being
                      cancelled
                    </strong>
                    .
                  </>
                )}

                {subscriptionData["_api_c2_body.status"] === "active" && (
                  <>
                    Your next payment is due on{" "}
                    <FormattedDaT
                      DateInput={new Date(nextPaymentDate * 1000)}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div
            className={
              SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "show"
                : "hide"
            }
          >
            <div className="infoitem-form-container">
              <div className="infoitem-form-label">
                To edit or cancel your subscription please{" "}
                <span
                  onClick={this.onClickCancel}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  fill in this form
                </span>{" "}
                and we will process your request as soon as possible.
              </div>

              {
                //            {!this.state.cancelIsActive && (
                //              <button
                //                onClick={this.onCancelSubscription}
                //                className="cancel-button-inverted"
                //              >
                //               Cancel subscription
                //             </button>
                //            )}
                //
                //           {this.state.cancelIsActive && (
                //             <>
                //              <div style={{ height: "15px" }}> </div>
                //               <div className="infoitem-form-label">
                //                 Are you sure you want to cancel your subscription?
                //               </div>
                //               <div className="cancel-options-buttons-container">
                //                 <button
                //                   onClick={this.onClickNoCancel}
                //                   className="keep-button-inverted"
                //                 >
                //                   Keep plan
                //                 </button>
                //                 <button onClick={this.onSubmit} className="cancel-button">
                //                   {SaveStatus === `${ItemLabel}-fetching`
                //                     ? "Cancelling..."
                //                     : "Cancel subscription"}
                //                 </button>
                //               </div>
                //             </>
                //          )}
              }
            </div>
          </div>

          <div className="infoitem-section-break-wrapper">
            <div className="infoitem-section-break"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  autoLogin: (ItemLabel) => dispatch(autoLogin(ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
});

export default connect(null, mapDispatchToProps)(InfoItemSubscription);
