import React from "react";
import { appConfig } from "../data/appConfig";
import "../styles/DisplayCanvas.css";
import LocalProfileImageBanner from "../components/LocalProfileImageBanner";
import NotificationBanner from "../components/NotificationBanner";
import NavigationHeader from "../components/NavigationHeader";

export default class LocalAreaProfilesPage extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  propertiesManaged = (managerId) => {
    const { propertyData } = this.props;
    const propertyDataFiltered = propertyData.filter((Item) => {
      return Item.LinkedPropertyMgr === managerId;
    });
    return propertyDataFiltered.length;
  };

  onClick = () => {
    this.props.history.push("/create-local-area-profile");
  };

  render() {
    const { systemContent, sharedLocalAreaContentProfiles } = this.props;

    // Extract placeholder property details from SystemContent
    const placeholderContentFiltered = systemContent.filter(
      (DisplayContent) => {
        return DisplayContent.DetailLabel === "property-details-placeholder";
      }
    );
    const placeholderContent = placeholderContentFiltered[0];

    const currentPageTitle = "Local Area Profiles";

    return (
      <>
        <NavigationHeader
          {...this.props}
          previousPageTitle={"Dashboard"}
          previousPagePath={"/"}
          currentPageTitle={currentPageTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {currentPageTitle}</div>
            <div className="infopage-subheading">
              Create and edit local area profiles to share across multiple
              properties
            </div>

            <NotificationBanner
              Text={
                "Save time! Any content you set up in this section can be shared across all of your properties with the click of a button"
              }
              DisplayButton={true}
              ExternalLink={true}
              ButtonText={"How it works"}
              Target={`${appConfig.siteUrl}/help/shared-content`}
              IconType={"Bell"}
              IconColour={"#1e8fc1"}
              TextColour={"#1e8fc1"}
              ButtonColour={"#1e8fc1"}
              BorderColour={"#1e8fc1"}
              {...this.props}
            />

            <div className="section-break-wrapper">
              <div className="section-break"></div>
            </div>

            <div className="button-banner-section-outer-container">
              <div className="button-banner-section-container">
                {sharedLocalAreaContentProfiles &&
                  sharedLocalAreaContentProfiles.length === 1 && (
                    <h3>
                      {sharedLocalAreaContentProfiles.length} Local area profile
                    </h3>
                  )}

                {((sharedLocalAreaContentProfiles &&
                  sharedLocalAreaContentProfiles.length > 1) ||
                  (sharedLocalAreaContentProfiles &&
                    sharedLocalAreaContentProfiles.length === 0)) && (
                  <h3>
                    {sharedLocalAreaContentProfiles.length} Local area profiles
                  </h3>
                )}

                <div className="button-banners-container">
                  {(!sharedLocalAreaContentProfiles ||
                    (sharedLocalAreaContentProfiles &&
                      !sharedLocalAreaContentProfiles[0])) && (
                    <div className="button-banner-section-description">
                      No Local area profiles setup yet
                    </div>
                  )}

                  {sharedLocalAreaContentProfiles &&
                    sharedLocalAreaContentProfiles.map((item) => (
                      <div
                        key={item._id}
                        className={
                          sharedLocalAreaContentProfiles.indexOf(item) === 0 ||
                          sharedLocalAreaContentProfiles.indexOf(item) === 6 ||
                          sharedLocalAreaContentProfiles.indexOf(item) === 12
                            ? "button-banner-container-1"
                            : sharedLocalAreaContentProfiles.indexOf(item) ===
                                1 ||
                              sharedLocalAreaContentProfiles.indexOf(item) ===
                                7 ||
                              sharedLocalAreaContentProfiles.indexOf(item) ===
                                13
                            ? "button-banner-container-2"
                            : sharedLocalAreaContentProfiles.indexOf(item) ===
                                2 ||
                              sharedLocalAreaContentProfiles.indexOf(item) ===
                                8 ||
                              sharedLocalAreaContentProfiles.indexOf(item) ===
                                14
                            ? "button-banner-container-3"
                            : sharedLocalAreaContentProfiles.indexOf(item) ===
                                3 ||
                              sharedLocalAreaContentProfiles.indexOf(item) ===
                                9 ||
                              sharedLocalAreaContentProfiles.indexOf(item) ===
                                15
                            ? "button-banner-container-4"
                            : sharedLocalAreaContentProfiles.indexOf(item) ===
                                4 ||
                              sharedLocalAreaContentProfiles.indexOf(item) ===
                                10 ||
                              sharedLocalAreaContentProfiles.indexOf(item) ===
                                16
                            ? "button-banner-container-5"
                            : sharedLocalAreaContentProfiles.indexOf(item) ===
                                5 ||
                              sharedLocalAreaContentProfiles.indexOf(item) ===
                                11 ||
                              sharedLocalAreaContentProfiles.indexOf(item) ===
                                17
                            ? "button-banner-container-6"
                            : "button-banner-container-1"
                        }
                      >
                        <LocalProfileImageBanner
                          Heading={item.Title}
                          Image={
                            item.CoverImage
                              ? item.CoverImage
                              : placeholderContent.DetailImage
                          }
                          Status={true}
                          Target={`local-area-profile/${item.Label}`}
                          SharedContentProfileId={item._id}
                          ItemData={item}
                          {...this.props}
                        />
                      </div>
                    ))}
                </div>

                <button className="create-new-button" onClick={this.onClick}>
                  Create new profile
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
