import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import ConversationResponseSubmitButton from "./ConversationResponseSubmitButton";
import ConversationResponseFileUploader from "./ConversationResponseFileUploader";
import FormFileListItem from "./FormFileListItem";
import { clearConversationFileUploads } from "../redux/actions/userActions";
import "../styles/ConversationResponse.css";

export default function ConversationResponse(props) {
  const textAreaTag = useRef(null);
  const [inputMode, setinputMode] = useState("none"); // Hide mobile keypad initially as set to autofocus
  const { conversationData, conversationMessageCount } = props;
  const [state, setState] = useState({
    messageState: {
      DetailText: "",
      conversationId: conversationData._id,
      propertyId: conversationData.LinkedProperty
    }
  });

  const conversationFileUploadData = useSelector(
    (state) => state.conversationsReducer.conversationFileUploads,
    shallowEqual
  );

  const propertyManagers = useSelector(
    (state) => state.userReducer.user.linkedPropertyManagers,
    shallowEqual
  );

  const assignedPropertyManager = propertyManagers
    ? propertyManagers.filter(
        (item) => item._id === conversationData.LinkedPropertyManager
      )[0]
    : {};

  const inputId = "conversationMessageText";

  const onChange = (e) => {
    e.persist();
    setMessageState("DetailText", e.target.value);
  };

  const dispatch = useDispatch();

  const setMessageState = (name, payload) => {
    setState({
      ...state,
      messageState: {
        ...state.messageState,
        [name]: payload
      }
    });
    sessionStorage.setItem(`${inputId}`, payload);
    if (state.fileUploading) {
      //    setFileUploading(false);
    }
  };

  //  const sendMessage = () => {
  //   const { propertyId, conversationId, DetailText } = state.messageState;
  //   dispatch(sendOutboundMessage(propertyId, conversationId, DetailText));
  // };

  useEffect(() => {
    textAreaTag.current.blur(); // Unfocus
    setinputMode("text"); // Re-enable mobile keypad visibility immediately post-render
    sessionStorage.setItem(`${inputId}`, "");
    dispatch(clearConversationFileUploads());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log("conversationMessageCount", conversationMessageCount);
    setMessageState("DetailText", "");
    //  console.log("runs");
    sessionStorage.setItem(`${inputId}`, "");
    dispatch(clearConversationFileUploads());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationMessageCount]);

  return (
    <>
      <div className={"conversation-response"} id={"conversation-response"}>
        <div className="conversation-response-outer-container">
          <div className="conversation-response-inner-top-container">
            <img
              className="conversation-response-avatar-image"
              src={`${assignedPropertyManager.PropertyMgrCoverImage}?width=30`}
              alt={"Heading"}
            />

            <div className="conversation-response-textarea-container">
              <TextareaAutosize
                autoFocus
                type={"text"}
                ref={textAreaTag}
                inputMode={inputMode}
                id={"message-text-input"}
                name={"DetailText"}
                className={"conversation-response-textarea"}
                placeholder={"Write a message..."}
                onChange={onChange}
                value={state.messageState.DetailText}
              />
            </div>

            <ConversationResponseFileUploader
              {...props}
              dataName={"DetailFiles"}
              dataLabel={"Attach up to 10 files"}
              // placeholderText={"Attach files"}
              inputId={"formDetailFiles"}
              inputTag={"n/a"}
              inputType={"n/a"}
              translateText={false}
            />

            <ConversationResponseSubmitButton
              buttonId={"conversation-response-submit-button"}
              // translateText={true}
              //  onSubmit={sendMessage}
              buttonLabelStatic={"Send"}
              buttonLabelLoading={"..."}
            />
          </div>

          {conversationFileUploadData.length > 0 && (
            <div className="conversation-response-inner-bottom-container">
              <div className="conversation-response-file-list-container">
                {conversationFileUploadData.map((item) => (
                  <FormFileListItem
                    {...props}
                    key={item.fileName}
                    ItemId={"item.infodetailid"}
                    Heading={item.fileName}
                    CategoryLabel={"item.infodetailcategorylabel"}
                    SubCategoryLabel={"item.infodetailsubcategorylabel"}
                    DetailLabel={"item.infodetaillabel"}
                    DetailImage={item.fileData}
                    PropertyID={"propertyContent.PropertyID"}
                    TopLevelPath="featured"
                    ContentType={"details"}
                    Translate={false}
                    fileUploading={false}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
