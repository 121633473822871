import React from "react";
import { connect } from "react-redux";
import { updateUser } from "../redux/actions/userActions";
import { updateCompany } from "../redux/actions/userActions";
import { updateManager } from "../redux/actions/userActions";
import { updateProperty } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import { googleLanguageList } from "../data/googleLanguageList";
import InfoItemCheckMark from "./InfoItemCheckMark";
import "../styles/InfoItem.css";
import "../styles/InfoItemLanguageSingle.css";

class InfoItemLanguageSingle extends React.Component {
  state = {
    payload: { DefaultLanguage: this.props.ValueA },
    languages: {},
  };

  createLanguages = () => {
    this.setState({
      languages: googleLanguageList,
    });
  };

  onChange = (e) => {
    e.persist();

    this.setState(() => ({
      payload: { DefaultLanguage: e.target.value },
    }));
  };

  onEdit = (e) => {
    e.preventDefault();
    const { ItemLabel } = this.props;
    document
      .getElementById(`${ItemLabel}-container`)
      .scrollIntoView({ behavior: "smooth" });
    this.props.setSaveStatus(`${ItemLabel}-editing`);
  };

  onCancel = (e) => {
    e.preventDefault();
    const { ItemLabel } = this.props;
    this.props.setSaveStatus(`${ItemLabel}-idle`);
    this.setState(() => ({
      payload: { DefaultLanguage: this.props.ValueA },
    }));
  };

  onSubmit = (e) => {
    const { ItemLabel, ItemType, userId, companyId, managerId, propertyId } =
      this.props;

    e.preventDefault();
    if (ItemType === "company") {
      this.props.updateCompany(this.state.payload, companyId, ItemLabel);
    }
    if (ItemType === "manager") {
      this.props.updateManager(this.state.payload, managerId, ItemLabel);
    }
    if (ItemType === "property") {
      this.props.updateProperty(this.state.payload, propertyId, ItemLabel);
    }
    if (ItemType === "user") {
      this.props.updateUser(this.state.payload, userId, ItemLabel);
    }
  };

  componentDidMount = () => {
    this.createLanguages();
  };

  render() {
    const {
      SaveStatus,
      ItemLabel,
      ItemHeading,
      ItemDescription,
      ValueA,
      IsChecked,
      IsRequired,
      //  TrueText,
      FalseText,
    } = this.props;

    const languageList = this.state.languages ? this.state.languages : {};

    const languageFilter =
      languageList.length > 0
        ? languageList.filter((item) => {
            return item.language === ValueA;
          })
        : {};

    const TrueText =
      languageList.length > 0 && languageFilter
        ? `${
            languageFilter[0].name
          } (${languageFilter[0].language.toUpperCase()})`
        : "";

    return (
      <>
        <div
          className="infoitem-container"
          id={`${ItemLabel}-container`}
          style={{ scrollMarginTop: "var(--headerNavHeight)" }}
        >
          <div className="infoitem-heading-and-button-container">
            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={IsChecked}
                IsRequired={IsRequired}
                IsCustomContent={ValueA ? true : false}
                ItemHeading={ItemHeading}
              />
            </div>

            <button
              className="infoitem-edit-button"
              onClick={
                SaveStatus === `${ItemLabel}-editing`
                  ? this.onCancel
                  : this.onEdit
              }
            >
              {SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "Cancel"
                : "Edit"}
            </button>
          </div>

          <div className="infoitem-value">
            {SaveStatus === `${ItemLabel}-editing` ||
            SaveStatus === `${ItemLabel}-fetching`
              ? `${ItemDescription}`
              : ValueA
              ? TrueText
              : FalseText}
          </div>

          <div
            className={
              SaveStatus === `${ItemLabel}-editing` ||
              SaveStatus === `${ItemLabel}-fetching`
                ? "show"
                : "hide"
            }
          >
            {languageList && (
              <div className="infoitem-form-container">
                <div className="infoitem-form">
                  <div className="radio-button-list-container">
                    {languageList.length > 0 &&
                      languageList.map((item) => (
                        <div key={item.language}>
                          <input
                            type="radio"
                            className="radio-button-list-item"
                            id={item.language}
                            name={"languages"}
                            value={item.language}
                            onChange={this.onChange}
                            checked={
                              item.language ===
                              this.state.payload.DefaultLanguage
                                ? "checked"
                                : ""
                            }
                          ></input>
                          <label
                            className="radio-button-list-item-text"
                            htmlFor={item.language}
                          >{`${
                            item.name
                          } (${item.language.toUpperCase()})`}</label>
                        </div>
                      ))}
                  </div>

                  <div className="infoitem-form-inner-container-left">
                    <button
                      onClick={this.onSubmit}
                      className="infoitem-save-button"
                    >
                      {SaveStatus === `${ItemLabel}-fetching`
                        ? "Saving..."
                        : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="infoitem-section-break-wrapper">
            <div className="infoitem-section-break"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userInfo, userId, ItemLabel) =>
    dispatch(updateUser(userInfo, userId, ItemLabel)),
  updateManager: (managerInfo, managerId, ItemLabel) =>
    dispatch(updateManager(managerInfo, managerId, ItemLabel)),
  updateCompany: (companyInfo, companyId, ItemLabel) =>
    dispatch(updateCompany(companyInfo, companyId, ItemLabel)),
  updateProperty: (propertyInfo, propertyId, ItemLabel) =>
    dispatch(updateProperty(propertyInfo, propertyId, ItemLabel)),
  setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
});

export default connect(null, mapDispatchToProps)(InfoItemLanguageSingle);
