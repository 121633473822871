import React from "react";
import Popup from "reactjs-popup";
import InfoItemTwoBox from "./InfoItemTwoBox";
import InfoItemImage from "./InfoItemImage";
import InfoItemOneBox from "./InfoItemOneBox";
import InfoItemTel from "./InfoItemTel";
import CrossIcon from "../icons/CrossIcon";
import "../styles/ContentPreviewPopup.css";
import "../styles/InfoItemPopup.css";

export default class InfoItemPopup extends React.Component {
  render() {
    const {
      ItemData,
      ButtonText,
      userData,
      // companyData,
      //  managerData,
      //  propertyData,
      SaveStatus,
    } = this.props;

    const propertyManager = ItemData;
    return (
      <div>
        <Popup
          modal
          lockScroll={true}
          contentStyle={{
            border: "none",
            padding: "5px",
            margin: "auto",
            width: "auto",
            height: "auto",
            overflowY: "scroll",
            overflowX: "scroll",
          }}
          trigger={
            <button className="infoitemmulti-popup-modal-open-button-round">
              <div className="infoitemmulti-popup-modal-open-button-round-text">
                {ButtonText}
              </div>
            </button>
          }
        >
          {(close) => (
            <>
              <div className="infoitemmulti-popup-modal-box">
                <div className="popup-modal-close-button-wrapper">
                  <button className="popup-modal-close-button" onClick={close}>
                    <div className="popup-modal-close-button-icon-wrapper">
                      <div className="popup-modal-close-button-icon">
                        <CrossIcon />
                      </div>
                    </div>
                  </button>
                </div>
                <div className="infoitemmulti-popup-modal-content-wrapper">
                  <div className="infoitem-popup-container">
                    <InfoItemTwoBox
                      SaveStatus={SaveStatus}
                      managerId={propertyManager._id}
                      ItemType={"manager"} // Defines which dispatch to invoke
                      ItemLabel={"Name"}
                      ItemHeading={"Name"}
                      ItemDescription={
                        "This is the name of the property manager / host"
                      }
                      InputLabelA={"First name"}
                      ValueA={propertyManager.PropertyMgrFirstname}
                      PayloadA={"PropertyMgrFirstname"}
                      InputLabelB={"Last name"}
                      ValueB={propertyManager.PropertyMgrLastname}
                      PayloadB={"PropertyMgrLastname"}
                      IsChecked={
                        propertyManager.PropertyMgrFirstname ||
                        propertyManager.PropertyMgrLastname
                          ? true
                          : false
                      }
                      IsRequired={false}
                      FalseText={"Not entered"}
                      userId={userData._id}
                    />

                    <InfoItemImage
                      SaveStatus={SaveStatus}
                      managerId={propertyManager._id}
                      userId={userData._id}
                      //  companyId={companyData._id}
                      ItemType={"manager"} // Defines which dispatch to invoke
                      ItemHeading={"Photo"}
                      ItemLabel={"manager-profile-image"}
                      FileFolder={"manager-content"}
                      ItemId={propertyManager._id} // Defines which id to insert into filename
                      ItemDescription={
                        "This is the photo displayed to your guests"
                      }
                      InputLabelA={""}
                      ValueA={propertyManager.PropertyMgrCoverImage}
                      PayloadA={"PropertyMgrCoverImage"} // Defines which database label to update
                      IsChecked={
                        propertyManager.PropertyMgrCoverImage ? true : false
                      }
                      IsRequired={false}
                      FalseText={"Not uploaded"}
                    />

                    <InfoItemOneBox
                      SaveStatus={SaveStatus}
                      managerId={propertyManager._id}
                      userId={userData._id}
                      ItemType={"manager"} // Defines which dispatch to invoke
                      ContainerClassName={"infoitem-form-inner-container-full"}
                      ItemLabel={"Email address"}
                      ItemHeading={"Email Address"}
                      ItemDescription={
                        "This is the email address that guests will use to contact you (leave blank if you don't want to show an email address)"
                      }
                      InputLabelA={"Email address"}
                      ValueA={propertyManager.PropertyMgrEmail}
                      PayloadA={"PropertyMgrEmail"}
                      IsChecked={
                        propertyManager.PropertyMgrEmail ? true : false
                      }
                      IsRequired={false}
                      FalseText={"Not entered"}
                    />

                    <InfoItemTel
                      SaveStatus={SaveStatus}
                      managerId={propertyManager._id}
                      userId={userData._id}
                      itemType={"manager"}
                      managerEmail={propertyManager.PropertyMgrEmail}
                      ContainerClassName={"infoitem-form-inner-container-left"}
                      itemLabel={"manager-phone-number"}
                      ItemHeading={"Phone Number"}
                      ItemDescription={
                        "This is the telephone number that guests will use to contact you (leave blank if you don't want to show a phone number)"
                      }
                      InputLabelA={"Phone number"}
                      ValueA={propertyManager.PropertyMgrTel}
                      // PayloadA={"PropertyMgrTel"}
                      IsChecked={propertyManager.PropertyMgrTel ? true : false}
                      IsRequired={false}
                      FalseText={"Not entered"}
                    />

                    <InfoItemTwoBox
                      SaveStatus={SaveStatus}
                      managerId={propertyManager._id}
                      ItemType={"manager"} // Defines which dispatch to invoke
                      ItemLabel={"message-link"}
                      ItemHeading={"Message Link"}
                      ItemDescription={
                        "This is note/link to let guests know which message platform to contact you on"
                      }
                      InputLabelA={'Message link name (e.g. "Airbnb App")'}
                      ValueA={propertyManager.MessageAppName}
                      PayloadA={"MessageAppName"}
                      InputLabelB={
                        "Message link Url (e.g. https://www.airbnb.com) - leave blank if not applicable"
                      }
                      ValueB={propertyManager.MessageAppLink}
                      PayloadB={"MessageAppLink"}
                      IsChecked={propertyManager.MessageAppName ? true : false}
                      IsRequired={false}
                      FalseText={"Not entered"}
                      userId={userData._id}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Popup>
      </div>
    );
  }
}
