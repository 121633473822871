import React from "react";
import NavigationHeader from "../components/NavigationHeader";
import CreateManagerModule from "../components/CreateManagerModule";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class CreateManagerPage extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { SaveStatus, userData, LinkedPropertyManagersLoaded } = this.props;

    const currentPageTitle = "Create Property Manager";

    return (
      <>
        <NavigationHeader
          {...this.props}
          previousPageTitle={"Property Managers / Hosts"}
          previousPagePath={"/property-managers"}
          currentPageTitle={currentPageTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {currentPageTitle}</div>
            <div className="infopage-subheading">
              The details of the property manager, which will be shared with
              your guests.
            </div>

            <CreateManagerModule
              {...this.props}
              SaveStatus={SaveStatus}
              ItemType={"manager"} // Defines which dispatch to invoke
              ItemLabel={"new-manager"}
              ItemHeading={"Name"}
              InputLabelA={"First name"}
              ValueA={""}
              PayloadA={"PropertyMgrFirstname"}
              InputLabelB={"Last name"}
              ValueB={""}
              PayloadB={"PropertyMgrLastname"}
              IsRequired={true}
              SetupFlow={false}
              ContainerClassName={"infoitem-form-inner-container-full"}
              userId={userData._id}
              LinkedPropertyManagersLoaded={LinkedPropertyManagersLoaded}
            />
          </div>
        </div>
      </>
    );
  }
}
