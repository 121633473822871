import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { workflowApiRoot, apiSecretToken } from "../constants.js";
import { appConfig } from "../data/appConfig";
import Loading from "../components/Loading";

const ReferralRedirectPage = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const referralId = searchParams.get("ref");
  const [fetchSuccess, setFetchSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (referralId) {
      fetch(`${workflowApiRoot}referral-link-click`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${apiSecretToken}`,
        },
        body: JSON.stringify({
          referral_id: referralId,
        }),
      })
        .then((response) => response.json())
        .then((results) => {
          if (results.status === "success") {
            setFetchSuccess(true);
          }
        });
    }
  }, [referralId]);

  useEffect(() => {
    if (fetchSuccess) {
      window.location.href = `${appConfig.siteUrl}/?ref=${referralId}&utm_medium=referral&utm_campaign=${referralId}`;
    }
  }, [fetchSuccess, referralId]);

  return <Loading />;
};

export default ReferralRedirectPage;
