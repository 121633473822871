// Guidey Subscription Options

export const subscriptionOptions = [
  {
    name: "Monthly Subscription",
    product: "Guidey™ | Monthly Subscription | Standard Pricing",
    productId: "prod_ROOVAp98f36GSK",
    type: "basic",
    unitLabel: "property",
    cycle: "monthly",
    currency: "usd",
    currencySymbol: "$",
    id: "monthly-usd",
    value: "price_1QVbidGMVnQW6tSnO714falC",
    pricing: {
      1: 8.99,
      2: 13.29,
      3: 17.29,
      4: 21.29,
      5: 25.29,
      6: 29.19,
      7: 33.09,
      8: 36.89,
      9: 40.49,
      10: 43.99,
      11: 47.39,
      12: 50.59,
      13: 53.69,
      14: 56.79,
      15: 59.79,
      16: 62.79,
      17: 65.79,
      18: 68.79,
      19: 71.79,
      20: 74.79,
      21: 77.79,
      22: 80.79,
      23: 83.79,
      24: 86.79,
      25: 89.59,
      26: 92.39,
      27: 95.19,
      28: 97.99,
      29: 100.79,
      30: 103.59,
      31: 106.39,
      32: 109.19,
      33: 111.99,
      34: 114.79,
      35: 117.29,
      36: 119.79,
      37: 122.29,
      38: 124.79,
      39: 127.29,
      40: 129.79,
      41: 132.29,
      42: 134.79,
      43: 137.29,
      44: 139.79,
      45: 142.29,
      46: 144.79,
      47: 147.29,
      48: 149.79,
      49: 152.29,
      50: 154.49,
      51: 156.69,
      52: 158.89,
      53: 161.09,
      54: 163.29,
      55: 165.49,
      56: 167.69,
      57: 169.89,
      58: 172.09,
      59: 174.29,
      60: 176.49,
      61: 178.69,
      62: 180.89,
      63: 183.09,
      64: 185.29,
      65: 187.49,
      66: 189.69,
      67: 191.89,
      68: 194.09,
      69: 196.29,
      70: 198.49,
      71: 200.69,
      72: 202.89,
      73: 205.09,
      74: 207.29,
      75: 209.29,
      76: 211.29,
      77: 213.29,
      78: 215.29,
      79: 217.29,
      80: 219.29,
      81: 221.29,
      82: 223.29,
      83: 225.29,
      84: 227.29,
      85: 229.29,
      86: 231.29,
      87: 233.29,
      88: 235.29,
      89: 237.29,
      90: 239.29,
      91: 241.29,
      92: 243.29,
      93: 245.29,
      94: 247.29,
      95: 249.29,
      96: 251.29,
      97: 253.29,
      98: 255.29,
      99: 257.29,
      100: 259.29,
      101: 261.29,
      102: 263.29,
      103: 265.29,
      104: 267.29,
      105: 269.29,
      106: 271.29,
      107: 273.29,
      108: 275.29,
      109: 277.29,
      110: 279.29,
      111: 281.29,
      112: 283.29,
      113: 285.29,
      114: 287.29,
      115: 289.29,
      116: 291.29,
      117: 293.29,
      118: 295.29,
      119: 297.29,
      120: 299.29,
      121: 301.29,
      122: 303.29,
      123: 305.29,
      124: 307.29,
      125: 309.29,
      126: 311.29,
      127: 313.29,
      128: 315.29,
      129: 317.29,
      130: 319.29,
      131: 321.29,
      132: 323.29,
      133: 325.29,
      134: 327.29,
      135: 329.29,
      136: 331.29,
      137: 333.29,
      138: 335.29,
      139: 337.29,
      140: 339.29,
      141: 341.29,
      142: 343.29,
      143: 345.29,
      144: 347.29,
      145: 349.29,
      146: 351.29,
      147: 353.29,
      148: 355.29,
      149: 357.29,
      150: 359.19,
      151: 361.09,
      152: 362.99,
      153: 364.89,
      154: 366.79,
      155: 368.69,
      156: 370.59,
      157: 372.49,
      158: 374.39,
      159: 376.29,
      160: 378.19,
      161: 380.09,
      162: 381.99,
      163: 383.89,
      164: 385.79,
      165: 387.69,
      166: 389.59,
      167: 391.49,
      168: 393.39,
      169: 395.29,
      170: 397.19,
      171: 399.09,
      172: 400.99,
      173: 402.89,
      174: 404.79,
      175: 406.59,
      176: 408.39,
      177: 410.19,
      178: 411.99,
      179: 413.79,
      180: 415.59,
      181: 417.39,
      182: 419.19,
      183: 420.99,
      184: 422.79,
      185: 424.59,
      186: 426.39,
      187: 428.19,
      188: 429.99,
      189: 431.79,
      190: 433.59,
      191: 435.39,
      192: 437.19,
      193: 438.99,
      194: 440.79,
      195: 442.59,
      196: 444.39,
      197: 446.19,
      198: 447.99,
      199: 449.79,
      200: 451.59,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      1: 8.99,
      2: 13.29,
      3: 17.29,
      4: 21.29,
      5: 25.29,
      6: 29.19,
      7: 33.09,
      8: 36.89,
      9: 40.49,
      10: 43.99,
      11: 47.39,
      12: 50.59,
      13: 53.69,
      14: 56.79,
      15: 59.79,
      16: 62.79,
      17: 65.79,
      18: 68.79,
      19: 71.79,
      20: 74.79,
      21: 77.79,
      22: 80.79,
      23: 83.79,
      24: 86.79,
      25: 89.59,
      26: 92.39,
      27: 95.19,
      28: 97.99,
      29: 100.79,
      30: 103.59,
      31: 106.39,
      32: 109.19,
      33: 111.99,
      34: 114.79,
      35: 117.29,
      36: 119.79,
      37: 122.29,
      38: 124.79,
      39: 127.29,
      40: 129.79,
      41: 132.29,
      42: 134.79,
      43: 137.29,
      44: 139.79,
      45: 142.29,
      46: 144.79,
      47: 147.29,
      48: 149.79,
      49: 152.29,
      50: 154.49,
      51: 156.69,
      52: 158.89,
      53: 161.09,
      54: 163.29,
      55: 165.49,
      56: 167.69,
      57: 169.89,
      58: 172.09,
      59: 174.29,
      60: 176.49,
      61: 178.69,
      62: 180.89,
      63: 183.09,
      64: 185.29,
      65: 187.49,
      66: 189.69,
      67: 191.89,
      68: 194.09,
      69: 196.29,
      70: 198.49,
      71: 200.69,
      72: 202.89,
      73: 205.09,
      74: 207.29,
      75: 209.29,
      76: 211.29,
      77: 213.29,
      78: 215.29,
      79: 217.29,
      80: 219.29,
      81: 221.29,
      82: 223.29,
      83: 225.29,
      84: 227.29,
      85: 229.29,
      86: 231.29,
      87: 233.29,
      88: 235.29,
      89: 237.29,
      90: 239.29,
      91: 241.29,
      92: 243.29,
      93: 245.29,
      94: 247.29,
      95: 249.29,
      96: 251.29,
      97: 253.29,
      98: 255.29,
      99: 257.29,
      100: 259.29,
      101: 261.29,
      102: 263.29,
      103: 265.29,
      104: 267.29,
      105: 269.29,
      106: 271.29,
      107: 273.29,
      108: 275.29,
      109: 277.29,
      110: 279.29,
      111: 281.29,
      112: 283.29,
      113: 285.29,
      114: 287.29,
      115: 289.29,
      116: 291.29,
      117: 293.29,
      118: 295.29,
      119: 297.29,
      120: 299.29,
      121: 301.29,
      122: 303.29,
      123: 305.29,
      124: 307.29,
      125: 309.29,
      126: 311.29,
      127: 313.29,
      128: 315.29,
      129: 317.29,
      130: 319.29,
      131: 321.29,
      132: 323.29,
      133: 325.29,
      134: 327.29,
      135: 329.29,
      136: 331.29,
      137: 333.29,
      138: 335.29,
      139: 337.29,
      140: 339.29,
      141: 341.29,
      142: 343.29,
      143: 345.29,
      144: 347.29,
      145: 349.29,
      146: 351.29,
      147: 353.29,
      148: 355.29,
      149: 357.29,
      150: 359.19,
      151: 361.09,
      152: 362.99,
      153: 364.89,
      154: 366.79,
      155: 368.69,
      156: 370.59,
      157: 372.49,
      158: 374.39,
      159: 376.29,
      160: 378.19,
      161: 380.09,
      162: 381.99,
      163: 383.89,
      164: 385.79,
      165: 387.69,
      166: 389.59,
      167: 391.49,
      168: 393.39,
      169: 395.29,
      170: 397.19,
      171: 399.09,
      172: 400.99,
      173: 402.89,
      174: 404.79,
      175: 406.59,
      176: 408.39,
      177: 410.19,
      178: 411.99,
      179: 413.79,
      180: 415.59,
      181: 417.39,
      182: 419.19,
      183: 420.99,
      184: 422.79,
      185: 424.59,
      186: 426.39,
      187: 428.19,
      188: 429.99,
      189: 431.79,
      190: 433.59,
      191: 435.39,
      192: 437.19,
      193: 438.99,
      194: 440.79,
      195: 442.59,
      196: 444.39,
      197: 446.19,
      198: 447.99,
      199: 449.79,
      200: 451.59,
    },
  },
  {
    name: "Annual Subscription",
    product: "Guidey™ | Annual Subscription | Standard Pricing",
    productId: "prod_QeCsu6XwfQ401k",
    type: "basic",
    unitLabel: "property",
    cycle: "annual",
    currency: "usd",
    currencySymbol: "$",
    id: "annual-usd",
    value: "price_1Pmuf9GMVnQW6tSnR8FEfaWp",
    pricing: {
      1: 89,
      2: 128,
      3: 166,
      4: 204,
      5: 242,
      6: 279,
      7: 316,
      8: 352,
      9: 387,
      10: 421,
      11: 453,
      12: 483,
      13: 513,
      14: 542,
      15: 571,
      16: 600,
      17: 629,
      18: 658,
      19: 687,
      20: 716,
      21: 745,
      22: 774,
      23: 802,
      24: 830,
      25: 858,
      26: 886,
      27: 913,
      28: 940,
      29: 967,
      30: 994,
      31: 1021,
      32: 1048,
      33: 1075,
      34: 1102,
      35: 1128,
      36: 1154,
      37: 1179,
      38: 1204,
      39: 1229,
      40: 1252,
      41: 1275,
      42: 1298,
      43: 1321,
      44: 1344,
      45: 1367,
      46: 1390,
      47: 1413,
      48: 1436,
      49: 1459,
      50: 1480,
      51: 1501,
      52: 1522,
      53: 1543,
      54: 1564,
      55: 1585,
      56: 1606,
      57: 1627,
      58: 1648,
      59: 1669,
      60: 1690,
      61: 1711,
      62: 1732,
      63: 1753,
      64: 1774,
      65: 1795,
      66: 1816,
      67: 1837,
      68: 1858,
      69: 1879,
      70: 1900,
      71: 1921,
      72: 1942,
      73: 1963,
      74: 1984,
      75: 2004,
      76: 2024,
      77: 2044,
      78: 2064,
      79: 2084,
      80: 2104,
      81: 2124,
      82: 2144,
      83: 2164,
      84: 2184,
      85: 2204,
      86: 2224,
      87: 2244,
      88: 2264,
      89: 2284,
      90: 2304,
      91: 2324,
      92: 2344,
      93: 2364,
      94: 2384,
      95: 2404,
      96: 2424,
      97: 2444,
      98: 2464,
      99: 2484,
      100: 2503,
      101: 2522,
      102: 2541,
      103: 2560,
      104: 2579,
      105: 2598,
      106: 2617,
      107: 2636,
      108: 2655,
      109: 2674,
      110: 2693,
      111: 2712,
      112: 2731,
      113: 2750,
      114: 2769,
      115: 2788,
      116: 2807,
      117: 2826,
      118: 2845,
      119: 2864,
      120: 2883,
      121: 2902,
      122: 2921,
      123: 2940,
      124: 2959,
      125: 2978,
      126: 2997,
      127: 3016,
      128: 3035,
      129: 3054,
      130: 3073,
      131: 3092,
      132: 3111,
      133: 3130,
      134: 3149,
      135: 3168,
      136: 3187,
      137: 3206,
      138: 3225,
      139: 3244,
      140: 3263,
      141: 3282,
      142: 3301,
      143: 3320,
      144: 3339,
      145: 3358,
      146: 3377,
      147: 3396,
      148: 3415,
      149: 3434,
      150: 3452,
      151: 3470,
      152: 3488,
      153: 3506,
      154: 3524,
      155: 3542,
      156: 3560,
      157: 3578,
      158: 3596,
      159: 3614,
      160: 3632,
      161: 3650,
      162: 3668,
      163: 3686,
      164: 3704,
      165: 3722,
      166: 3740,
      167: 3758,
      168: 3776,
      169: 3794,
      170: 3812,
      171: 3830,
      172: 3848,
      173: 3866,
      174: 3884,
      175: 3901,
      176: 3918,
      177: 3935,
      178: 3952,
      179: 3969,
      180: 3986,
      181: 4003,
      182: 4020,
      183: 4037,
      184: 4054,
      185: 4071,
      186: 4088,
      187: 4105,
      188: 4122,
      189: 4139,
      190: 4156,
      191: 4173,
      192: 4190,
      193: 4207,
      194: 4224,
      195: 4241,
      196: 4258,
      197: 4275,
      198: 4292,
      199: 4309,
      200: 4326,
    },
    specialOffer: "",
    saveMessage: "SAVE 20%",
    renewalPricing: {
      1: 89,
      2: 128,
      3: 166,
      4: 204,
      5: 242,
      6: 279,
      7: 316,
      8: 352,
      9: 387,
      10: 421,
      11: 453,
      12: 483,
      13: 513,
      14: 542,
      15: 571,
      16: 600,
      17: 629,
      18: 658,
      19: 687,
      20: 716,
      21: 745,
      22: 774,
      23: 802,
      24: 830,
      25: 858,
      26: 886,
      27: 913,
      28: 940,
      29: 967,
      30: 994,
      31: 1021,
      32: 1048,
      33: 1075,
      34: 1102,
      35: 1128,
      36: 1154,
      37: 1179,
      38: 1204,
      39: 1229,
      40: 1252,
      41: 1275,
      42: 1298,
      43: 1321,
      44: 1344,
      45: 1367,
      46: 1390,
      47: 1413,
      48: 1436,
      49: 1459,
      50: 1480,
      51: 1501,
      52: 1522,
      53: 1543,
      54: 1564,
      55: 1585,
      56: 1606,
      57: 1627,
      58: 1648,
      59: 1669,
      60: 1690,
      61: 1711,
      62: 1732,
      63: 1753,
      64: 1774,
      65: 1795,
      66: 1816,
      67: 1837,
      68: 1858,
      69: 1879,
      70: 1900,
      71: 1921,
      72: 1942,
      73: 1963,
      74: 1984,
      75: 2004,
      76: 2024,
      77: 2044,
      78: 2064,
      79: 2084,
      80: 2104,
      81: 2124,
      82: 2144,
      83: 2164,
      84: 2184,
      85: 2204,
      86: 2224,
      87: 2244,
      88: 2264,
      89: 2284,
      90: 2304,
      91: 2324,
      92: 2344,
      93: 2364,
      94: 2384,
      95: 2404,
      96: 2424,
      97: 2444,
      98: 2464,
      99: 2484,
      100: 2503,
      101: 2522,
      102: 2541,
      103: 2560,
      104: 2579,
      105: 2598,
      106: 2617,
      107: 2636,
      108: 2655,
      109: 2674,
      110: 2693,
      111: 2712,
      112: 2731,
      113: 2750,
      114: 2769,
      115: 2788,
      116: 2807,
      117: 2826,
      118: 2845,
      119: 2864,
      120: 2883,
      121: 2902,
      122: 2921,
      123: 2940,
      124: 2959,
      125: 2978,
      126: 2997,
      127: 3016,
      128: 3035,
      129: 3054,
      130: 3073,
      131: 3092,
      132: 3111,
      133: 3130,
      134: 3149,
      135: 3168,
      136: 3187,
      137: 3206,
      138: 3225,
      139: 3244,
      140: 3263,
      141: 3282,
      142: 3301,
      143: 3320,
      144: 3339,
      145: 3358,
      146: 3377,
      147: 3396,
      148: 3415,
      149: 3434,
      150: 3452,
      151: 3470,
      152: 3488,
      153: 3506,
      154: 3524,
      155: 3542,
      156: 3560,
      157: 3578,
      158: 3596,
      159: 3614,
      160: 3632,
      161: 3650,
      162: 3668,
      163: 3686,
      164: 3704,
      165: 3722,
      166: 3740,
      167: 3758,
      168: 3776,
      169: 3794,
      170: 3812,
      171: 3830,
      172: 3848,
      173: 3866,
      174: 3884,
      175: 3901,
      176: 3918,
      177: 3935,
      178: 3952,
      179: 3969,
      180: 3986,
      181: 4003,
      182: 4020,
      183: 4037,
      184: 4054,
      185: 4071,
      186: 4088,
      187: 4105,
      188: 4122,
      189: 4139,
      190: 4156,
      191: 4173,
      192: 4190,
      193: 4207,
      194: 4224,
      195: 4241,
      196: 4258,
      197: 4275,
      198: 4292,
      199: 4309,
      200: 4326,
    },
  },
  {
    name: "Monthly Subscription",
    product: "Guidey™ | Monthly Subscription | Standard Pricing",
    productId: "prod_ROOVAp98f36GSK",
    type: "basic",
    unitLabel: "property",
    cycle: "monthly",
    currency: "gbp",
    currencySymbol: "£",
    id: "monthly-gbp",
    value: "price_1QVbs7GMVnQW6tSn2TqP5PoW",
    pricing: {
      1: 6.99,
      2: 10.19,
      3: 13.19,
      4: 16.19,
      5: 19.19,
      6: 22.19,
      7: 25.19,
      8: 27.99,
      9: 30.79,
      10: 33.59,
      11: 36.39,
      12: 38.79,
      13: 41.19,
      14: 43.59,
      15: 45.99,
      16: 48.39,
      17: 50.69,
      18: 52.99,
      19: 55.29,
      20: 57.59,
      21: 59.89,
      22: 62.19,
      23: 64.49,
      24: 66.79,
      25: 68.99,
      26: 71.19,
      27: 73.39,
      28: 75.59,
      29: 77.79,
      30: 79.89,
      31: 81.99,
      32: 84.09,
      33: 86.19,
      34: 88.29,
      35: 90.29,
      36: 92.29,
      37: 94.29,
      38: 96.29,
      39: 98.29,
      40: 100.19,
      41: 102.09,
      42: 103.99,
      43: 105.89,
      44: 107.79,
      45: 109.69,
      46: 111.59,
      47: 113.49,
      48: 115.39,
      49: 117.29,
      50: 118.99,
      51: 120.69,
      52: 122.39,
      53: 124.09,
      54: 125.79,
      55: 127.49,
      56: 129.19,
      57: 130.89,
      58: 132.59,
      59: 134.29,
      60: 135.99,
      61: 137.69,
      62: 139.39,
      63: 141.09,
      64: 142.79,
      65: 144.49,
      66: 146.19,
      67: 147.89,
      68: 149.59,
      69: 151.29,
      70: 152.99,
      71: 154.69,
      72: 156.39,
      73: 158.09,
      74: 159.79,
      75: 161.39,
      76: 162.99,
      77: 164.59,
      78: 166.19,
      79: 167.79,
      80: 169.39,
      81: 170.99,
      82: 172.59,
      83: 174.19,
      84: 175.79,
      85: 177.39,
      86: 178.99,
      87: 180.59,
      88: 182.19,
      89: 183.79,
      90: 185.39,
      91: 186.99,
      92: 188.59,
      93: 190.19,
      94: 191.79,
      95: 193.39,
      96: 194.99,
      97: 196.59,
      98: 198.19,
      99: 199.79,
      100: 201.29,
      101: 202.79,
      102: 204.29,
      103: 205.79,
      104: 207.29,
      105: 208.79,
      106: 210.29,
      107: 211.79,
      108: 213.29,
      109: 214.79,
      110: 216.29,
      111: 217.79,
      112: 219.29,
      113: 220.79,
      114: 222.29,
      115: 223.79,
      116: 225.29,
      117: 226.79,
      118: 228.29,
      119: 229.79,
      120: 231.29,
      121: 232.79,
      122: 234.29,
      123: 235.79,
      124: 237.29,
      125: 238.79,
      126: 240.29,
      127: 241.79,
      128: 243.29,
      129: 244.79,
      130: 246.29,
      131: 247.79,
      132: 249.29,
      133: 250.79,
      134: 252.29,
      135: 253.79,
      136: 255.29,
      137: 256.79,
      138: 258.29,
      139: 259.79,
      140: 261.29,
      141: 262.79,
      142: 264.29,
      143: 265.79,
      144: 267.29,
      145: 268.79,
      146: 270.29,
      147: 271.79,
      148: 273.29,
      149: 274.79,
      150: 276.19,
      151: 277.59,
      152: 278.99,
      153: 280.39,
      154: 281.79,
      155: 283.19,
      156: 284.59,
      157: 285.99,
      158: 287.39,
      159: 288.79,
      160: 290.19,
      161: 291.59,
      162: 292.99,
      163: 294.39,
      164: 295.79,
      165: 297.19,
      166: 298.59,
      167: 299.99,
      168: 301.39,
      169: 302.79,
      170: 304.19,
      171: 305.59,
      172: 306.99,
      173: 308.39,
      174: 309.79,
      175: 311.09,
      176: 312.39,
      177: 313.69,
      178: 314.99,
      179: 316.29,
      180: 317.59,
      181: 318.89,
      182: 320.19,
      183: 321.49,
      184: 322.79,
      185: 324.09,
      186: 325.39,
      187: 326.69,
      188: 327.99,
      189: 329.29,
      190: 330.59,
      191: 331.89,
      192: 333.19,
      193: 334.49,
      194: 335.79,
      195: 337.09,
      196: 338.39,
      197: 339.69,
      198: 340.99,
      199: 342.29,
      200: 343.59,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      1: 6.99,
      2: 10.19,
      3: 13.19,
      4: 16.19,
      5: 19.19,
      6: 22.19,
      7: 25.19,
      8: 27.99,
      9: 30.79,
      10: 33.59,
      11: 36.39,
      12: 38.79,
      13: 41.19,
      14: 43.59,
      15: 45.99,
      16: 48.39,
      17: 50.69,
      18: 52.99,
      19: 55.29,
      20: 57.59,
      21: 59.89,
      22: 62.19,
      23: 64.49,
      24: 66.79,
      25: 68.99,
      26: 71.19,
      27: 73.39,
      28: 75.59,
      29: 77.79,
      30: 79.89,
      31: 81.99,
      32: 84.09,
      33: 86.19,
      34: 88.29,
      35: 90.29,
      36: 92.29,
      37: 94.29,
      38: 96.29,
      39: 98.29,
      40: 100.19,
      41: 102.09,
      42: 103.99,
      43: 105.89,
      44: 107.79,
      45: 109.69,
      46: 111.59,
      47: 113.49,
      48: 115.39,
      49: 117.29,
      50: 118.99,
      51: 120.69,
      52: 122.39,
      53: 124.09,
      54: 125.79,
      55: 127.49,
      56: 129.19,
      57: 130.89,
      58: 132.59,
      59: 134.29,
      60: 135.99,
      61: 137.69,
      62: 139.39,
      63: 141.09,
      64: 142.79,
      65: 144.49,
      66: 146.19,
      67: 147.89,
      68: 149.59,
      69: 151.29,
      70: 152.99,
      71: 154.69,
      72: 156.39,
      73: 158.09,
      74: 159.79,
      75: 161.39,
      76: 162.99,
      77: 164.59,
      78: 166.19,
      79: 167.79,
      80: 169.39,
      81: 170.99,
      82: 172.59,
      83: 174.19,
      84: 175.79,
      85: 177.39,
      86: 178.99,
      87: 180.59,
      88: 182.19,
      89: 183.79,
      90: 185.39,
      91: 186.99,
      92: 188.59,
      93: 190.19,
      94: 191.79,
      95: 193.39,
      96: 194.99,
      97: 196.59,
      98: 198.19,
      99: 199.79,
      100: 201.29,
      101: 202.79,
      102: 204.29,
      103: 205.79,
      104: 207.29,
      105: 208.79,
      106: 210.29,
      107: 211.79,
      108: 213.29,
      109: 214.79,
      110: 216.29,
      111: 217.79,
      112: 219.29,
      113: 220.79,
      114: 222.29,
      115: 223.79,
      116: 225.29,
      117: 226.79,
      118: 228.29,
      119: 229.79,
      120: 231.29,
      121: 232.79,
      122: 234.29,
      123: 235.79,
      124: 237.29,
      125: 238.79,
      126: 240.29,
      127: 241.79,
      128: 243.29,
      129: 244.79,
      130: 246.29,
      131: 247.79,
      132: 249.29,
      133: 250.79,
      134: 252.29,
      135: 253.79,
      136: 255.29,
      137: 256.79,
      138: 258.29,
      139: 259.79,
      140: 261.29,
      141: 262.79,
      142: 264.29,
      143: 265.79,
      144: 267.29,
      145: 268.79,
      146: 270.29,
      147: 271.79,
      148: 273.29,
      149: 274.79,
      150: 276.19,
      151: 277.59,
      152: 278.99,
      153: 280.39,
      154: 281.79,
      155: 283.19,
      156: 284.59,
      157: 285.99,
      158: 287.39,
      159: 288.79,
      160: 290.19,
      161: 291.59,
      162: 292.99,
      163: 294.39,
      164: 295.79,
      165: 297.19,
      166: 298.59,
      167: 299.99,
      168: 301.39,
      169: 302.79,
      170: 304.19,
      171: 305.59,
      172: 306.99,
      173: 308.39,
      174: 309.79,
      175: 311.09,
      176: 312.39,
      177: 313.69,
      178: 314.99,
      179: 316.29,
      180: 317.59,
      181: 318.89,
      182: 320.19,
      183: 321.49,
      184: 322.79,
      185: 324.09,
      186: 325.39,
      187: 326.69,
      188: 327.99,
      189: 329.29,
      190: 330.59,
      191: 331.89,
      192: 333.19,
      193: 334.49,
      194: 335.79,
      195: 337.09,
      196: 338.39,
      197: 339.69,
      198: 340.99,
      199: 342.29,
      200: 343.59,
    },
  },
  {
    name: "Annual Subscription",
    product: "Guidey™ | Annual Subscription | Standard Pricing",
    productId: "prod_QeCsu6XwfQ401k",
    type: "basic",
    unitLabel: "property",
    cycle: "annual",
    currency: "gbp",
    currencySymbol: "£",
    id: "annual-gbp",
    value: "price_1PmuSYGMVnQW6tSnH62XbSj2",
    pricing: {
      1: 69,
      2: 98,
      3: 127,
      4: 156,
      5: 185,
      6: 214,
      7: 242,
      8: 269,
      9: 296,
      10: 322,
      11: 348,
      12: 371,
      13: 394,
      14: 417,
      15: 440,
      16: 463,
      17: 485,
      18: 507,
      19: 529,
      20: 551,
      21: 573,
      22: 595,
      23: 617,
      24: 639,
      25: 661,
      26: 682,
      27: 703,
      28: 724,
      29: 745,
      30: 765,
      31: 785,
      32: 805,
      33: 825,
      34: 845,
      35: 865,
      36: 885,
      37: 905,
      38: 925,
      39: 945,
      40: 963,
      41: 981,
      42: 999,
      43: 1017,
      44: 1035,
      45: 1053,
      46: 1071,
      47: 1089,
      48: 1107,
      49: 1125,
      50: 1141,
      51: 1157,
      52: 1173,
      53: 1189,
      54: 1205,
      55: 1221,
      56: 1237,
      57: 1253,
      58: 1269,
      59: 1285,
      60: 1301,
      61: 1317,
      62: 1333,
      63: 1349,
      64: 1365,
      65: 1381,
      66: 1397,
      67: 1413,
      68: 1429,
      69: 1445,
      70: 1461,
      71: 1477,
      72: 1493,
      73: 1509,
      74: 1525,
      75: 1541,
      76: 1557,
      77: 1573,
      78: 1589,
      79: 1605,
      80: 1621,
      81: 1637,
      82: 1653,
      83: 1669,
      84: 1685,
      85: 1700,
      86: 1715,
      87: 1730,
      88: 1745,
      89: 1760,
      90: 1775,
      91: 1790,
      92: 1805,
      93: 1820,
      94: 1835,
      95: 1850,
      96: 1865,
      97: 1880,
      98: 1895,
      99: 1910,
      100: 1925,
      101: 1940,
      102: 1955,
      103: 1970,
      104: 1985,
      105: 2000,
      106: 2015,
      107: 2030,
      108: 2045,
      109: 2060,
      110: 2075,
      111: 2090,
      112: 2105,
      113: 2120,
      114: 2135,
      115: 2149,
      116: 2163,
      117: 2177,
      118: 2191,
      119: 2205,
      120: 2219,
      121: 2233,
      122: 2247,
      123: 2261,
      124: 2275,
      125: 2289,
      126: 2303,
      127: 2317,
      128: 2331,
      129: 2345,
      130: 2359,
      131: 2373,
      132: 2387,
      133: 2401,
      134: 2415,
      135: 2429,
      136: 2443,
      137: 2457,
      138: 2471,
      139: 2485,
      140: 2499,
      141: 2513,
      142: 2527,
      143: 2541,
      144: 2555,
      145: 2569,
      146: 2583,
      147: 2597,
      148: 2611,
      149: 2625,
      150: 2639,
      151: 2653,
      152: 2667,
      153: 2681,
      154: 2695,
      155: 2709,
      156: 2723,
      157: 2737,
      158: 2751,
      159: 2765,
      160: 2779,
      161: 2793,
      162: 2807,
      163: 2821,
      164: 2835,
      165: 2849,
      166: 2863,
      167: 2877,
      168: 2891,
      169: 2905,
      170: 2919,
      171: 2933,
      172: 2947,
      173: 2961,
      174: 2975,
      175: 2988,
      176: 3001,
      177: 3014,
      178: 3027,
      179: 3040,
      180: 3053,
      181: 3066,
      182: 3079,
      183: 3092,
      184: 3105,
      185: 3118,
      186: 3131,
      187: 3144,
      188: 3157,
      189: 3170,
      190: 3183,
      191: 3196,
      192: 3209,
      193: 3222,
      194: 3235,
      195: 3248,
      196: 3261,
      197: 3274,
      198: 3287,
      199: 3300,
      200: 3313,
    },
    specialOffer: "",
    saveMessage: "SAVE 20%",
    renewalPricing: {
      1: 69,
      2: 98,
      3: 127,
      4: 156,
      5: 185,
      6: 214,
      7: 242,
      8: 269,
      9: 296,
      10: 322,
      11: 348,
      12: 371,
      13: 394,
      14: 417,
      15: 440,
      16: 463,
      17: 485,
      18: 507,
      19: 529,
      20: 551,
      21: 573,
      22: 595,
      23: 617,
      24: 639,
      25: 661,
      26: 682,
      27: 703,
      28: 724,
      29: 745,
      30: 765,
      31: 785,
      32: 805,
      33: 825,
      34: 845,
      35: 865,
      36: 885,
      37: 905,
      38: 925,
      39: 945,
      40: 963,
      41: 981,
      42: 999,
      43: 1017,
      44: 1035,
      45: 1053,
      46: 1071,
      47: 1089,
      48: 1107,
      49: 1125,
      50: 1141,
      51: 1157,
      52: 1173,
      53: 1189,
      54: 1205,
      55: 1221,
      56: 1237,
      57: 1253,
      58: 1269,
      59: 1285,
      60: 1301,
      61: 1317,
      62: 1333,
      63: 1349,
      64: 1365,
      65: 1381,
      66: 1397,
      67: 1413,
      68: 1429,
      69: 1445,
      70: 1461,
      71: 1477,
      72: 1493,
      73: 1509,
      74: 1525,
      75: 1541,
      76: 1557,
      77: 1573,
      78: 1589,
      79: 1605,
      80: 1621,
      81: 1637,
      82: 1653,
      83: 1669,
      84: 1685,
      85: 1700,
      86: 1715,
      87: 1730,
      88: 1745,
      89: 1760,
      90: 1775,
      91: 1790,
      92: 1805,
      93: 1820,
      94: 1835,
      95: 1850,
      96: 1865,
      97: 1880,
      98: 1895,
      99: 1910,
      100: 1925,
      101: 1940,
      102: 1955,
      103: 1970,
      104: 1985,
      105: 2000,
      106: 2015,
      107: 2030,
      108: 2045,
      109: 2060,
      110: 2075,
      111: 2090,
      112: 2105,
      113: 2120,
      114: 2135,
      115: 2149,
      116: 2163,
      117: 2177,
      118: 2191,
      119: 2205,
      120: 2219,
      121: 2233,
      122: 2247,
      123: 2261,
      124: 2275,
      125: 2289,
      126: 2303,
      127: 2317,
      128: 2331,
      129: 2345,
      130: 2359,
      131: 2373,
      132: 2387,
      133: 2401,
      134: 2415,
      135: 2429,
      136: 2443,
      137: 2457,
      138: 2471,
      139: 2485,
      140: 2499,
      141: 2513,
      142: 2527,
      143: 2541,
      144: 2555,
      145: 2569,
      146: 2583,
      147: 2597,
      148: 2611,
      149: 2625,
      150: 2639,
      151: 2653,
      152: 2667,
      153: 2681,
      154: 2695,
      155: 2709,
      156: 2723,
      157: 2737,
      158: 2751,
      159: 2765,
      160: 2779,
      161: 2793,
      162: 2807,
      163: 2821,
      164: 2835,
      165: 2849,
      166: 2863,
      167: 2877,
      168: 2891,
      169: 2905,
      170: 2919,
      171: 2933,
      172: 2947,
      173: 2961,
      174: 2975,
      175: 2988,
      176: 3001,
      177: 3014,
      178: 3027,
      179: 3040,
      180: 3053,
      181: 3066,
      182: 3079,
      183: 3092,
      184: 3105,
      185: 3118,
      186: 3131,
      187: 3144,
      188: 3157,
      189: 3170,
      190: 3183,
      191: 3196,
      192: 3209,
      193: 3222,
      194: 3235,
      195: 3248,
      196: 3261,
      197: 3274,
      198: 3287,
      199: 3300,
      200: 3313,
    },
  },
  {
    name: "Monthly Subscription",
    product: "Guidey™ | Monthly Subscription | Standard Pricing",
    productId: "prod_ROOVAp98f36GSK",
    type: "basic",
    unitLabel: "property",
    cycle: "monthly",
    currency: "eur",
    currencySymbol: "€",
    id: "monthly-eur",
    value: "price_1QVc0hGMVnQW6tSnAjC3juSx",
    pricing: {
      1: 8.89,
      2: 12.69,
      3: 16.49,
      4: 20.29,
      5: 24.09,
      6: 27.69,
      7: 31.29,
      8: 34.79,
      9: 38.29,
      10: 41.69,
      11: 44.99,
      12: 47.99,
      13: 50.99,
      14: 53.99,
      15: 56.89,
      16: 59.79,
      17: 62.69,
      18: 65.59,
      19: 68.49,
      20: 71.39,
      21: 74.29,
      22: 77.19,
      23: 80.09,
      24: 82.99,
      25: 85.69,
      26: 88.39,
      27: 91.09,
      28: 93.79,
      29: 96.49,
      30: 99.19,
      31: 101.89,
      32: 104.59,
      33: 107.29,
      34: 109.99,
      35: 112.59,
      36: 115.19,
      37: 117.79,
      38: 120.39,
      39: 122.99,
      40: 125.29,
      41: 127.59,
      42: 129.89,
      43: 132.19,
      44: 134.49,
      45: 136.79,
      46: 139.09,
      47: 141.39,
      48: 143.69,
      49: 145.99,
      50: 148.09,
      51: 150.19,
      52: 152.29,
      53: 154.39,
      54: 156.49,
      55: 158.59,
      56: 160.69,
      57: 162.79,
      58: 164.89,
      59: 166.99,
      60: 169.09,
      61: 171.19,
      62: 173.29,
      63: 175.39,
      64: 177.49,
      65: 179.59,
      66: 181.69,
      67: 183.79,
      68: 185.89,
      69: 187.99,
      70: 190.09,
      71: 192.19,
      72: 194.29,
      73: 196.39,
      74: 198.49,
      75: 200.49,
      76: 202.49,
      77: 204.49,
      78: 206.49,
      79: 208.49,
      80: 210.49,
      81: 212.49,
      82: 214.49,
      83: 216.49,
      84: 218.49,
      85: 220.49,
      86: 222.49,
      87: 224.49,
      88: 226.49,
      89: 228.49,
      90: 230.49,
      91: 232.49,
      92: 234.49,
      93: 236.49,
      94: 238.49,
      95: 240.49,
      96: 242.49,
      97: 244.49,
      98: 246.49,
      99: 248.49,
      100: 250.39,
      101: 252.29,
      102: 254.19,
      103: 256.09,
      104: 257.99,
      105: 259.89,
      106: 261.79,
      107: 263.69,
      108: 265.59,
      109: 267.49,
      110: 269.39,
      111: 271.29,
      112: 273.19,
      113: 275.09,
      114: 276.99,
      115: 278.89,
      116: 280.79,
      117: 282.69,
      118: 284.59,
      119: 286.49,
      120: 288.39,
      121: 290.29,
      122: 292.19,
      123: 294.09,
      124: 295.99,
      125: 297.89,
      126: 299.79,
      127: 301.69,
      128: 303.59,
      129: 305.49,
      130: 307.39,
      131: 309.29,
      132: 311.19,
      133: 313.09,
      134: 314.99,
      135: 316.89,
      136: 318.79,
      137: 320.69,
      138: 322.59,
      139: 324.49,
      140: 326.39,
      141: 328.29,
      142: 330.19,
      143: 332.09,
      144: 333.99,
      145: 335.89,
      146: 337.79,
      147: 339.69,
      148: 341.59,
      149: 343.49,
      150: 345.29,
      151: 347.09,
      152: 348.89,
      153: 350.69,
      154: 352.49,
      155: 354.29,
      156: 356.09,
      157: 357.89,
      158: 359.69,
      159: 361.49,
      160: 363.29,
      161: 365.09,
      162: 366.89,
      163: 368.69,
      164: 370.49,
      165: 372.29,
      166: 374.09,
      167: 375.89,
      168: 377.69,
      169: 379.49,
      170: 381.29,
      171: 383.09,
      172: 384.89,
      173: 386.69,
      174: 388.49,
      175: 390.19,
      176: 391.89,
      177: 393.59,
      178: 395.29,
      179: 396.99,
      180: 398.69,
      181: 400.39,
      182: 402.09,
      183: 403.79,
      184: 405.49,
      185: 407.19,
      186: 408.89,
      187: 410.59,
      188: 412.29,
      189: 413.99,
      190: 415.69,
      191: 417.39,
      192: 419.09,
      193: 420.79,
      194: 422.49,
      195: 424.19,
      196: 425.89,
      197: 427.59,
      198: 429.29,
      199: 430.99,
      200: 432.69,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      1: 8.89,
      2: 12.69,
      3: 16.49,
      4: 20.29,
      5: 24.09,
      6: 27.69,
      7: 31.29,
      8: 34.79,
      9: 38.29,
      10: 41.69,
      11: 44.99,
      12: 47.99,
      13: 50.99,
      14: 53.99,
      15: 56.89,
      16: 59.79,
      17: 62.69,
      18: 65.59,
      19: 68.49,
      20: 71.39,
      21: 74.29,
      22: 77.19,
      23: 80.09,
      24: 82.99,
      25: 85.69,
      26: 88.39,
      27: 91.09,
      28: 93.79,
      29: 96.49,
      30: 99.19,
      31: 101.89,
      32: 104.59,
      33: 107.29,
      34: 109.99,
      35: 112.59,
      36: 115.19,
      37: 117.79,
      38: 120.39,
      39: 122.99,
      40: 125.29,
      41: 127.59,
      42: 129.89,
      43: 132.19,
      44: 134.49,
      45: 136.79,
      46: 139.09,
      47: 141.39,
      48: 143.69,
      49: 145.99,
      50: 148.09,
      51: 150.19,
      52: 152.29,
      53: 154.39,
      54: 156.49,
      55: 158.59,
      56: 160.69,
      57: 162.79,
      58: 164.89,
      59: 166.99,
      60: 169.09,
      61: 171.19,
      62: 173.29,
      63: 175.39,
      64: 177.49,
      65: 179.59,
      66: 181.69,
      67: 183.79,
      68: 185.89,
      69: 187.99,
      70: 190.09,
      71: 192.19,
      72: 194.29,
      73: 196.39,
      74: 198.49,
      75: 200.49,
      76: 202.49,
      77: 204.49,
      78: 206.49,
      79: 208.49,
      80: 210.49,
      81: 212.49,
      82: 214.49,
      83: 216.49,
      84: 218.49,
      85: 220.49,
      86: 222.49,
      87: 224.49,
      88: 226.49,
      89: 228.49,
      90: 230.49,
      91: 232.49,
      92: 234.49,
      93: 236.49,
      94: 238.49,
      95: 240.49,
      96: 242.49,
      97: 244.49,
      98: 246.49,
      99: 248.49,
      100: 250.39,
      101: 252.29,
      102: 254.19,
      103: 256.09,
      104: 257.99,
      105: 259.89,
      106: 261.79,
      107: 263.69,
      108: 265.59,
      109: 267.49,
      110: 269.39,
      111: 271.29,
      112: 273.19,
      113: 275.09,
      114: 276.99,
      115: 278.89,
      116: 280.79,
      117: 282.69,
      118: 284.59,
      119: 286.49,
      120: 288.39,
      121: 290.29,
      122: 292.19,
      123: 294.09,
      124: 295.99,
      125: 297.89,
      126: 299.79,
      127: 301.69,
      128: 303.59,
      129: 305.49,
      130: 307.39,
      131: 309.29,
      132: 311.19,
      133: 313.09,
      134: 314.99,
      135: 316.89,
      136: 318.79,
      137: 320.69,
      138: 322.59,
      139: 324.49,
      140: 326.39,
      141: 328.29,
      142: 330.19,
      143: 332.09,
      144: 333.99,
      145: 335.89,
      146: 337.79,
      147: 339.69,
      148: 341.59,
      149: 343.49,
      150: 345.29,
      151: 347.09,
      152: 348.89,
      153: 350.69,
      154: 352.49,
      155: 354.29,
      156: 356.09,
      157: 357.89,
      158: 359.69,
      159: 361.49,
      160: 363.29,
      161: 365.09,
      162: 366.89,
      163: 368.69,
      164: 370.49,
      165: 372.29,
      166: 374.09,
      167: 375.89,
      168: 377.69,
      169: 379.49,
      170: 381.29,
      171: 383.09,
      172: 384.89,
      173: 386.69,
      174: 388.49,
      175: 390.19,
      176: 391.89,
      177: 393.59,
      178: 395.29,
      179: 396.99,
      180: 398.69,
      181: 400.39,
      182: 402.09,
      183: 403.79,
      184: 405.49,
      185: 407.19,
      186: 408.89,
      187: 410.59,
      188: 412.29,
      189: 413.99,
      190: 415.69,
      191: 417.39,
      192: 419.09,
      193: 420.79,
      194: 422.49,
      195: 424.19,
      196: 425.89,
      197: 427.59,
      198: 429.29,
      199: 430.99,
      200: 432.69,
    },
  },
  {
    name: "Annual Subscription",
    product: "Guidey™ | Annual Subscription | Standard Pricing",
    productId: "prod_QeCsu6XwfQ401k",
    type: "basic",
    unitLabel: "property",
    cycle: "annual",
    currency: "eur",
    currencySymbol: "€",
    id: "annual-eur",
    value: "price_1PmvFuGMVnQW6tSnGemr9Tiz",
    pricing: {
      1: 85,
      2: 122,
      3: 159,
      4: 195,
      5: 231,
      6: 267,
      7: 302,
      8: 336,
      9: 369,
      10: 402,
      11: 433,
      12: 462,
      13: 490,
      14: 518,
      15: 546,
      16: 574,
      17: 602,
      18: 630,
      19: 658,
      20: 685,
      21: 712,
      22: 739,
      23: 766,
      24: 793,
      25: 820,
      26: 847,
      27: 873,
      28: 899,
      29: 925,
      30: 950,
      31: 975,
      32: 1000,
      33: 1025,
      34: 1050,
      35: 1075,
      36: 1100,
      37: 1125,
      38: 1150,
      39: 1175,
      40: 1197,
      41: 1219,
      42: 1241,
      43: 1263,
      44: 1285,
      45: 1307,
      46: 1329,
      47: 1351,
      48: 1373,
      49: 1395,
      50: 1415,
      51: 1435,
      52: 1455,
      53: 1475,
      54: 1495,
      55: 1515,
      56: 1535,
      57: 1555,
      58: 1575,
      59: 1595,
      60: 1615,
      61: 1635,
      62: 1655,
      63: 1675,
      64: 1695,
      65: 1715,
      66: 1735,
      67: 1755,
      68: 1775,
      69: 1795,
      70: 1815,
      71: 1835,
      72: 1855,
      73: 1875,
      74: 1895,
      75: 1915,
      76: 1935,
      77: 1955,
      78: 1974,
      79: 1993,
      80: 2012,
      81: 2031,
      82: 2050,
      83: 2069,
      84: 2088,
      85: 2107,
      86: 2126,
      87: 2145,
      88: 2164,
      89: 2183,
      90: 2202,
      91: 2221,
      92: 2240,
      93: 2259,
      94: 2278,
      95: 2297,
      96: 2316,
      97: 2335,
      98: 2354,
      99: 2373,
      100: 2392,
      101: 2411,
      102: 2430,
      103: 2449,
      104: 2468,
      105: 2487,
      106: 2505,
      107: 2523,
      108: 2541,
      109: 2559,
      110: 2577,
      111: 2595,
      112: 2613,
      113: 2631,
      114: 2649,
      115: 2667,
      116: 2685,
      117: 2703,
      118: 2721,
      119: 2739,
      120: 2757,
      121: 2775,
      122: 2793,
      123: 2811,
      124: 2829,
      125: 2847,
      126: 2865,
      127: 2883,
      128: 2901,
      129: 2919,
      130: 2937,
      131: 2955,
      132: 2973,
      133: 2991,
      134: 3009,
      135: 3027,
      136: 3045,
      137: 3063,
      138: 3081,
      139: 3099,
      140: 3117,
      141: 3135,
      142: 3153,
      143: 3171,
      144: 3189,
      145: 3207,
      146: 3225,
      147: 3243,
      148: 3261,
      149: 3279,
      150: 3297,
      151: 3315,
      152: 3333,
      153: 3351,
      154: 3369,
      155: 3387,
      156: 3405,
      157: 3423,
      158: 3440,
      159: 3457,
      160: 3474,
      161: 3491,
      162: 3508,
      163: 3525,
      164: 3542,
      165: 3559,
      166: 3576,
      167: 3593,
      168: 3610,
      169: 3627,
      170: 3644,
      171: 3661,
      172: 3678,
      173: 3695,
      174: 3712,
      175: 3729,
      176: 3746,
      177: 3763,
      178: 3780,
      179: 3796,
      180: 3812,
      181: 3828,
      182: 3844,
      183: 3860,
      184: 3876,
      185: 3892,
      186: 3908,
      187: 3924,
      188: 3940,
      189: 3956,
      190: 3972,
      191: 3988,
      192: 4004,
      193: 4020,
      194: 4036,
      195: 4052,
      196: 4068,
      197: 4084,
      198: 4100,
      199: 4116,
      200: 4132,
    },
    specialOffer: "",
    saveMessage: "SAVE 20%",
    renewalPricing: {
      1: 85,
      2: 122,
      3: 159,
      4: 195,
      5: 231,
      6: 267,
      7: 302,
      8: 336,
      9: 369,
      10: 402,
      11: 433,
      12: 462,
      13: 490,
      14: 518,
      15: 546,
      16: 574,
      17: 602,
      18: 630,
      19: 658,
      20: 685,
      21: 712,
      22: 739,
      23: 766,
      24: 793,
      25: 820,
      26: 847,
      27: 873,
      28: 899,
      29: 925,
      30: 950,
      31: 975,
      32: 1000,
      33: 1025,
      34: 1050,
      35: 1075,
      36: 1100,
      37: 1125,
      38: 1150,
      39: 1175,
      40: 1197,
      41: 1219,
      42: 1241,
      43: 1263,
      44: 1285,
      45: 1307,
      46: 1329,
      47: 1351,
      48: 1373,
      49: 1395,
      50: 1415,
      51: 1435,
      52: 1455,
      53: 1475,
      54: 1495,
      55: 1515,
      56: 1535,
      57: 1555,
      58: 1575,
      59: 1595,
      60: 1615,
      61: 1635,
      62: 1655,
      63: 1675,
      64: 1695,
      65: 1715,
      66: 1735,
      67: 1755,
      68: 1775,
      69: 1795,
      70: 1815,
      71: 1835,
      72: 1855,
      73: 1875,
      74: 1895,
      75: 1915,
      76: 1935,
      77: 1955,
      78: 1974,
      79: 1993,
      80: 2012,
      81: 2031,
      82: 2050,
      83: 2069,
      84: 2088,
      85: 2107,
      86: 2126,
      87: 2145,
      88: 2164,
      89: 2183,
      90: 2202,
      91: 2221,
      92: 2240,
      93: 2259,
      94: 2278,
      95: 2297,
      96: 2316,
      97: 2335,
      98: 2354,
      99: 2373,
      100: 2392,
      101: 2411,
      102: 2430,
      103: 2449,
      104: 2468,
      105: 2487,
      106: 2505,
      107: 2523,
      108: 2541,
      109: 2559,
      110: 2577,
      111: 2595,
      112: 2613,
      113: 2631,
      114: 2649,
      115: 2667,
      116: 2685,
      117: 2703,
      118: 2721,
      119: 2739,
      120: 2757,
      121: 2775,
      122: 2793,
      123: 2811,
      124: 2829,
      125: 2847,
      126: 2865,
      127: 2883,
      128: 2901,
      129: 2919,
      130: 2937,
      131: 2955,
      132: 2973,
      133: 2991,
      134: 3009,
      135: 3027,
      136: 3045,
      137: 3063,
      138: 3081,
      139: 3099,
      140: 3117,
      141: 3135,
      142: 3153,
      143: 3171,
      144: 3189,
      145: 3207,
      146: 3225,
      147: 3243,
      148: 3261,
      149: 3279,
      150: 3297,
      151: 3315,
      152: 3333,
      153: 3351,
      154: 3369,
      155: 3387,
      156: 3405,
      157: 3423,
      158: 3440,
      159: 3457,
      160: 3474,
      161: 3491,
      162: 3508,
      163: 3525,
      164: 3542,
      165: 3559,
      166: 3576,
      167: 3593,
      168: 3610,
      169: 3627,
      170: 3644,
      171: 3661,
      172: 3678,
      173: 3695,
      174: 3712,
      175: 3729,
      176: 3746,
      177: 3763,
      178: 3780,
      179: 3796,
      180: 3812,
      181: 3828,
      182: 3844,
      183: 3860,
      184: 3876,
      185: 3892,
      186: 3908,
      187: 3924,
      188: 3940,
      189: 3956,
      190: 3972,
      191: 3988,
      192: 4004,
      193: 4020,
      194: 4036,
      195: 4052,
      196: 4068,
      197: 4084,
      198: 4100,
      199: 4116,
      200: 4132,
    },
  },
  {
    name: "Monthly Subscription",
    product: "Guidey™ | Monthly Subscription | Standard Pricing",
    productId: "prod_ROOVAp98f36GSK",
    type: "basic",
    unitLabel: "property",
    cycle: "monthly",
    currency: "aud",
    currencySymbol: "A$",
    id: "monthly-aud",
    value: "price_1QVf2kGMVnQW6tSnTVWl01jx",
    pricing: {
      1: 14.49,
      2: 20.79,
      3: 26.99,
      4: 33.19,
      5: 39.19,
      6: 45.19,
      7: 51.19,
      8: 57.19,
      9: 62.79,
      10: 68.29,
      11: 73.59,
      12: 78.59,
      13: 83.59,
      14: 88.39,
      15: 93.19,
      16: 97.99,
      17: 102.59,
      18: 107.19,
      19: 111.79,
      20: 116.39,
      21: 120.99,
      22: 125.59,
      23: 130.19,
      24: 134.79,
      25: 139.19,
      26: 143.59,
      27: 147.99,
      28: 152.39,
      29: 156.79,
      30: 161.19,
      31: 165.59,
      32: 169.99,
      33: 174.39,
      34: 178.79,
      35: 183.19,
      36: 187.59,
      37: 191.99,
      38: 196.39,
      39: 200.79,
      40: 204.39,
      41: 207.99,
      42: 211.59,
      43: 215.19,
      44: 218.79,
      45: 222.39,
      46: 225.99,
      47: 229.59,
      48: 233.19,
      49: 236.79,
      50: 240.39,
      51: 243.99,
      52: 247.59,
      53: 251.19,
      54: 254.79,
      55: 258.39,
      56: 261.99,
      57: 265.59,
      58: 269.19,
      59: 272.79,
      60: 276.09,
      61: 279.39,
      62: 282.69,
      63: 285.99,
      64: 289.29,
      65: 292.59,
      66: 295.89,
      67: 299.19,
      68: 302.49,
      69: 305.79,
      70: 309.09,
      71: 312.39,
      72: 315.69,
      73: 318.99,
      74: 322.29,
      75: 325.59,
      76: 328.89,
      77: 332.19,
      78: 335.49,
      79: 338.79,
      80: 341.99,
      81: 345.19,
      82: 348.39,
      83: 351.59,
      84: 354.79,
      85: 357.99,
      86: 361.19,
      87: 364.39,
      88: 367.59,
      89: 370.79,
      90: 373.99,
      91: 377.19,
      92: 380.39,
      93: 383.59,
      94: 386.79,
      95: 389.99,
      96: 393.19,
      97: 396.39,
      98: 399.59,
      99: 402.79,
      100: 405.89,
      101: 408.99,
      102: 412.09,
      103: 415.19,
      104: 418.29,
      105: 421.39,
      106: 424.49,
      107: 427.59,
      108: 430.69,
      109: 433.79,
      110: 436.89,
      111: 439.99,
      112: 443.09,
      113: 446.19,
      114: 449.29,
      115: 452.39,
      116: 455.49,
      117: 458.59,
      118: 461.69,
      119: 464.79,
      120: 467.89,
      121: 470.99,
      122: 474.09,
      123: 477.19,
      124: 480.29,
      125: 483.39,
      126: 486.49,
      127: 489.59,
      128: 492.69,
      129: 495.79,
      130: 498.79,
      131: 501.79,
      132: 504.79,
      133: 507.79,
      134: 510.79,
      135: 513.79,
      136: 516.79,
      137: 519.79,
      138: 522.79,
      139: 525.79,
      140: 528.79,
      141: 531.79,
      142: 534.79,
      143: 537.79,
      144: 540.79,
      145: 543.79,
      146: 546.79,
      147: 549.79,
      148: 552.79,
      149: 555.79,
      150: 558.79,
      151: 561.79,
      152: 564.79,
      153: 567.79,
      154: 570.79,
      155: 573.79,
      156: 576.79,
      157: 579.79,
      158: 582.79,
      159: 585.79,
      160: 588.79,
      161: 591.79,
      162: 594.79,
      163: 597.79,
      164: 600.79,
      165: 603.79,
      166: 606.79,
      167: 609.79,
      168: 612.79,
      169: 615.79,
      170: 618.79,
      171: 621.79,
      172: 624.79,
      173: 627.79,
      174: 630.79,
      175: 633.59,
      176: 636.39,
      177: 639.19,
      178: 641.99,
      179: 644.79,
      180: 647.59,
      181: 650.39,
      182: 653.19,
      183: 655.99,
      184: 658.79,
      185: 661.59,
      186: 664.39,
      187: 667.19,
      188: 669.99,
      189: 672.79,
      190: 675.59,
      191: 678.39,
      192: 681.19,
      193: 683.99,
      194: 686.79,
      195: 689.59,
      196: 692.39,
      197: 695.19,
      198: 697.99,
      199: 700.79,
      200: 703.59,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      1: 14.49,
      2: 20.79,
      3: 26.99,
      4: 33.19,
      5: 39.19,
      6: 45.19,
      7: 51.19,
      8: 57.19,
      9: 62.79,
      10: 68.29,
      11: 73.59,
      12: 78.59,
      13: 83.59,
      14: 88.39,
      15: 93.19,
      16: 97.99,
      17: 102.59,
      18: 107.19,
      19: 111.79,
      20: 116.39,
      21: 120.99,
      22: 125.59,
      23: 130.19,
      24: 134.79,
      25: 139.19,
      26: 143.59,
      27: 147.99,
      28: 152.39,
      29: 156.79,
      30: 161.19,
      31: 165.59,
      32: 169.99,
      33: 174.39,
      34: 178.79,
      35: 183.19,
      36: 187.59,
      37: 191.99,
      38: 196.39,
      39: 200.79,
      40: 204.39,
      41: 207.99,
      42: 211.59,
      43: 215.19,
      44: 218.79,
      45: 222.39,
      46: 225.99,
      47: 229.59,
      48: 233.19,
      49: 236.79,
      50: 240.39,
      51: 243.99,
      52: 247.59,
      53: 251.19,
      54: 254.79,
      55: 258.39,
      56: 261.99,
      57: 265.59,
      58: 269.19,
      59: 272.79,
      60: 276.09,
      61: 279.39,
      62: 282.69,
      63: 285.99,
      64: 289.29,
      65: 292.59,
      66: 295.89,
      67: 299.19,
      68: 302.49,
      69: 305.79,
      70: 309.09,
      71: 312.39,
      72: 315.69,
      73: 318.99,
      74: 322.29,
      75: 325.59,
      76: 328.89,
      77: 332.19,
      78: 335.49,
      79: 338.79,
      80: 341.99,
      81: 345.19,
      82: 348.39,
      83: 351.59,
      84: 354.79,
      85: 357.99,
      86: 361.19,
      87: 364.39,
      88: 367.59,
      89: 370.79,
      90: 373.99,
      91: 377.19,
      92: 380.39,
      93: 383.59,
      94: 386.79,
      95: 389.99,
      96: 393.19,
      97: 396.39,
      98: 399.59,
      99: 402.79,
      100: 405.89,
      101: 408.99,
      102: 412.09,
      103: 415.19,
      104: 418.29,
      105: 421.39,
      106: 424.49,
      107: 427.59,
      108: 430.69,
      109: 433.79,
      110: 436.89,
      111: 439.99,
      112: 443.09,
      113: 446.19,
      114: 449.29,
      115: 452.39,
      116: 455.49,
      117: 458.59,
      118: 461.69,
      119: 464.79,
      120: 467.89,
      121: 470.99,
      122: 474.09,
      123: 477.19,
      124: 480.29,
      125: 483.39,
      126: 486.49,
      127: 489.59,
      128: 492.69,
      129: 495.79,
      130: 498.79,
      131: 501.79,
      132: 504.79,
      133: 507.79,
      134: 510.79,
      135: 513.79,
      136: 516.79,
      137: 519.79,
      138: 522.79,
      139: 525.79,
      140: 528.79,
      141: 531.79,
      142: 534.79,
      143: 537.79,
      144: 540.79,
      145: 543.79,
      146: 546.79,
      147: 549.79,
      148: 552.79,
      149: 555.79,
      150: 558.79,
      151: 561.79,
      152: 564.79,
      153: 567.79,
      154: 570.79,
      155: 573.79,
      156: 576.79,
      157: 579.79,
      158: 582.79,
      159: 585.79,
      160: 588.79,
      161: 591.79,
      162: 594.79,
      163: 597.79,
      164: 600.79,
      165: 603.79,
      166: 606.79,
      167: 609.79,
      168: 612.79,
      169: 615.79,
      170: 618.79,
      171: 621.79,
      172: 624.79,
      173: 627.79,
      174: 630.79,
      175: 633.59,
      176: 636.39,
      177: 639.19,
      178: 641.99,
      179: 644.79,
      180: 647.59,
      181: 650.39,
      182: 653.19,
      183: 655.99,
      184: 658.79,
      185: 661.59,
      186: 664.39,
      187: 667.19,
      188: 669.99,
      189: 672.79,
      190: 675.59,
      191: 678.39,
      192: 681.19,
      193: 683.99,
      194: 686.79,
      195: 689.59,
      196: 692.39,
      197: 695.19,
      198: 697.99,
      199: 700.79,
      200: 703.59,
    },
  },
  {
    name: "Annual Subscription",
    product: "Guidey™ | Annual Subscription | Standard Pricing",
    productId: "prod_QeCsu6XwfQ401k",
    type: "basic",
    unitLabel: "property",
    cycle: "annual",
    currency: "aud",
    currencySymbol: "A$",
    id: "annual-aud",
    value: "price_1PmvObGMVnQW6tSnbtsNzmrQ",
    pricing: {
      1: 139,
      2: 200,
      3: 260,
      4: 319,
      5: 378,
      6: 435,
      7: 492,
      8: 549,
      9: 604,
      10: 657,
      11: 707,
      12: 754,
      13: 800,
      14: 846,
      15: 891,
      16: 936,
      17: 981,
      18: 1026,
      19: 1071,
      20: 1116,
      21: 1161,
      22: 1206,
      23: 1251,
      24: 1294,
      25: 1337,
      26: 1380,
      27: 1423,
      28: 1466,
      29: 1509,
      30: 1550,
      31: 1591,
      32: 1632,
      33: 1673,
      34: 1714,
      35: 1755,
      36: 1796,
      37: 1837,
      38: 1878,
      39: 1919,
      40: 1954,
      41: 1989,
      42: 2024,
      43: 2059,
      44: 2094,
      45: 2129,
      46: 2164,
      47: 2199,
      48: 2234,
      49: 2269,
      50: 2304,
      51: 2339,
      52: 2374,
      53: 2409,
      54: 2444,
      55: 2479,
      56: 2514,
      57: 2549,
      58: 2584,
      59: 2619,
      60: 2651,
      61: 2683,
      62: 2715,
      63: 2747,
      64: 2779,
      65: 2811,
      66: 2843,
      67: 2875,
      68: 2907,
      69: 2939,
      70: 2971,
      71: 3003,
      72: 3035,
      73: 3067,
      74: 3099,
      75: 3131,
      76: 3163,
      77: 3195,
      78: 3227,
      79: 3259,
      80: 3290,
      81: 3321,
      82: 3352,
      83: 3383,
      84: 3414,
      85: 3445,
      86: 3476,
      87: 3507,
      88: 3538,
      89: 3569,
      90: 3600,
      91: 3631,
      92: 3662,
      93: 3693,
      94: 3724,
      95: 3755,
      96: 3786,
      97: 3817,
      98: 3848,
      99: 3879,
      100: 3909,
      101: 3939,
      102: 3969,
      103: 3999,
      104: 4029,
      105: 4059,
      106: 4089,
      107: 4119,
      108: 4149,
      109: 4179,
      110: 4209,
      111: 4239,
      112: 4269,
      113: 4299,
      114: 4329,
      115: 4359,
      116: 4389,
      117: 4419,
      118: 4449,
      119: 4479,
      120: 4509,
      121: 4539,
      122: 4569,
      123: 4599,
      124: 4629,
      125: 4658,
      126: 4687,
      127: 4716,
      128: 4745,
      129: 4774,
      130: 4803,
      131: 4832,
      132: 4861,
      133: 4890,
      134: 4919,
      135: 4948,
      136: 4977,
      137: 5006,
      138: 5035,
      139: 5064,
      140: 5093,
      141: 5122,
      142: 5151,
      143: 5180,
      144: 5209,
      145: 5238,
      146: 5267,
      147: 5296,
      148: 5325,
      149: 5354,
      150: 5383,
      151: 5412,
      152: 5441,
      153: 5470,
      154: 5499,
      155: 5528,
      156: 5557,
      157: 5586,
      158: 5615,
      159: 5644,
      160: 5673,
      161: 5702,
      162: 5731,
      163: 5760,
      164: 5789,
      165: 5818,
      166: 5847,
      167: 5876,
      168: 5905,
      169: 5934,
      170: 5963,
      171: 5992,
      172: 6021,
      173: 6050,
      174: 6079,
      175: 6106,
      176: 6133,
      177: 6160,
      178: 6187,
      179: 6214,
      180: 6241,
      181: 6268,
      182: 6295,
      183: 6322,
      184: 6349,
      185: 6376,
      186: 6403,
      187: 6430,
      188: 6457,
      189: 6484,
      190: 6511,
      191: 6538,
      192: 6565,
      193: 6592,
      194: 6619,
      195: 6646,
      196: 6673,
      197: 6700,
      198: 6727,
      199: 6754,
      200: 6781,
    },
    specialOffer: "",
    saveMessage: "SAVE 20%",
    renewalPricing: {
      1: 139,
      2: 200,
      3: 260,
      4: 319,
      5: 378,
      6: 435,
      7: 492,
      8: 549,
      9: 604,
      10: 657,
      11: 707,
      12: 754,
      13: 800,
      14: 846,
      15: 891,
      16: 936,
      17: 981,
      18: 1026,
      19: 1071,
      20: 1116,
      21: 1161,
      22: 1206,
      23: 1251,
      24: 1294,
      25: 1337,
      26: 1380,
      27: 1423,
      28: 1466,
      29: 1509,
      30: 1550,
      31: 1591,
      32: 1632,
      33: 1673,
      34: 1714,
      35: 1755,
      36: 1796,
      37: 1837,
      38: 1878,
      39: 1919,
      40: 1954,
      41: 1989,
      42: 2024,
      43: 2059,
      44: 2094,
      45: 2129,
      46: 2164,
      47: 2199,
      48: 2234,
      49: 2269,
      50: 2304,
      51: 2339,
      52: 2374,
      53: 2409,
      54: 2444,
      55: 2479,
      56: 2514,
      57: 2549,
      58: 2584,
      59: 2619,
      60: 2651,
      61: 2683,
      62: 2715,
      63: 2747,
      64: 2779,
      65: 2811,
      66: 2843,
      67: 2875,
      68: 2907,
      69: 2939,
      70: 2971,
      71: 3003,
      72: 3035,
      73: 3067,
      74: 3099,
      75: 3131,
      76: 3163,
      77: 3195,
      78: 3227,
      79: 3259,
      80: 3290,
      81: 3321,
      82: 3352,
      83: 3383,
      84: 3414,
      85: 3445,
      86: 3476,
      87: 3507,
      88: 3538,
      89: 3569,
      90: 3600,
      91: 3631,
      92: 3662,
      93: 3693,
      94: 3724,
      95: 3755,
      96: 3786,
      97: 3817,
      98: 3848,
      99: 3879,
      100: 3909,
      101: 3939,
      102: 3969,
      103: 3999,
      104: 4029,
      105: 4059,
      106: 4089,
      107: 4119,
      108: 4149,
      109: 4179,
      110: 4209,
      111: 4239,
      112: 4269,
      113: 4299,
      114: 4329,
      115: 4359,
      116: 4389,
      117: 4419,
      118: 4449,
      119: 4479,
      120: 4509,
      121: 4539,
      122: 4569,
      123: 4599,
      124: 4629,
      125: 4658,
      126: 4687,
      127: 4716,
      128: 4745,
      129: 4774,
      130: 4803,
      131: 4832,
      132: 4861,
      133: 4890,
      134: 4919,
      135: 4948,
      136: 4977,
      137: 5006,
      138: 5035,
      139: 5064,
      140: 5093,
      141: 5122,
      142: 5151,
      143: 5180,
      144: 5209,
      145: 5238,
      146: 5267,
      147: 5296,
      148: 5325,
      149: 5354,
      150: 5383,
      151: 5412,
      152: 5441,
      153: 5470,
      154: 5499,
      155: 5528,
      156: 5557,
      157: 5586,
      158: 5615,
      159: 5644,
      160: 5673,
      161: 5702,
      162: 5731,
      163: 5760,
      164: 5789,
      165: 5818,
      166: 5847,
      167: 5876,
      168: 5905,
      169: 5934,
      170: 5963,
      171: 5992,
      172: 6021,
      173: 6050,
      174: 6079,
      175: 6106,
      176: 6133,
      177: 6160,
      178: 6187,
      179: 6214,
      180: 6241,
      181: 6268,
      182: 6295,
      183: 6322,
      184: 6349,
      185: 6376,
      186: 6403,
      187: 6430,
      188: 6457,
      189: 6484,
      190: 6511,
      191: 6538,
      192: 6565,
      193: 6592,
      194: 6619,
      195: 6646,
      196: 6673,
      197: 6700,
      198: 6727,
      199: 6754,
      200: 6781,
    },
  },
];

export const subscriptionOffers = [
  {
    name: "Monthly Subscription",
    type: "basic",
    unitLabel: "property",
    cycle: "monthly",
    currency: "usd",
    currencySymbol: "$",
    id: "monthly-usd",
    value: "price_1JjLhGGMVnQW6tSnbvugVHxE",
    pricing: {
      tier1Unit: 7.99,
      tier2Unit: 4.99,
      tier3Unit: 3.99,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 7.99,
      tier2Unit: 4.99,
      tier3Unit: 3.99,
    },
  },
  {
    name: "Annual Subscription",
    type: "basic",
    unitLabel: "property",
    cycle: "annual",
    currency: "usd",
    currencySymbol: "$",
    id: "annual-usd",
    value: "price_1OFfdTGMVnQW6tSnCTF0ITJ8",
    pricing: {
      tier1Unit: 62.91,
      tier2Unit: 42.21,
      tier3Unit: 31.41,
    },
    specialOffer: "save-25",
    saveMessage: "SAVE 25% - Ends 28th Nov",
    renewalPricing: {
      tier1Unit: 89.99,
      tier2Unit: 56.88,
      tier3Unit: 45.48,
    },
  },

  {
    name: "Annual Subscription",
    type: "basic",
    unitLabel: "property",
    cycle: "annual",
    currency: "gbp",
    currencySymbol: "£",
    id: "annual-gbp",
    value: "price_1OuDC8GMVnQW6tSnNSxwXQmy",
    pricing: {
      tier1Unit: 16.8,
      tier2Unit: 16.8,
      tier3Unit: 16.8,
    },
    specialOffer: "save-25",
    saveMessage: "SPECIAL OFFER PRICING",
    renewalPricing: {
      tier1Unit: 16.8,
      tier2Unit: 16.8,
      tier3Unit: 16.8,
    },
  },
  {
    name: "Monthly Subscription",
    type: "basic",
    unitLabel: "property",
    cycle: "monthly",
    currency: "eur",
    currencySymbol: "€",
    id: "monthly-eur",
    value: "price_1O0m87GMVnQW6tSnAXZG6TRa",
    pricing: {
      tier1Unit: 6.99,
      tier2Unit: 4.69,
      tier3Unit: 3.49,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 6.99,
      tier2Unit: 4.69,
      tier3Unit: 3.49,
    },
  },
  {
    name: "Annual Subscription",
    type: "basic",
    unitLabel: "property",
    cycle: "annual",
    currency: "eur",
    currencySymbol: "€",
    id: "annual-eur",
    value: "price_1OFffNGMVnQW6tSns966FO9o",
    pricing: {
      tier1Unit: 62.91,
      tier2Unit: 42.21,
      tier3Unit: 31.41,
    },
    specialOffer: "save-25",
    saveMessage: "SAVE 25% - Ends 28th Nov",
    renewalPricing: {
      tier1Unit: 79.69,
      tier2Unit: 53.46,
      tier3Unit: 39.78,
    },
  },
  {
    name: "Monthly Subscription",
    type: "basic",
    unitLabel: "property",
    cycle: "monthly",
    currency: "aud",
    currencySymbol: "A$",
    id: "monthly-aud",
    value: "price_1JjLhGGMVnQW6tSnpwDX8wce",
    pricing: {
      tier1Unit: 11.99,
      tier2Unit: 7.99,
      tier3Unit: 5.99,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 11.99,
      tier2Unit: 7.99,
      tier3Unit: 5.99,
    },
  },
  {
    name: "Annual Subscription",
    type: "basic",
    unitLabel: "property",
    cycle: "annual",
    currency: "aud",
    currencySymbol: "A$",
    id: "annual-aud",
    value: "price_1OFfeSGMVnQW6tSnHbrRrt5t",
    pricing: {
      tier1Unit: 107.91,
      tier2Unit: 71.91,
      tier3Unit: 53.91,
    },
    specialOffer: "save-25",
    saveMessage: "SAVE 25% - Ends 28th Nov",
    renewalPricing: {
      tier1Unit: 134.99,
      tier2Unit: 91.08,
      tier3Unit: 68.28,
    },
  },
];

export const subscriptionCustomPricing = [
  {
    name: "Annual Subscription",
    product: "Guidey™ | Annual Subscription",
    productId: "prod_RNvzo56eI99cbL",
    type: "basic",
    unitLabel: "property",
    cycle: "annual",
    currency: "usd",
    currencySymbol: "$",
    id: "annual-usd",
    value: "price_1QVA80GMVnQW6tSnwZG3jIMk",
    pricing: {
      1: 28.91,
      2: 57.82,
      3: 86.73,
      4: 115.64,
      5: 144.55,
      6: 173.46,
      7: 202.37,
      8: 231.28,
      9: 260.19,
      10: 289.1,
      11: 318.01,
      12: 346.92,
      13: 375.83,
      14: 404.74,
      15: 433.65,
      16: 462.56,
      17: 491.47,
      18: 520.38,
      19: 549.29,
      20: 578.2,
      21: 607.11,
      22: 636.02,
      23: 664.93,
      24: 693.84,
      25: 722.75,
      26: 751.66,
      27: 780.57,
      28: 809.48,
      29: 838.39,
      30: 867.3,
      31: 896.21,
      32: 925.12,
      33: 954.03,
      34: 982.94,
      35: 1011.85,
      36: 1040.76,
      37: 1069.67,
      38: 1098.58,
      39: 1127.49,
      40: 1156.4,
      41: 1185.31,
      42: 1214.22,
      43: 1243.13,
      44: 1272.04,
      45: 1300.95,
      46: 1329.86,
      47: 1358.77,
      48: 1387.68,
      49: 1416.59,
      50: 1445.5,
      51: 1474.41,
      52: 1503.32,
      53: 1532.23,
      54: 1561.14,
      55: 1590.05,
      56: 1618.96,
      57: 1647.87,
      58: 1676.78,
      59: 1705.69,
      60: 1735.08,
      61: 1763.51,
      62: 1792.42,
      63: 1821.33,
      64: 1850.24,
      65: 1879.15,
      66: 1908.06,
      67: 1936.97,
      68: 1965.88,
      69: 1994.79,
      70: 2023.7,
      71: 2052.61,
      72: 2081.52,
      73: 2110.43,
      74: 2139.34,
      75: 2168.25,
      76: 2197.16,
      77: 2226.07,
      78: 2254.98,
      79: 2283.89,
      80: 2312.8,
      81: 2341.71,
      82: 2370.62,
      83: 2399.53,
      84: 2428.44,
      85: 2457.35,
      86: 2486.26,
      87: 2515.17,
      88: 2544.08,
      89: 2572.99,
      90: 2601.9,
      91: 2630.81,
      92: 2659.72,
      93: 2688.63,
      94: 2717.54,
      95: 2746.45,
      96: 2775.36,
      97: 2804.27,
      98: 2833.18,
      99: 2862.09,
      100: 2891,
      101: 2919.91,
      102: 2948.82,
      103: 2977.73,
      104: 3006.64,
      105: 3035.55,
      106: 3064.46,
      107: 3093.37,
      108: 3122.28,
      109: 3151.19,
      110: 3180.1,
      111: 3209.01,
      112: 3237.92,
      113: 3266.83,
      114: 3295.74,
      115: 3324.65,
      116: 3353.56,
      117: 3382.47,
      118: 3411.38,
      119: 3440.29,
      120: 3469.2,
      121: 3498.11,
      122: 3527.02,
      123: 3555.93,
      124: 3584.84,
      125: 3613.75,
      126: 3642.66,
      127: 3671.57,
      128: 3700.48,
      129: 3729.39,
      130: 3758.3,
      131: 3787.21,
      132: 3816.12,
      133: 3845.03,
      134: 3873.94,
      135: 3902.85,
      136: 3931.76,
      137: 3960.67,
      138: 3989.58,
      139: 4018.49,
      140: 4047.4,
      141: 4076.31,
      142: 4105.22,
      143: 4134.13,
      144: 4163.04,
      145: 4191.95,
      146: 4220.86,
      147: 4249.77,
      148: 4278.68,
      149: 4307.59,
      150: 4336.5,
      151: 4365.41,
      152: 4394.32,
      153: 4423.23,
      154: 4452.14,
      155: 4481.05,
      156: 4509.96,
      157: 4538.87,
      158: 4567.78,
      159: 4596.69,
      160: 4625.6,
      161: 4654.51,
      162: 4683.42,
      163: 4712.33,
      164: 4741.24,
      165: 4770.15,
      166: 4799.06,
      167: 4827.97,
      168: 4856.88,
      169: 4885.79,
      170: 4914.7,
      171: 4943.61,
      172: 4972.52,
      173: 5001.43,
      174: 5030.34,
      175: 5059.25,
      176: 5088.16,
      177: 5117.07,
      178: 5145.98,
      179: 5174.89,
      180: 5203.8,
      181: 5232.71,
      182: 5261.62,
      183: 5290.53,
      184: 5319.44,
      185: 5348.35,
      186: 5377.26,
      187: 5406.17,
      188: 5435.08,
      189: 5463.99,
      190: 5492.9,
      191: 5521.81,
      192: 5550.72,
      193: 5579.63,
      194: 5608.54,
      195: 5637.45,
      196: 5666.36,
      197: 5695.27,
      198: 5724.18,
      199: 5753.09,
      200: 5782,
    },
    specialOffer: "save-20",
    saveMessage: "SAVE 20%",
    renewalPricing: {
      1: 28.91,
      2: 57.82,
      3: 86.73,
      4: 115.64,
      5: 144.55,
      6: 173.46,
      7: 202.37,
      8: 231.28,
      9: 260.19,
      10: 289.1,
      11: 318.01,
      12: 346.92,
      13: 375.83,
      14: 404.74,
      15: 433.65,
      16: 462.56,
      17: 491.47,
      18: 520.38,
      19: 549.29,
      20: 578.2,
      21: 607.11,
      22: 636.02,
      23: 664.93,
      24: 693.84,
      25: 722.75,
      26: 751.66,
      27: 780.57,
      28: 809.48,
      29: 838.39,
      30: 867.3,
      31: 896.21,
      32: 925.12,
      33: 954.03,
      34: 982.94,
      35: 1011.85,
      36: 1040.76,
      37: 1069.67,
      38: 1098.58,
      39: 1127.49,
      40: 1156.4,
      41: 1185.31,
      42: 1214.22,
      43: 1243.13,
      44: 1272.04,
      45: 1300.95,
      46: 1329.86,
      47: 1358.77,
      48: 1387.68,
      49: 1416.59,
      50: 1445.5,
      51: 1474.41,
      52: 1503.32,
      53: 1532.23,
      54: 1561.14,
      55: 1590.05,
      56: 1618.96,
      57: 1647.87,
      58: 1676.78,
      59: 1705.69,
      60: 1735.08,
      61: 1763.51,
      62: 1792.42,
      63: 1821.33,
      64: 1850.24,
      65: 1879.15,
      66: 1908.06,
      67: 1936.97,
      68: 1965.88,
      69: 1994.79,
      70: 2023.7,
      71: 2052.61,
      72: 2081.52,
      73: 2110.43,
      74: 2139.34,
      75: 2168.25,
      76: 2197.16,
      77: 2226.07,
      78: 2254.98,
      79: 2283.89,
      80: 2312.8,
      81: 2341.71,
      82: 2370.62,
      83: 2399.53,
      84: 2428.44,
      85: 2457.35,
      86: 2486.26,
      87: 2515.17,
      88: 2544.08,
      89: 2572.99,
      90: 2601.9,
      91: 2630.81,
      92: 2659.72,
      93: 2688.63,
      94: 2717.54,
      95: 2746.45,
      96: 2775.36,
      97: 2804.27,
      98: 2833.18,
      99: 2862.09,
      100: 2891,
      101: 2919.91,
      102: 2948.82,
      103: 2977.73,
      104: 3006.64,
      105: 3035.55,
      106: 3064.46,
      107: 3093.37,
      108: 3122.28,
      109: 3151.19,
      110: 3180.1,
      111: 3209.01,
      112: 3237.92,
      113: 3266.83,
      114: 3295.74,
      115: 3324.65,
      116: 3353.56,
      117: 3382.47,
      118: 3411.38,
      119: 3440.29,
      120: 3469.2,
      121: 3498.11,
      122: 3527.02,
      123: 3555.93,
      124: 3584.84,
      125: 3613.75,
      126: 3642.66,
      127: 3671.57,
      128: 3700.48,
      129: 3729.39,
      130: 3758.3,
      131: 3787.21,
      132: 3816.12,
      133: 3845.03,
      134: 3873.94,
      135: 3902.85,
      136: 3931.76,
      137: 3960.67,
      138: 3989.58,
      139: 4018.49,
      140: 4047.4,
      141: 4076.31,
      142: 4105.22,
      143: 4134.13,
      144: 4163.04,
      145: 4191.95,
      146: 4220.86,
      147: 4249.77,
      148: 4278.68,
      149: 4307.59,
      150: 4336.5,
      151: 4365.41,
      152: 4394.32,
      153: 4423.23,
      154: 4452.14,
      155: 4481.05,
      156: 4509.96,
      157: 4538.87,
      158: 4567.78,
      159: 4596.69,
      160: 4625.6,
      161: 4654.51,
      162: 4683.42,
      163: 4712.33,
      164: 4741.24,
      165: 4770.15,
      166: 4799.06,
      167: 4827.97,
      168: 4856.88,
      169: 4885.79,
      170: 4914.7,
      171: 4943.61,
      172: 4972.52,
      173: 5001.43,
      174: 5030.34,
      175: 5059.25,
      176: 5088.16,
      177: 5117.07,
      178: 5145.98,
      179: 5174.89,
      180: 5203.8,
      181: 5232.71,
      182: 5261.62,
      183: 5290.53,
      184: 5319.44,
      185: 5348.35,
      186: 5377.26,
      187: 5406.17,
      188: 5435.08,
      189: 5463.99,
      190: 5492.9,
      191: 5521.81,
      192: 5550.72,
      193: 5579.63,
      194: 5608.54,
      195: 5637.45,
      196: 5666.36,
      197: 5695.27,
      198: 5724.18,
      199: 5753.09,
      200: 5782,
    },
  },
  {
    name: "Monthly Subscription",
    product: "Guidey™ | Monthly Subscription | Unit Pricing",
    productId: "prod_Qwxc8nL5NZ1ru4",
    type: "basic",
    unitLabel: "guide",
    cycle: "monthly",
    currency: "gbp",
    currencySymbol: "£",
    id: "monthly-gbp",
    value: "price_1Q53hCGMVnQW6tSnGm4Eg0fh",
    pricing: {
      tier1Unit: 6.0,
      tier2Unit: 6.0,
      tier3Unit: 6.0,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 6.0,
      tier2Unit: 6.0,
      tier3Unit: 6.0,
    },
  },

  {
    name: "Annual Subscription",
    product: "Guidey™ | Annual Subscription | Unit Pricing",
    productId: "prod_QwxaFj5B0aXAy2",
    type: "basic",
    unitLabel: "guide",
    cycle: "annual",
    currency: "gbp",
    currencySymbol: "£",
    id: "annual-gbp",
    value: "price_1Q53fDGMVnQW6tSnnk1OxbDr",
    pricing: {
      tier1Unit: 57.67,
      tier2Unit: 57.67,
      tier3Unit: 57.67,
    },
    specialOffer: "save-20",
    saveMessage: "SAVE 20%",
    renewalPricing: {
      tier1Unit: 57.67,
      tier2Unit: 57.67,
      tier3Unit: 57.67,
    },
  },
  {
    name: "Monthly Subscription",
    product: "Guidey™ | Monthly Subscription | Unit Pricing",
    productId: "prod_Qwxc8nL5NZ1ru4",
    type: "basic",
    unitLabel: "property",
    cycle: "monthly",
    currency: "eur",
    currencySymbol: "€",
    id: "monthly-eur",
    value: "price_1O0m87GMVnQW6tSnAXZG6TRa",
    pricing: {
      tier1Unit: 6.99,
      tier2Unit: 4.69,
      tier3Unit: 3.49,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 6.99,
      tier2Unit: 4.69,
      tier3Unit: 3.49,
    },
  },
  {
    name: "Annual Subscription",
    product: "Guidey™ | Annual Subscription | Unit Pricing",
    productId: "prod_QwxaFj5B0aXAy2",
    type: "basic",
    unitLabel: "property",
    cycle: "annual",
    currency: "eur",
    currencySymbol: "€",
    id: "annual-eur",
    value: "price_1OFffNGMVnQW6tSns966FO9o",
    pricing: {
      tier1Unit: 62.91,
      tier2Unit: 42.21,
      tier3Unit: 31.41,
    },
    specialOffer: "save-25",
    saveMessage: "SAVE 25% - Ends 28th Nov",
    renewalPricing: {
      tier1Unit: 79.69,
      tier2Unit: 53.46,
      tier3Unit: 39.78,
    },
  },
  {
    name: "Monthly Subscription",
    product: "Guidey™ | Monthly Subscription | Unit Pricing",
    productId: "prod_Qwxc8nL5NZ1ru4",
    type: "basic",
    unitLabel: "property",
    cycle: "monthly",
    currency: "aud",
    currencySymbol: "A$",
    id: "monthly-aud",
    value: "price_1JjLhGGMVnQW6tSnpwDX8wce",
    pricing: {
      tier1Unit: 11.99,
      tier2Unit: 7.99,
      tier3Unit: 5.99,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 11.99,
      tier2Unit: 7.99,
      tier3Unit: 5.99,
    },
  },
  {
    name: "Annual Subscription",
    product: "Guidey™ | Annual Subscription | Unit Pricing",
    productId: "prod_QwxaFj5B0aXAy2",
    type: "basic",
    unitLabel: "property",
    cycle: "annual",
    currency: "aud",
    currencySymbol: "A$",
    id: "annual-aud",
    value: "price_1OFfeSGMVnQW6tSnHbrRrt5t",
    pricing: {
      tier1Unit: 107.91,
      tier2Unit: 71.91,
      tier3Unit: 53.91,
    },
    specialOffer: "save-25",
    saveMessage: "SAVE 25% - Ends 28th Nov",
    renewalPricing: {
      tier1Unit: 134.99,
      tier2Unit: 91.08,
      tier3Unit: 68.28,
    },
  },
];

export const subscriptionUnitPricing = [
  {
    name: "Monthly Subscription",
    type: "basic",
    unitLabel: "guide",
    cycle: "monthly",
    currency: "usd",
    currencySymbol: "$",
    id: "monthly-usd",
    value: "price_1JjLhGGMVnQW6tSnbvugVHxE",
    pricing: {
      tier1Unit: 7.99,
      tier2Unit: 4.99,
      tier3Unit: 3.99,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 7.99,
      tier2Unit: 4.99,
      tier3Unit: 3.99,
    },
  },
  {
    name: "Annual Subscription",
    type: "basic",
    unitLabel: "guide",
    cycle: "annual",
    currency: "usd",
    currencySymbol: "$",
    id: "annual-usd",
    value: "price_1OFfdTGMVnQW6tSnCTF0ITJ8",
    pricing: {
      tier1Unit: 62.91,
      tier2Unit: 42.21,
      tier3Unit: 31.41,
    },
    specialOffer: "save-25",
    saveMessage: "SAVE 25% - Ends 28th Nov",
    renewalPricing: {
      tier1Unit: 89.99,
      tier2Unit: 56.88,
      tier3Unit: 45.48,
    },
  },
  {
    name: "Monthly Subscription",
    type: "basic",
    unitLabel: "guide",
    cycle: "monthly",
    currency: "gbp",
    currencySymbol: "£",
    id: "monthly-gbp",
    value: "price_1JjLhGGMVnQW6tSnbvugVHxE",
    pricing: {
      tier1Unit: 6.01,
      tier2Unit: 6.01,
      tier3Unit: 6.01,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 6.01,
      tier2Unit: 6.01,
      tier3Unit: 6.01,
    },
  },
  {
    name: "Annual Subscription",
    type: "basic",
    unitLabel: "guide",
    cycle: "annual",
    currency: "gbp",
    currencySymbol: "£",
    id: "annual-gbp",
    value: "price_1OuDC8GMVnQW6tSnNSxwXQmy",
    pricing: {
      tier1Unit: 57.68,
      tier2Unit: 57.68,
      tier3Unit: 57.68,
    },
    specialOffer: "unit-pricing-model",
    saveMessage: "SAVE 20%",
    renewalPricing: {
      tier1Unit: 57.68,
      tier2Unit: 57.68,
      tier3Unit: 57.68,
    },
  },
  {
    name: "Monthly Subscription",
    type: "basic",
    unitLabel: "guide",
    cycle: "monthly",
    currency: "eur",
    currencySymbol: "€",
    id: "monthly-eur",
    value: "price_1O0m87GMVnQW6tSnAXZG6TRa",
    pricing: {
      tier1Unit: 6.99,
      tier2Unit: 4.69,
      tier3Unit: 3.49,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 6.99,
      tier2Unit: 4.69,
      tier3Unit: 3.49,
    },
  },
  {
    name: "Annual Subscription",
    type: "basic",
    unitLabel: "guide",
    cycle: "annual",
    currency: "eur",
    currencySymbol: "€",
    id: "annual-eur",
    value: "price_1OFffNGMVnQW6tSns966FO9o",
    pricing: {
      tier1Unit: 62.91,
      tier2Unit: 42.21,
      tier3Unit: 31.41,
    },
    specialOffer: "save-25",
    saveMessage: "SAVE 25% - Ends 28th Nov",
    renewalPricing: {
      tier1Unit: 79.69,
      tier2Unit: 53.46,
      tier3Unit: 39.78,
    },
  },
  {
    name: "Monthly Subscription",
    type: "basic",
    unitLabel: "guide",
    cycle: "monthly",
    currency: "aud",
    currencySymbol: "A$",
    id: "monthly-aud",
    value: "price_1JjLhGGMVnQW6tSnpwDX8wce",
    pricing: {
      tier1Unit: 11.99,
      tier2Unit: 7.99,
      tier3Unit: 5.99,
    },
    specialOffer: "",
    saveMessage: "",
    renewalPricing: {
      tier1Unit: 11.99,
      tier2Unit: 7.99,
      tier3Unit: 5.99,
    },
  },
  {
    name: "Annual Subscription",
    type: "basic",
    unitLabel: "guide",
    cycle: "annual",
    currency: "aud",
    currencySymbol: "A$",
    id: "annual-aud",
    value: "price_1OFfeSGMVnQW6tSnHbrRrt5t",
    pricing: {
      tier1Unit: 107.91,
      tier2Unit: 71.91,
      tier3Unit: 53.91,
    },
    specialOffer: "save-25",
    saveMessage: "SAVE 25% - Ends 28th Nov",
    renewalPricing: {
      tier1Unit: 134.99,
      tier2Unit: 91.08,
      tier3Unit: 68.28,
    },
  },
];
