import React from "react";
import NavigationHeader from "../components/NavigationHeader";
import CreateLocalProfileModule from "../components/CreateLocalProfileModule";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class CreateLocalAreaProfilePage extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { SaveStatus, userData, AttachProfilesLoaded } = this.props;
    const currentPageTitle = "Create a New Local Area Profile";

    return (
      <>
        <NavigationHeader
          {...this.props}
          previousPageTitle={"Local Area Profiles"}
          previousPagePath={"/local-area-profiles"}
          currentPageTitle={currentPageTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {currentPageTitle}</div>
            <div className="infopage-subheading">
              Local area profiles can be shared across multiple properties
            </div>

            <CreateLocalProfileModule
              {...this.props}
              //  propertyId={propertyId}
              UserId={userData._id}
              ItemLabel={"create-local-profile"}
              SaveStatus={SaveStatus}
              //      propertyDetails={propertyDetails}
              InputLabelA={"Property address"}
              AttachProfilesLoaded={AttachProfilesLoaded}
            />
          </div>
        </div>
      </>
    );
  }
}
