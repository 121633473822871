import React from "react";
import { connect } from "react-redux";
import { getReferralData } from "../redux/actions/userActions";
import { appConfig } from "../data/appConfig";
import Loading from "../components/Loading";
import NavigationHeader from "../components/NavigationHeader";
import LinkIcon from "../icons/LinkIcon";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import Table from "../components/Table";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/Referrals.css";

class ReferralsPage extends React.Component {
  state = {
    linkCopied: false,
    referralUrl: "",
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const { userData } = this.props;
    this.props.getReferralData();
    this.setState({
      referralUrl: `${appConfig.referralUrl}?ref=${userData.ReferrerId}`,
    });
  }

  onLinkCopy = () => {
    navigator.clipboard.writeText(this.state.referralUrl);
    this.setState({ linkCopied: true });
    setTimeout(() => this.setState({ linkCopied: false }), 3000);
  };

  render() {
    const { userData, referralData, ReferralDataLoaded, geoLocationData } =
      this.props;
    // console.log("referralData", referralData);
    // console.log("referralDataLoaded", ReferralDataLoaded);

    //  const referralUrl = `${appConfig.referralUrl}?ref=${userData.ReferrerId}`;

    const { referralUrl } = this.state;

    const currency = userData.DefaultCurrency
      ? userData.DefaultCurrency
      : geoLocationData.currency.toLowerCase();
    // console.log(currency);

    const currencySymbol =
      currency === "gbp"
        ? "£"
        : currency === "aud"
        ? "A$"
        : currency === "eur"
        ? "€"
        : currency === "usd"
        ? "$"
        : "$";

    if (!ReferralDataLoaded) {
      return <Loading LoadingCanvas={"loading-page-header-canvas"} />;
    }

    if (ReferralDataLoaded) {
      const customerReferralData = referralData.customerReferrals.map(
        (item) => {
          return {
            PropertyTitle: item.PropertyTitle,
            RewardAmount: `${currencySymbol}${item.RewardAmount}`,
            Status: item.RewardCredited
              ? "Confirmed, reward payment completed"
              : item.RewardClaimable && !item.RewardCredited
              ? "Confirmed, you can now claim your reward"
              : item.ActiveSubscriber &&
                !item.RewardClaimable &&
                !item.RewardCredited
              ? "Subscribed. First month not completed yet"
              : !item.ActiveSubscriber &&
                !item.RewardCredited &&
                item.TrialEndDate > new Date()
              ? "In trial period, not subscribed yet"
              : !item.ActiveSubscriber &&
                !item.RewardCredited &&
                item.TrialEndDate < new Date()
              ? "Trial ended, not subscribed yet"
              : "",
            // RewardLink: item.RewardLink
          };
        }
      );

      const currentRewardValue =
        currency === "gbp"
          ? referralData.currentRewardValueGBP
          : currency === "usd"
          ? referralData.currentRewardValueUSD
          : currency === "aud"
          ? referralData.currentRewardValueAUD
          : currency === "eur"
          ? referralData.currentRewardValueEUR
          : referralData.currentRewardValueUSD;

      // const accountBalanceCalc = -referralData.accountBalance / 100;
      // const accountBalance = accountBalanceCalc.toFixed(2);

      const currentPageTitle = "Referrals";

      return (
        <>
          <NavigationHeader
            {...this.props}
            previousPageTitle={"Dashboard"}
            previousPagePath={"/"}
            currentPageTitle={currentPageTitle}
          />
          <div className="canvas-with-header-navheader-and-footer">
            <div className="canvas-with-header-and-footer-inner-container">
              <div className="h1-header-large"> {currentPageTitle}</div>
              <div className="top-section-wrapper">
                <div className="top-section-detail-snippet">
                  <div className="section-subheading">
                    Refer friends and claim a free{" "}
                    <span>
                      <strong>
                        {currencySymbol}
                        {currentRewardValue}
                      </strong>
                    </span>{" "}
                    Amazon voucher for each friend!
                  </div>

                  {
                    //  <div className="top-section-callout-banner">
                    //   <div className="top-section-callout-banner-inner">
                    //    Amazon Voucher image
                    //  </div>
                    //  </div>
                  }

                  <div className="sub-section-text">
                    <strong>1.</strong> Simply share your unique referral link
                    below.
                  </div>

                  <div className="sub-section-text">
                    <strong>2.</strong> Anyone who signs up from your link will
                    be automatically tracked.
                  </div>

                  <div className="sub-section-text">
                    <strong>3.</strong> Shortly after they’ve completed their
                    first paid month, we will issue a voucher to you via email!
                  </div>
                  <div className="sub-section-text">
                    You can track the progress of your referrals at any time by
                    visiting this page.
                  </div>
                </div>
              </div>

              <div className="url-callout-banner">
                <div className="url-callout-banner-header">
                  👇 <strong>Share Your Link</strong> 👇
                </div>

                {
                  //    <div className="url-callout-banner-social-buttons-container">
                  //    <a
                  //      href={`https://api.whatsapp.com/send?text=${referralUrl}`}
                  //     target="_blank"
                  //      rel="noopener"
                  //   >
                  //     <button>WhatsApp Share</button>
                  //   </a>
                  //  </div>
                }

                <a
                  className="url-callout-banner-value"
                  id="infopage-link"
                  href={referralUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {referralUrl}
                </a>

                <button
                  className="referral-link-button"
                  onClick={this.onLinkCopy}
                >
                  {!this.state.linkCopied && (
                    <>
                      <div className="referral-link-label">Copy link</div>

                      <LinkIcon className="referral-link-box-icon" />
                    </>
                  )}

                  {this.state.linkCopied && (
                    <>
                      <div className="referral-link-label">
                        Link copied to clipboard
                      </div>
                      <CheckmarkIcon className="referral-link-box-icon" />
                    </>
                  )}
                </button>
              </div>

              <div className="mid-section-wrapper">
                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>

                <div className="sub-section-heading">Your Referrals</div>

                <div className="stats-section-callout-banner-wrapper">
                  <div className="stats-section-callout-banner">
                    <div className="stats-section-callout-banner-inner">
                      <div className="stats-section-callout-banner-header">
                        Total Link<br></br>Clicks
                      </div>
                      <div className="stats-section-callout-banner-value">
                        {referralData.totalLinkClicks
                          ? referralData.totalLinkClicks
                          : 0}
                      </div>
                    </div>
                  </div>

                  <div className="stats-section-callout-banner-spacer"></div>

                  <div className="stats-section-callout-banner">
                    <div className="stats-section-callout-banner-inner">
                      <div className="stats-section-callout-banner-header">
                        Total Confirmed<br></br>Referrals
                      </div>
                      <div className="stats-section-callout-banner-value">
                        {referralData.totalReferrals}
                      </div>
                    </div>
                  </div>

                  <div className="stats-section-callout-banner-spacer"></div>

                  <div className="stats-section-callout-banner">
                    <div className="stats-section-callout-banner-inner">
                      <div className="stats-section-callout-banner-header">
                        Total Rewards<br></br>Earned
                      </div>
                      <div className="stats-section-callout-banner-value">
                        <span>{currencySymbol}</span>
                        {referralData.totalRewards
                          ? referralData.totalRewards
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>

                {customerReferralData.length > 0 && (
                  <Table
                    {...{
                      data: customerReferralData,
                      fields: {
                        PropertyTitle: "Property Name",
                        RewardAmount: "Reward Amount",
                        Status: "Status",
                        // RewardLink: "Reward Link",
                      },
                    }}
                  />
                )}

                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>

                <div className="ts-and-cs-text">
                  <strong>Referral Promotion Terms and Conditions </strong>
                  <ol>
                    <li>
                      The referral reward ('reward') will be issued for each
                      new, legitimate user referred to us through your unique
                      referral link. Users with multiple properties count as 1
                      referral. The referred friend must hold a paid
                      subscription for at least 35 days.
                    </li>
                    <li>
                      If the referred friend signs up for a monthly
                      subscription, the reward will be issued in monthly
                      installments until the full reward amount has been issued.
                      If the referred friend signs up for an annual
                      subscription, the full amount of the reward will be issued
                      in the first installment.
                    </li>
                    <li>
                      There is no limit to how many referrals you can make and
                      no limit to how many rewards you can earn. However, we
                      reserve the right to revoke rewards if we deem that the
                      promotion has been abused.
                    </li>
                    <li>
                      Rewards must be claimed within 90 days of being issued.
                      Within this period, rewards remain claimable whilst your
                      account is a live, paid subscription. Rewards are not
                      redeemable as cash or any other form of payment if you
                      cancel your subscription.
                    </li>
                    <li>
                      We reserve the right to revoke rewards if you cancel your
                      subscription or if your payment fails and we are required
                      to revoke your access to our service.
                    </li>
                    <li>
                      By sharing your referral link, you agree to these terms
                      and conditions.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getReferralData: () => dispatch(getReferralData()),
  };
};

export default connect(null, mapDispatchToProps)(ReferralsPage);
