import React from "react";
import NavigationHeader from "../components/NavigationHeader";
import PropertyDetailsSection from "../components/PropertyDetailsSection";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class ContentCategoryPage extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  checkIfChecked = (label, propertyId) => {
    const {
      isComponentShared,
      SharedPropertyContentProfileId,
      customContent,
      sharedContent,
    } = this.props;
    const selectedContent = isComponentShared ? sharedContent : customContent;

    var filter = false;
    if (!isComponentShared) {
      filter = selectedContent.filter((Item) => {
        return (
          Item &&
          Item.LinkedProperty &&
          Item.LinkedProperty.includes(propertyId) &&
          Item.ContentType === "details" &&
          Item.CategoryLabel === label &&
          (Item.DetailText ||
            Item.DetailTextDescribe ||
            Item.DetailTextLocate ||
            Item.DetailTextOperate ||
            Item.WebLink ||
            Item.DetailVideo ||
            Item.DetailImage ||
            Item.DetailDocument)
        );
      });
    }
    if (isComponentShared) {
      filter = selectedContent.filter((Item) => {
        return (
          Item &&
          Item.LinkedSharedContentProfile &&
          Item.LinkedSharedContentProfile === SharedPropertyContentProfileId &&
          Item.ContentType === "details" &&
          Item.CategoryLabel === label &&
          (Item.DetailText ||
            Item.DetailTextDescribe ||
            Item.DetailTextLocate ||
            Item.DetailTextOperate ||
            Item.WebLink ||
            Item.DetailVideo ||
            Item.DetailImage ||
            Item.DetailDocument)
        );
      });
    }
    if (filter[0]) return true;
    if (!filter[0]) return false;
  };

  render() {
    const { SaveStatus, propertyData, systemCategories, isComponentShared } =
      this.props;

    // Extract VanityPropertyId from current page's URL
    const vanityPropertyId = window.location.pathname.split("/")[2];
    const previousPagePathRoot = window.location.pathname.split("/")[1];
    const ParentCategoryLabel = window.location.pathname.split("/")[3];
    //console.log(ParentCategoryLabel)
    // Use Detail label to lookup custom content
    const propertyDataFiltered = propertyData.filter((Item) => {
      return Item.PropertyID === vanityPropertyId;
    });
    const propertyDetails = propertyDataFiltered[0];

    const previousPageTitle = isComponentShared
      ? "Shared Property Content"
      : propertyDetails && propertyDetails.PropertyTitle
      ? propertyDetails.PropertyTitle
      : "Unamed Place";

    const systemCategoriesFiltered = systemCategories.filter((item) => {
      return (
        item.ParentCategoryLabel === ParentCategoryLabel &&
        item.ThemeLabel === "default"
      );
    });

    const systemCategoriesFilteredAgain = systemCategories.filter((item) => {
      return item.CategoryLabel === ParentCategoryLabel;
    });

    const SectionTitle = systemCategoriesFilteredAgain[0].CategoryTitle;
    const pathRoot = window.location.pathname.split("/")[1];
    return (
      <>
        <NavigationHeader
          {...this.props}
          propertyDetails={propertyDetails}
          pathRoot={pathRoot}
          previousPageTitle={previousPageTitle}
          previousPagePath={`/${previousPagePathRoot}/${vanityPropertyId}`}
          currentPageTitle={SectionTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {SectionTitle}</div>

            {systemCategoriesFiltered.map((item) => (
              <div key={item._id}>
                <PropertyDetailsSection
                  {...this.props}
                  SectionType={"non-link"}
                  IsChecked={this.checkIfChecked(
                    item.CategoryLabel,
                    propertyDetails ? propertyDetails._id : null
                  )}
                  IsRequired={item.IsRequired}
                  ShowActionButton={false}
                  SectionTitle={item.CategoryTitle}
                  SectionText={item.CategoryShortDescription}
                  SaveStatus={SaveStatus}
                  vanityPropertyId={
                    propertyDetails ? propertyDetails.PropertyID : null
                  }
                  CategoryLabel={item.CategoryLabel}
                  Target={`/${previousPagePathRoot}/${vanityPropertyId}/${ParentCategoryLabel}/${item.CategoryLabel}`}
                  propertyId={propertyDetails ? propertyDetails._id : null}
                />

                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}