import React from "react";
import NavigationHeader from "../components/NavigationHeader";
import InfoItemTel from "../components/InfoItemTel";
import InfoItemTwoBox from "../components/InfoItemTwoBox";
import InfoItemImage from "../components/InfoItemImage";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class PersonalInfoPage extends React.Component {
  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { SaveStatus, userData } = this.props;

    const currentPageTitle = "Personal Information";

    return (
      <>
        <NavigationHeader
          {...this.props}
          previousPageTitle={"Dashboard"}
          previousPagePath={"/"}
          currentPageTitle={currentPageTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {currentPageTitle}</div>
            <div className="infopage-subheading">
              Provide your personal contact details so we can reach you. These
              details will not be shared with your guests.
            </div>

            <div className="infoitem-container">
              <div className="section-break-wrapper">
                <div className="section-break"></div>
              </div>
            </div>

            <InfoItemTwoBox
              SaveStatus={SaveStatus}
              userId={userData._id}
              ItemType={"user"}
              ItemLabel={"Name"}
              ItemHeading={"Name"}
              ItemDescription={"This is the name of the account holder"}
              InputLabelA={"First name"}
              ValueA={userData.UserFirstname}
              PayloadA={"UserFirstname"}
              InputLabelB={"Last name"}
              ValueB={userData.UserLastname}
              PayloadB={"UserLastname"}
              IsChecked={
                userData.UserFirstname || userData.UserLastname ? true : false
              }
              IsRequired={true}
              FalseText={"Not entered"}
            />

            <InfoItemTel
              SaveStatus={SaveStatus}
              userId={userData._id}
              itemType={"user"}
              ContainerClassName={"infoitem-form-inner-container-left"}
              itemLabel={"phone-number"}
              ItemHeading={"Phone number"}
              ItemDescription={
                "This is the number we will use if we need to contact you"
              }
              InputLabelA={"Phone number"}
              ValueA={userData.UserTel}
              PayloadA={"UserTel"}
              IsChecked={userData.UserTel ? true : false}
              IsRequired={true}
              FalseText={"Not entered"}
            />

            <InfoItemImage
              SaveStatus={SaveStatus}
              userId={userData._id}
              ItemType={"user"} // Defines which dispatch to invoke
              ItemHeading={"Photo"}
              ItemLabel={"user-cover-image"}
              FileFolder={"user-content"}
              ItemId={userData._id} // Defines which id to insert into filename
              ItemDescription={
                "This is your dashboard profile image (it is not displayed to your guests)"
              }
              InputLabelA={""}
              ValueA={userData.UserCoverImage}
              PayloadA={"UserCoverImage"} // Defines which database label to update
              IsChecked={userData.UserCoverImage ? true : false}
              IsRequired={false}
              FalseText={"Not uploaded"}
            />
          </div>
        </div>
      </>
    );
  }
}
