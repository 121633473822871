import React from "react";
import LocalAreaContentPage from "../pages/LocalAreaContentPage";
import { connect } from "react-redux";
import { getCustomContent } from "../redux/actions/userActions";
import { setCustomContentLoaded } from "../redux/actions/userActions";
import { getLinkedProperties } from "../redux/actions/userActions";
import { setLinkedPropertiesLoaded } from "../redux/actions/userActions";
import { getSharedContent } from "../redux/actions/userActions";
import { setSharedContentLoaded } from "../redux/actions/userActions";

class PropertyDetailsLocalLoader extends React.Component {
  state = {
    sharedContentProfileId: "",
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    const {
      PropertyId,
      CustomContentPropertyId,
      isComponentShared,
      SharedContentLoadedId,
      sharedContentProfiles,
    } = this.props;

    var vanitySharedContentProfileId = window.location.pathname.split("/")[2]
      ? window.location.pathname.split("/")[2]
      : null;

    const sharedLocalAreaContentFilter = sharedContentProfiles
      ? sharedContentProfiles.filter((item) => {
          return (
            item.LocalContentProfile === true &&
            item.Label === vanitySharedContentProfileId
          );
        })
      : null;

    const SharedContentProfileId =
      sharedLocalAreaContentFilter && sharedLocalAreaContentFilter[0]
        ? sharedLocalAreaContentFilter[0]._id
        : null;

    if (SharedContentProfileId) {
      this.setState({
        sharedContentProfileId: SharedContentProfileId,
      });
    }

    if (this.props.location.state) {
      const propertyId = this.props.location.state.propertyId;
      //  console.log("Condition 1 - content not already loaded (from link)");

      if (!isComponentShared) {
        // Condition 1 - content not already loaded (from link)
        //  console.log("Condition 1 - content not already loaded (from link)");
        if (this.props.location.state.propertyId !== CustomContentPropertyId) {
          this.props.setCustomContentLoaded(false, CustomContentPropertyId);
          this.props.getCustomContent(propertyId);
          this.props.setLinkedPropertiesLoaded(true);
        }

        // Condition 2 - content is already loaded (from link)
        if (this.props.location.state.propertyId === CustomContentPropertyId) {
          this.props.setLinkedPropertiesLoaded(true);
          this.props.setCustomContentLoaded(true, CustomContentPropertyId);
        }
      }

      if (isComponentShared) {
        // Condition 1 - content not already loaded (from link)
        if (this.props.location.state.propertyId !== SharedContentLoadedId) {
          this.props.setSharedContentLoaded(false, SharedContentLoadedId);
          this.props.getSharedContent(propertyId, "ItemLabel");
        }

        // Condition 2 - content is already loaded (from link)
        if (this.props.location.state.propertyId === SharedContentLoadedId) {
          this.props.setSharedContentLoaded(true, SharedContentLoadedId);
        }
      }
    }

    // Condition 3 - page loaded in browser
    if (!this.props.location.state) {
      //  console.log("Condition 3 - page loaded in browser");
      if (!isComponentShared && PropertyId) {
        this.props.setCustomContentLoaded(false, CustomContentPropertyId);
        this.props.getCustomContent(PropertyId);
        this.props.setLinkedPropertiesLoaded(true);
      }
      if (isComponentShared && SharedContentProfileId) {
        this.props.setSharedContentLoaded(false, SharedContentLoadedId);
        this.props.getSharedContent(SharedContentProfileId, "ItemLabel");
      }
    }
  };

  render() {
    const {
      SaveStatus,
      userData,
      companyData,
      managerData,
      propertyData,
      systemContent,
      systemCategories,
      customContent,
      //  CustomContentLoaded,
      PropertyId,
      //  linkedPropertiesLoaded,
      sharedContent,
      //  SharedContentLoaded,
      isComponentShared,
      sharedContentProfiles,
      AttachContentLoaded,
    } = this.props;

    const propertyId =
      this.props.location.state && this.props.location.state.propertyId
        ? this.props.location.state.propertyId
        : PropertyId;

    const SharedContentProfileId = this.state.sharedContentProfileId
      ? this.state.sharedContentProfileId
      : null;

    var vanitySharedLocalAreaContentProfileId =
      window.location.pathname.split("/")[1] === "local-area-profile"
        ? window.location.pathname.split("/")[2]
        : null;

    const sharedLocalAreaContentProfiles = sharedContentProfiles
      ? sharedContentProfiles.filter((item) => {
          return item.LocalContentProfile;
        })
      : null;

    const sharedLocalAreaContentProfileFilter = sharedContentProfiles
      ? sharedLocalAreaContentProfiles.filter((item) => {
          return item.Label === vanitySharedLocalAreaContentProfileId;
        })
      : null;

    const sharedLocalAreaContentProfileData =
      sharedLocalAreaContentProfileFilter
        ? sharedLocalAreaContentProfileFilter[0]
        : null;

    //var saveStatusLastEight = SaveStatus.substr(SaveStatus.length - 8);

    return (
      <LocalAreaContentPage
        {...this.props}
        userData={userData}
        companyData={companyData}
        managerData={managerData}
        propertyData={propertyData}
        systemCategories={systemCategories}
        systemContent={systemContent}
        customContent={customContent}
        sharedContent={sharedContent}
        SaveStatus={SaveStatus}
        PropertyId={propertyId}
        isComponentShared={isComponentShared}
        SharedContentProfileId={SharedContentProfileId}
        sharedLocalAreaContentProfileData={sharedLocalAreaContentProfileData}
        sharedLocalAreaContentProfiles={sharedLocalAreaContentProfiles}
        AttachContentLoaded={AttachContentLoaded}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomContent: (propertyId) => dispatch(getCustomContent(propertyId)),
    setCustomContentLoaded: (payload, propertyId) =>
      dispatch(setCustomContentLoaded(payload, propertyId)),
    setLinkedPropertiesLoaded: (payload) =>
      dispatch(setLinkedPropertiesLoaded(payload)),
    getLinkedProperties: (ItemLabel, userId) =>
      dispatch(getLinkedProperties(ItemLabel, userId)),
    getSharedContent: (sharedContentProfileId, ItemLabel) =>
      dispatch(getSharedContent(sharedContentProfileId, ItemLabel)),
    setSharedContentLoaded: (payload, profileId) =>
      dispatch(setSharedContentLoaded(payload, profileId)),
  };
};

export default connect(null, mapDispatchToProps)(PropertyDetailsLocalLoader);
