import React from "react";
//import ReactGA from "react-ga";
//import { googleAnalyticsId } from "../constants.js";
import { connect } from "react-redux";
import { fetchUser } from "../redux/actions/userActions";
//import { topLevelDomain } from "../constants.js";
import "../styles/LoginForm.css";
//import logo from "../images/airguide-logo-header.png";
import Loading from "./Loading";
import GuideyLogo from "../icons/GuideyLogo";
// import CtaLoading from "./CtaLoading";

//ReactGA.initialize(googleAnalyticsId);

class LoginForm extends React.Component {
  state = {
    email: "",
    password: "",
    loading: false,
  };

  componentDidMount = () => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
  };

  onClickForgotPassword = () => {
    this.props.history.push("/forgot-password");
  };

  onClickCreateAccount = () => {
    this.props.history.push("/create-account");
    // window.location.href = `https://try${topLevelDomain}start`;
  };

  handleOnChange = (e) => {
    e.persist();
    this.setState(() => ({
      [e.target.name]: e.target.value,
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.fetchUser(this.state);
  };

  render() {
    const { FetchStatus } = this.props;

    if (FetchStatus === "loading-data") {
      return <Loading />;
    }

    const userFound = this.props.responseStatus.userFound;
    //  console.log(
    //    "userFound",
    //   userFound
    // );
    return (
      <>
        <div className="homepage-canvas">
          <div className="inline-static-link-grey">
            airguide is now Guidey™{" "}
            <a
              href="https://guideyapp.com/from-airguide-to-guidey"
              target="_blank"
              rel="noopener noreferrer"
            >
              find out more
            </a>
            .
          </div>

          <div className="login-form-wrapper">
            <div className="homepage-logo-wrapper">
              <GuideyLogo width={"180"} height={"auto"} />
            </div>
            <div className="homepage-heading">Log in to your account</div>

            <div className="login-form">
              <form onSubmit={this.onSubmit}>
                <input
                  type="text"
                  name="email"
                  className="login-text-input"
                  placeholder="Email address"
                  value={this.state.email}
                  onChange={this.handleOnChange}
                />
                <br />
                <input
                  type="password"
                  name="password"
                  className="login-text-input"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handleOnChange}
                />
                <br />

                <input
                  type="submit"
                  className="login-button"
                  value={FetchStatus === "fetching" ? "Logging in..." : "Login"}
                />
              </form>

              <button
                className="inline-edit-link-grey"
                onClick={this.onClickForgotPassword}
              >
                Forgot password?
              </button>

              <button
                className="inline-edit-link"
                onClick={this.onClickCreateAccount}
              >
                Create an account
              </button>
            </div>

            {!userFound && userFound !== undefined && (
              <div className="infoitem-form-error-message">
                Sorry, we could not find a user with that email address. Please
                try again.
              </div>
            )}
            {userFound && (
              <div className="infoitem-form-error-message">
                Sorry, incorrect password entered. Please try again.
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (userInfo) => dispatch(fetchUser(userInfo)),
  };
};

export default connect(null, mapDispatchToProps)(LoginForm);
