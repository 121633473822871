import React from "react";
import { appConfig } from "../data/appConfig";
import getAllUrlParams from "../functions/getAllUrlParams";
import CreateAffiliateAccountModule from "../components/CreateAffiliateAccountModule";
import Loading from "../components/Loading";
import GuideyLogo from "../icons/GuideyLogo";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/SetupFlow.css";

export default class CreateAffiliateAccountPage extends React.Component {
  state = {
    accountSuccess: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.style.setProperty("--tidio-display", "none");
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }

  updateState = () => {
    this.setState(() => ({
      accountSuccess: true,
    }));
  };

  render() {
    const { postResponseData, SaveStatus } = this.props;

    const termsOfService = (
      <a
        href={`${appConfig.siteUrl}/terms-of-service`}
        target="_blank"
        rel="noreferrer"
      >
        Terms of Service
      </a>
    );
    const privacyPolicy = (
      <a
        href={`${appConfig.siteUrl}/privacy-policy`}
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
    );

    return (
      <>
        <div className="setup-flow-headerbar-outer">
          <div className="setup-flow-headerbar">
            <GuideyLogo width={"120"} height={"auto"} />
          </div>
        </div>

        {this.state.accountSuccess && (
          <Loading
            LoadingText={"Success! Your account has been created"}
            LoadingCanvas={"success-loading-page-canvas"}
          />
        )}

        {!this.state.accountSuccess && (
          <div className="create-account-canvas">
            <div className="create-account-canvas-inner">
              <div className="create-account-container">
                <div className="create-account-inner-container-left">
                  <div className="setupflow-heading">
                    Partner with Guidey for a 25% commission
                  </div>
                  <div className="setupflow-subheading">
                    Enter your details below to generate your affiliate link and
                    start earning commission today!
                  </div>

                  <CreateAffiliateAccountModule
                    {...this.props}
                    SaveStatus={SaveStatus}
                    postResponseData={postResponseData}
                    ItemLabel={"new-affiliate-account"}
                    ItemHeading={"Email address"}
                    InputLabelA={"Email address"}
                    ValueA={""}
                    PayloadA={"email"}
                    InputLabelB={"Password"}
                    ValueB={""}
                    PayloadB={"password"}
                    InputLabelC={"First name"}
                    ValueC={""}
                    PayloadC={"userFirstname"}
                    trackingData={getAllUrlParams(window.location)}
                    updateState={this.updateState}
                  />

                  <div className="cta-button-subtext">
                    By proceeding, you agree to the {termsOfService} and{" "}
                    {privacyPolicy}
                  </div>
                </div>
              </div>

              <div className="create-account-inner-container-right"></div>
            </div>
          </div>
        )}
      </>
    );
  }
}
