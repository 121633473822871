import React from "react";
//import React, { useEffect } from "react";
import LazyLoad from "react-lazyload";
import InfoItemMultiPopup from "./InfoItemMultiPopup";
import ListItemImage from "./ListItemImage.js";
import ReactLoading from "react-loading";
import LocationIcon from "../icons/LocationIcon";
//import ToolTip from "./ToolTip.js";
import "../styles/InfoItem.css";

export default function InfoItemPlaceListItem(props) {
  const {
    ItemData,
    Index,
    ItemLabel,
    SharedContentProfileId,
    propertyId,
    SaveStatus,
    userId,
    propertyDetails,
    systemContent,
    customContent,
    sharedContent,
    isComponentShared,
    CategoryLabel,
    sharedLocalAreaContentProfileData,
  } = props;

  return (
    <>
      <div className="infoitem-listitem-image-and-detail-container">
        <div className="infoitem-listitem-number">
          {SaveStatus === `${ItemLabel}-fetching` ? (
            <ReactLoading
              type={"spin"}
              color={"var(--UIcolour1)"}
              height={"15px"}
              width={"15px"}
            />
          ) : (
            // : `${ItemData.ListRank + 1}`}
            `${Index + 1}`
          )}
        </div>
        <LazyLoad height={200} offset={100}>
          <ListItemImage
            GooglePhotoRef={ItemData.GooglePhotoReference}
            DetailImage={ItemData.DetailImage}
            ItemData={ItemData}
          />
        </LazyLoad>

        <div className="infoitem-listitem-detail-container">
          <div className="infoitem-listitem-text-and-button-container">
            <div className="infoitem-listitem-text-container">
              <div className="infoitem-listitem-heading">
                {ItemData.DetailTitle}
              </div>

              {(!ItemData.GeoLocationLat || !ItemData.GeoLocationLng) && (
                <div className="infoitem-listitem-location-wrapper">
                  <LocationIcon className="infoitem-listitem-location-icon-warning" />
                  <div className="infoitem-listitem-location-text-warning">
                    Awaiting location pin set up
                  </div>
                </div>
              )}

              {ItemData.GeoLocationLat &&
                ItemData.GeoLocationLng &&
                ItemData.GeoLocationFormattedAddress && (
                  <div className="infoitem-listitem-location-wrapper">
                    <LocationIcon className="infoitem-listitem-location-icon" />
                    <div className="infoitem-listitem-location-text">
                      {`${ItemData.GeoLocationFormattedAddress.replace(
                        /,[^,]+$/,
                        ""
                      )}`}

                      {ItemData.ShowWhatThreeWords && (
                        <>
                          <div className="infoitem-listitem-location-text-wtw">
                            {`///${ItemData.WhatThreeWordsLocation}`}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}

              {ItemData.GeoLocationLat &&
                ItemData.GeoLocationLng &&
                !ItemData.GeoLocationFormattedAddress &&
                ItemData.WhatThreeWordsNearestPlace && (
                  <div className="infoitem-listitem-location-wrapper">
                    <LocationIcon className="infoitem-listitem-location-icon" />
                    <div className="infoitem-listitem-location-text">
                      {`Manual location pin in ${ItemData.WhatThreeWordsNearestPlace}`}
                    </div>
                  </div>
                )}
            </div>
            <InfoItemMultiPopup
              ButtonText={"Edit"}
              ItemType={"edit"}
              ItemData={ItemData}
              ItemLabel={ItemLabel}
              SharedContentProfileId={SharedContentProfileId}
              PropertyId={propertyId}
              SaveStatus={SaveStatus}
              userId={userId}
              propertyData={propertyDetails}
              systemContent={systemContent}
              customContent={customContent}
              sharedContent={sharedContent}
              isComponentShared={isComponentShared}
              CategoryLabel={CategoryLabel}
              sharedLocalAreaContentProfileData={
                sharedLocalAreaContentProfileData
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
