import React from "react";
import "../styles/DisplayCanvas.css";
import "../styles/ButtonBanners.css";
import "../styles/SearchBar.css";
import NavigationHeader from "../components/NavigationHeader";
import ImageBanner from "../components/ImageBanner";
import SearchIcon from "../icons/SearchIcon.svg";

export default class AllPropertiesPage extends React.Component {
  state = {
    searchTerm: "",
  };

  onChange = (e) => {
    e.persist();
    this.setState(() => ({
      searchTerm: e.target.value,
    }));
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    sessionStorage.setItem("routePath", "all-properties");
  };

  onCreatePlaceClick = () => {
    this.props.history.push("/create-property");
  };

  render() {
    const { userData, propertyData, systemContent } = this.props;

    const propertyDataFiltered = propertyData
      ? propertyData.filter((Item) => {
          return Item.PropertyID !== userData.SharedContentProfileId;
        })
      : null;

    const propertyDataFilteredbySearch = propertyDataFiltered
      ? propertyDataFiltered.filter((item) => {
          return (
            (item.PropertyTitle &&
              item.PropertyTitle.toLowerCase().includes(
                this.state.searchTerm.toLowerCase()
              )) ||
            (item.PropertyDisplayAddress &&
              item.PropertyDisplayAddress.toLowerCase().includes(
                this.state.searchTerm.toLowerCase()
              ))
          );
        })
      : null;

    // Temp filter
    // const propertyDataFiltered = propertyData
    //      ? propertyData.filter((Item) => {
    //          return Item.PropertyTitle !== "Shared Content";
    //        })
    //      : null;

    // Extract placeholder property details from SystemContent
    const propertyDetailsPlaceholderFiltered = systemContent.filter(
      (DisplayContent) => {
        return DisplayContent.DetailLabel === "property-details-placeholder";
      }
    );
    const propertyDetailsPlaceholder = propertyDetailsPlaceholderFiltered[0];

    const previousPageTitle = "Dashboard";
    const previousPagePath = "/";
    const currentPageTitle = "All Properties";

    return (
      <>
        <NavigationHeader
          {...this.props}
          // propertyDetails={{}}
          pathRoot={"all-properties"}
          previousPageTitle={previousPageTitle}
          previousPagePath={previousPagePath}
          currentPageTitle={currentPageTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {currentPageTitle}</div>
            <div className="infopage-subheading">Find and edit properties</div>

            <>
              <input
                type="text"
                style={{
                  background: `url(${SearchIcon}) center left 10px no-repeat`,
                }}
                name={"Search"}
                className="search-bar-input"
                placeholder={"Search for a property name or address"}
                value={this.state.searchTerm}
                onChange={this.onChange}
              />
            </>

            <div className="section-break-wrapper">
              <div className="section-break"></div>
            </div>

            <div className="button-banner-section-outer-container">
              <div className="button-banner-section-container">
                {propertyDataFilteredbySearch.length === 1 && (
                  <h3>{propertyDataFilteredbySearch.length} Property</h3>
                )}

                {(propertyDataFilteredbySearch.length > 1 ||
                  propertyData.length < 1) && (
                  <h3>{propertyDataFilteredbySearch.length} Properties</h3>
                )}

                <div className="button-banners-container">
                  {!propertyDataFiltered[0] && (
                    <div className="button-banner-section-description">
                      No properties setup yet
                    </div>
                  )}

                  {propertyDataFilteredbySearch.map((item) => (
                    <div key={item._id} className={"button-banner-container"}>
                      <ImageBanner
                        Image={
                          item.PropertyCoverImage
                            ? item.PropertyCoverImage
                            : propertyDetailsPlaceholder.DetailImage
                        }
                        Heading={
                          item.PropertyTitle
                            ? item.PropertyTitle
                            : propertyDetailsPlaceholder.DetailTitle
                        }
                        Status={
                          userData.SubscriptionActiveUser &&
                          userData.SubscriptionActiveSystem &&
                          item.PropertyActiveStatus
                            ? true
                            : false
                        }
                        Target={`property/${item.PropertyID}`}
                        PropertyId={item._id}
                        {...this.props}
                      />
                    </div>
                  ))}
                </div>

                <button
                  className="create-new-button"
                  onClick={this.onCreatePlaceClick}
                >
                  Create new property
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
